import { toString as toString_2, Record, Union } from "../fable_modules/fable-library.3.7.3/Types.js";
import { DomainEntities, DomainEntities$reflection } from "../DomainEvents.fs.js";
import { FetchError$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { record_type, bool_type, list_type, string_type, class_type, union_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { PromiseBuilder__For_1565554B, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { Route_$007CGuid$007C_$007C, RouterModule_urlSegments } from "../fable_modules/Feliz.Router.3.8.0/Router.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { signOff as signOff_1, single } from "../Api/Activities.fs.js";
import { single as single_1 } from "../Api/Actions.fs.js";
import { FSharpResult$2, Result_Map } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { single as single_2 } from "../Api/Entities.fs.js";
import { iterate, reverse, ofSeq, collect, append as append_1, length, singleton, cons, empty, ofArray, map, choose, head, tail, isEmpty } from "../fable_modules/fable-library.3.7.3/List.js";
import { List_groupBy } from "../fable_modules/fable-library.3.7.3/Seq2.js";
import { defaultArg, some, map as map_1 } from "../fable_modules/fable-library.3.7.3/Option.js";
import { int32ToString, equals, createObj, comparePrimitives, uncurry, stringHash } from "../fable_modules/fable-library.3.7.3/Util.js";
import * as jspdf from "jspdf";
import { Csv_encode, EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString, System_String__String_ToOption_Static_Z721C83C5, dataUrlToCanvas, readAsDataUrlPromise } from "../Prelude.fs.js";
import { TaskMsg, useFeliz_React__React_useTaskDispatch_Static } from "../hooks/UseTaskDispatch.fs.js";
import { useReact_useMemo_CF4EA67, useReact_useCallback_93353E, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { useReact_useState_FCFD9EF } from "../hooks/../fable_modules/Feliz.1.58.1/React.fs.js";
import { interpolate, toText, join, isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { fromString } from "../hooks/../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { toString } from "../hooks/../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { detectQROnFiles } from "./PDFQRScanner.fs.js";
import { newGuid } from "../fable_modules/fable-library.3.7.3/Guid.js";
import { FSharpMap__TryFind, tryFind, change as change_1, ofList, empty as empty_1 } from "../fable_modules/fable-library.3.7.3/Map.js";
import { Documents_ChangeReferences, Documents_UpdateDocument, Activities_Signed, Documents_CreateDocument } from "../ServerTypes.fs.js";
import { many, count, changeReferences as changeReferences_1, update as update_1, create as create_5, createUpload } from "../Api/Documents.fs.js";
import { DomainEntity__get_Id, DomainEntity__get_Name } from "../ClientTypes.fs.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { utcNow as utcNow_1, toString as toString_1 } from "../fable_modules/fable-library.3.7.3/Date.js";
import { FSharpSet__get_Count, toList as toList_1, ofList as ofList_1 } from "../fable_modules/fable-library.3.7.3/Set.js";
import { createElement } from "react";
import * as react from "react";
import { Modal_ModalWithFooter, Modal_ModalWithBreakdown } from "./Modal.fs.js";
import { collect as collect_1, map as map_2, empty as empty_2, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { rangeDouble } from "../fable_modules/fable-library.3.7.3/Range.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { op_PlusPlus } from "../fable_modules/Feliz.Bulma.2.18.0/Operators.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { useFeliz_React__React_useElmish_Static_78C5B8C8 } from "../fable_modules/Feliz.UseElmish.1.6.0/UseElmish.fs.js";
import { DomainEntitiesSelector } from "../components/EntitySelector.fs.js";
import { StringBuilder__AppendLine_Z721C83C5, StringBuilder_$ctor } from "../fable_modules/fable-library.3.7.3/System.Text.js";
import file_saver from "file-saver";

class QRErrors extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UnknownFormat", "InvalidProject", "MissingEntity"];
    }
}

function QRErrors$reflection() {
    return union_type("EPM.App.Modals.Document.QRErrors", [], QRErrors, () => [[], [], [["Item1", DomainEntities$reflection()], ["Item2", FetchError$reflection()]]]);
}

class DocumentFile extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["File", "GeneratedPDF"];
    }
}

function DocumentFile$reflection() {
    return union_type("EPM.App.Modals.Document.DocumentFile", [], DocumentFile, () => [[["Item", class_type("Browser.Types.File")]], [["Item1", class_type("Browser.Types.Blob")], ["Item2", list_type(string_type)]]]);
}

function gatherQRData(currentProjectId, qr) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const parts = RouterModule_urlSegments(qr, 2);
        let pattern_matching_result, activityId, projectId;
        if (!isEmpty(parts)) {
            if (!isEmpty(tail(parts))) {
                if (!isEmpty(tail(tail(parts)))) {
                    if (head(tail(tail(parts))) === "projects") {
                        if (!isEmpty(tail(tail(tail(parts))))) {
                            const activePatternResult46563 = Route_$007CGuid$007C_$007C(head(tail(tail(tail(parts)))));
                            if (activePatternResult46563 != null) {
                                if (!isEmpty(tail(tail(tail(tail(parts)))))) {
                                    if (head(tail(tail(tail(tail(parts))))) === "Activity") {
                                        if (!isEmpty(tail(tail(tail(tail(tail(parts))))))) {
                                            const activePatternResult46564 = Route_$007CGuid$007C_$007C(head(tail(tail(tail(tail(tail(parts)))))));
                                            if (activePatternResult46564 != null) {
                                                if (isEmpty(tail(tail(tail(tail(tail(tail(parts)))))))) {
                                                    pattern_matching_result = 0;
                                                    activityId = activePatternResult46564;
                                                    projectId = activePatternResult46563;
                                                }
                                                else {
                                                    pattern_matching_result = 1;
                                                }
                                            }
                                            else {
                                                pattern_matching_result = 1;
                                            }
                                        }
                                        else {
                                            pattern_matching_result = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result = 1;
                                }
                            }
                            else {
                                pattern_matching_result = 1;
                            }
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return (currentProjectId === projectId) ? (single(currentProjectId, activityId).then((_arg1) => {
                    let matchValue, actionId;
                    const activity = _arg1;
                    if (activity.tag === 0) {
                        const activity_1 = activity.fields[0];
                        return ((matchValue = activity_1.ActionId, (matchValue != null) ? ((actionId = matchValue, PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (single_1(currentProjectId, actionId).then((_arg2) => (Promise.resolve(Result_Map((arg0_2) => arg0_2, _arg2))))))))) : (Promise.resolve(new FSharpResult$2(0, void 0))))).then((_arg3) => {
                            const actionResult = _arg3;
                            if (actionResult.tag === 0) {
                                const action_1 = actionResult.fields[0];
                                const matchValue_1 = activity_1.TargetEntity;
                                if (matchValue_1 != null) {
                                    const targetEntity = matchValue_1;
                                    return single_2(targetEntity.EntityType, currentProjectId, targetEntity.EntityId).then((_arg4) => {
                                        const target = _arg4;
                                        return (target.tag === 0) ? (Promise.resolve(new FSharpResult$2(0, [activity_1, action_1, target.fields[0]]))) : (Promise.resolve(new FSharpResult$2(1, new QRErrors(2, targetEntity.EntityType, target.fields[0]))));
                                    });
                                }
                                else {
                                    return Promise.resolve(new FSharpResult$2(0, [activity_1, action_1, void 0]));
                                }
                            }
                            else {
                                return Promise.resolve(new FSharpResult$2(1, new QRErrors(2, new DomainEntities(11), actionResult.fields[0])));
                            }
                        });
                    }
                    else {
                        return Promise.resolve(new FSharpResult$2(1, new QRErrors(2, new DomainEntities(5), activity.fields[0])));
                    }
                })) : (Promise.resolve(new FSharpResult$2(1, new QRErrors(1))));
            }
            case 1: {
                return Promise.resolve(new FSharpResult$2(1, new QRErrors(0)));
            }
        }
    }));
}

function processingQRFiles(projectId, files) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let pr;
        const nonQrFiles = choose((tupledArg) => {
            if (tupledArg[1] == null) {
                return tupledArg[0];
            }
            else {
                return void 0;
            }
        }, files);
        return ((pr = map((tupledArg_2) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (gatherQRData(projectId, tupledArg_2[0]).then((_arg1) => (Promise.resolve([map((tuple_1) => tuple_1[0], tupledArg_2[1]), _arg1])))))), List_groupBy((tuple) => tuple[1], choose((tupledArg_1) => map_1((qr_2) => [tupledArg_1[0], qr_2], tupledArg_1[1]), files), {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        })), Promise.all(pr))).then((_arg2) => (Promise.resolve([nonQrFiles, ofArray(_arg2)])));
    }));
}

function stichQRFiles(files) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const pdf = new jspdf.jsPDF(null);
        let addPage = false;
        const width = pdf.internal.pageSize.getWidth() - 10;
        const height = pdf.internal.pageSize.getHeight() - 10;
        let fileNames = empty();
        return PromiseBuilder__For_1565554B(promise, files, (_arg1) => {
            const file = _arg1;
            const matchValue = file.type;
            switch (matchValue) {
                case "image/jpg":
                case "image/jpeg":
                case "image/png": {
                    return (addPage ? ((void pdf.addPage(), Promise.resolve())) : (Promise.resolve())).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => (readAsDataUrlPromise(file).then((_arg2) => (dataUrlToCanvas(_arg2).then((_arg3) => {
                        fileNames = cons(file.name, fileNames);
                        pdf.addImage(_arg3, "PNG", 0, 0, width, height);
                        addPage = true;
                        return Promise.resolve();
                    }))))));
                }
                default: {
                    return Promise.resolve();
                }
            }
        }).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const blob = pdf.output('blob');
            return Promise.resolve(new DocumentFile(1, blob, fileNames));
        }));
    }));
}

export function Create(createInputProps) {
    let patternInput_10, xs_51;
    const close = createInputProps.close;
    const projectId = createInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const setName = patternInput_1[1];
    const name = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("");
    const setDetail = patternInput_2[1];
    const detail = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setIsDrag = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505("");
    const externalUrl = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(true);
    const uploadByFile = patternInput_5[0];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(empty());
    const uploadFiles = patternInput_6[0];
    const setUploadFiles = patternInput_6[1];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505(empty());
    const setNonQRFiles = patternInput_7[1];
    const nonQRFiles = patternInput_7[0];
    const patternInput_8 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessingQR = patternInput_8[1];
    const processingQR = patternInput_8[0];
    const patternInput_9 = useFeliz_React__React_useState_Static_1505(empty());
    const setQRFiles = patternInput_9[1];
    const qrFiles = patternInput_9[0];
    let patternInput_11;
    const key = "documents.upload.detectQR";
    const initialValue = false;
    patternInput_11 = ((patternInput_10 = useReact_useState_FCFD9EF(() => {
        const existingValue = window.localStorage.getItem(key);
        if (isNullOrWhiteSpace(existingValue)) {
            return initialValue;
        }
        else {
            const result = fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(bool_type, void 0, void 0)), existingValue);
            if (result.tag === 1) {
                console.error(some(`Unable to get ${key} from local storage`), result.fields[0]);
                return initialValue;
            }
            else {
                return result.fields[0];
            }
        }
    }), [patternInput_10[0], (value_1) => {
        const json = toString(4, Auto_generateBoxedEncoder_Z20B7B430(bool_type, void 0, void 0, void 0)(value_1));
        window.localStorage.setItem(key, json);
        patternInput_10[1](value_1);
    }]));
    const detectQR = patternInput_11[0];
    useReact_useEffect_Z101E1A95(() => {
        setQRFiles(empty());
        setNonQRFiles(empty());
        if (detectQR && (!processingQR)) {
            setProcessingQR(true);
            let pr_4;
            let pr_3;
            const pr = detectQROnFiles(uploadFiles);
            pr_3 = (pr.then((files_1) => processingQRFiles(projectId, files_1)));
            pr_4 = (pr_3.then((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let pr_2;
                return ((pr_2 = map((tupledArg_1) => {
                    const pr_1 = stichQRFiles(tupledArg_1[0]);
                    return pr_1.then((pdf) => [pdf, tupledArg_1[1]]);
                }, tupledArg[1]), Promise.all(pr_2))).then((_arg1) => {
                    setNonQRFiles(tupledArg[0]);
                    setQRFiles(ofArray(_arg1));
                    setProcessingQR(false);
                    return Promise.resolve();
                });
            }))));
            void pr_4;
        }
        else {
            setNonQRFiles(uploadFiles);
        }
        let pattern_matching_result, file;
        if (!isEmpty(uploadFiles)) {
            if (isEmpty(tail(uploadFiles))) {
                pattern_matching_result = 0;
                file = head(uploadFiles);
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                setName(file.name);
                setDetail(file.name);
                break;
            }
            case 1: {
                break;
            }
        }
    }, [uploadFiles, detectQR]);
    const create_4 = useReact_useCallback_93353E(() => {
        const pr_5 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let matchValue, name_2, taskId_1, create_1, name_1, url, taskId, create;
            return ((matchValue = [System_String__String_ToOption_Static_Z721C83C5(name), System_String__String_ToOption_Static_Z721C83C5(externalUrl), nonQRFiles, uploadByFile], (matchValue[0] != null) ? ((matchValue[1] == null) ? ((!isEmpty(matchValue[2])) ? (isEmpty(tail(matchValue[2])) ? (matchValue[3] ? ((name_2 = matchValue[0], (taskId_1 = newGuid(), (taskDispatch(new TaskMsg(0, taskId_1, `Create ${head(matchValue[2]).name}`)), (create_1 = (new Documents_CreateDocument(name_2, System_String__String_ToOption_Static_Z721C83C5(detail), empty_1(), empty_1(), void 0)), (taskDispatch(new TaskMsg(2, taskId_1)), createUpload(projectId, create_1, head(matchValue[2])).then((_arg3) => {
                const result_2 = _arg3;
                if (result_2.tag === 1) {
                    taskDispatch(new TaskMsg(6, taskId_1, result_2.fields[0]));
                    return Promise.resolve();
                }
                else {
                    taskDispatch(new TaskMsg(3, taskId_1, void 0));
                    return Promise.resolve();
                }
            }))))))) : ((Promise.resolve()))) : (matchValue[3] ? PromiseBuilder__For_1565554B(promise, matchValue[2], (_arg4) => {
                const file_2 = _arg4;
                const taskId_2 = newGuid();
                taskDispatch(new TaskMsg(0, taskId_2, `Create ${file_2.name}`));
                const create_2 = new Documents_CreateDocument(file_2.name, file_2.name, empty_1(), empty_1(), void 0);
                taskDispatch(new TaskMsg(2, taskId_2));
                return createUpload(projectId, create_2, file_2).then((_arg5) => {
                    const result_3 = _arg5;
                    if (result_3.tag === 1) {
                        taskDispatch(new TaskMsg(6, taskId_2, result_3.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, taskId_2, void 0));
                        return Promise.resolve();
                    }
                });
            }) : ((Promise.resolve())))) : (matchValue[3] ? PromiseBuilder__For_1565554B(promise, matchValue[2], (_arg4) => {
                const file_2 = _arg4;
                const taskId_2 = newGuid();
                taskDispatch(new TaskMsg(0, taskId_2, `Create ${file_2.name}`));
                const create_2 = new Documents_CreateDocument(file_2.name, file_2.name, empty_1(), empty_1(), void 0);
                taskDispatch(new TaskMsg(2, taskId_2));
                return createUpload(projectId, create_2, file_2).then((_arg5) => {
                    const result_3 = _arg5;
                    if (result_3.tag === 1) {
                        taskDispatch(new TaskMsg(6, taskId_2, result_3.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, taskId_2, void 0));
                        return Promise.resolve();
                    }
                });
            }) : ((Promise.resolve())))) : (matchValue[3] ? ((Promise.resolve())) : ((name_1 = matchValue[0], (url = matchValue[1], (taskId = newGuid(), (taskDispatch(new TaskMsg(0, taskId, "Create")), (create = (new Documents_CreateDocument(name_1, System_String__String_ToOption_Static_Z721C83C5(detail), empty_1(), empty_1(), url)), (taskDispatch(new TaskMsg(2, taskId)), create_5(projectId, create).then((_arg2) => {
                const result_1 = _arg2;
                if (result_1.tag === 1) {
                    taskDispatch(new TaskMsg(6, taskId, result_1.fields[0]));
                    return Promise.resolve();
                }
                else {
                    taskDispatch(new TaskMsg(3, taskId, void 0));
                    return Promise.resolve();
                }
            })))))))))) : ((matchValue[1] == null) ? (matchValue[3] ? PromiseBuilder__For_1565554B(promise, matchValue[2], (_arg4) => {
                const file_2 = _arg4;
                const taskId_2 = newGuid();
                taskDispatch(new TaskMsg(0, taskId_2, `Create ${file_2.name}`));
                const create_2 = new Documents_CreateDocument(file_2.name, file_2.name, empty_1(), empty_1(), void 0);
                taskDispatch(new TaskMsg(2, taskId_2));
                return createUpload(projectId, create_2, file_2).then((_arg5) => {
                    const result_3 = _arg5;
                    if (result_3.tag === 1) {
                        taskDispatch(new TaskMsg(6, taskId_2, result_3.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, taskId_2, void 0));
                        return Promise.resolve();
                    }
                });
            }) : ((Promise.resolve()))) : ((Promise.resolve()))))).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => PromiseBuilder__For_1565554B(promise, qrFiles, (_arg6) => {
                const qr_2 = _arg6[1];
                const file_3 = _arg6[0];
                if (qr_2.tag === 1) {
                    return Promise.resolve();
                }
                else {
                    const target = qr_2.fields[0][2];
                    const activity = qr_2.fields[0][0];
                    const actionOpt = qr_2.fields[0][1];
                    const taskId_3 = newGuid();
                    let targetName;
                    if (target != null) {
                        const t = target;
                        targetName = (`-${DomainEntity__get_Name(t)}`);
                    }
                    else {
                        targetName = "";
                    }
                    const name_3 = (actionOpt != null) ? actionOpt.Name : defaultArg(activity.Name, "");
                    let date;
                    let copyOfStruct = utcNow();
                    date = toString_1(copyOfStruct, "yyyy-MM-dd");
                    const name_4 = `${name_3}${targetName}-${date}`;
                    taskDispatch(new TaskMsg(0, taskId_3, `Upload ${name_4}`));
                    let patternInput_12;
                    if (file_3.tag === 1) {
                        const names = join(", ", file_3.fields[1]);
                        patternInput_12 = [`EPM Generated PDF: ${names}`, file_3.fields[0]];
                    }
                    else {
                        const file_4 = file_3.fields[0];
                        patternInput_12 = [file_4.name, file_4];
                    }
                    const create_3 = new Documents_CreateDocument(name_4, patternInput_12[0], empty_1(), ofList(singleton([new DomainEntities(5), ofList_1(singleton(activity.Id), {
                        Compare: comparePrimitives,
                    })])), void 0);
                    taskDispatch(new TaskMsg(2, taskId_3));
                    return createUpload(projectId, create_3, patternInput_12[1]).then((_arg7) => {
                        const result_4 = _arg7;
                        return ((result_4.tag === 1) ? ((taskDispatch(new TaskMsg(6, taskId_3, result_4.fields[0])), Promise.resolve())) : ((taskDispatch(new TaskMsg(3, taskId_3, void 0)), Promise.resolve()))).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            const signOffTaskId = newGuid();
                            const signOff = new Activities_Signed(utcNow(), void 0);
                            taskDispatch(new TaskMsg(0, signOffTaskId, `Sign off ${name_4}${targetName}`));
                            return signOff_1(projectId, activity.Id, signOff).then((_arg8) => {
                                const signOffResult = _arg8;
                                if (signOffResult.tag === 1) {
                                    taskDispatch(new TaskMsg(6, signOffTaskId, signOffResult.fields[0]));
                                    return Promise.resolve();
                                }
                                else {
                                    taskDispatch(new TaskMsg(3, signOffTaskId, void 0));
                                    return Promise.resolve();
                                }
                            });
                        }));
                    });
                }
            })));
        }));
        void pr_5;
    }, [name, detail, externalUrl, qrFiles, nonQRFiles]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_51 = toList(delay(() => {
            let elms_1, elms, props_1;
            return append((length(uploadFiles) !== 1) ? append(singleton_1((elms_1 = ofArray([createElement("label", {
                className: "label",
                children: "Name",
            }), (elms = singleton((props_1 = ofArray([["onChange", (ev) => {
                setName(ev.target.value);
            }], ["defaultValue", name], ["disabled", ((length(uploadFiles) > 1) ? true : (length(qrFiles) > 0)) ? true : processingQR]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_1)))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))]), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))), delay(() => {
                let elms_3, elms_2, props_6;
                return singleton_1((elms_3 = ofArray([createElement("label", {
                    className: "label",
                    children: "Detail",
                }), (elms_2 = singleton((props_6 = ofArray([["onChange", (ev_1) => {
                    setDetail(ev_1.target.value);
                }], ["defaultValue", detail], ["disabled", ((length(uploadFiles) > 1) ? true : (length(qrFiles) > 0)) ? true : processingQR]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_6)))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))]), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                })));
            })) : empty_2(), delay(() => {
                let elms_4, s_2, props_14, elems_4;
                return append(singleton_1((elms_4 = ofArray([(s_2 = (uploadByFile ? "Upload File" : "Upload Url"), createElement("label", {
                    className: "label",
                    children: s_2,
                })), (props_14 = ofArray([["className", join(" ", ["description-toggle", "pretty", "p-switch", "p-fill"])], (elems_4 = [createElement("input", {
                    type: "checkbox",
                    defaultChecked: uploadByFile,
                    onClick: (_arg1_1) => {
                        patternInput_5[1](!uploadByFile);
                    },
                    disabled: processingQR,
                }), createElement("div", {
                    className: "state",
                    children: createElement("label", {}),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]), createElement("div", createObj(Helpers_combineClasses("control", props_14))))]), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                }))), delay(() => {
                    let elms_8, props_27, elms_12, elms_11;
                    return append(uploadByFile ? append(singleton_1((elms_8 = singleton((props_27 = toList(delay(() => append(singleton_1(["className", "is-boxed"]), delay(() => append(singleton_1(["className", "has-name"]), delay(() => append(patternInput_3[0] ? singleton_1(["className", "is-warning"]) : singleton_1(["className", "is-info"]), delay(() => {
                        let elems_10, elems_9, elms_7;
                        return singleton_1((elems_10 = [createElement("div", createObj(ofArray([["onDragEnter", (_arg2_1) => {
                            setIsDrag(true);
                        }], ["onDragLeave", (_arg3_1) => {
                            setIsDrag(false);
                        }], ["onDragOver", (e) => {
                            e.preventDefault();
                            setIsDrag(true);
                        }], ["onDrop", (e_1) => {
                            let list_1, objectArg;
                            e_1.preventDefault();
                            setUploadFiles((list_1 = toList(rangeDouble(0, 1, e_1.dataTransfer.files.length - 1)), map((objectArg = e_1.dataTransfer.files, (arg00) => (objectArg[arg00])), list_1)));
                            setIsDrag(false);
                        }], (elems_9 = [(elms_7 = toList(delay(() => append(singleton_1(createElement("input", createObj(cons(["type", "file"], Helpers_combineClasses("file-input", ofArray([["type", "file"], ["multiple", true], ["onChange", (ev_2) => {
                            const fileList = ev_2.target.files;
                            if (!(fileList == null)) {
                                setUploadFiles(toList(delay(() => map_2((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1)))));
                            }
                        }], ["disabled", processingQR]])))))), delay(() => {
                            let elms_6, elms_5;
                            return append(singleton_1((elms_6 = ofArray([(elms_5 = singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-file-upload")), [])), createElement("span", {
                                className: "file-icon",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                            })), createElement("span", {
                                className: "file-label",
                                children: "Choose Documents...",
                            })]), createElement("span", {
                                className: "file-cta",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                            }))), delay(() => {
                                let s_5, s_6;
                                return processingQR ? singleton_1(createElement("span", {
                                    className: "file-name",
                                    children: "Processing QR...",
                                })) : ((!isEmpty(uploadFiles)) ? (isEmpty(tail(uploadFiles)) ? singleton_1((s_5 = head(uploadFiles).name, createElement("span", {
                                    className: "file-name",
                                    children: s_5,
                                }))) : singleton_1((s_6 = (`${length(uploadFiles)} documents`), createElement("span", {
                                    className: "file-name",
                                    children: s_6,
                                })))) : singleton_1((s_6 = (`${length(uploadFiles)} documents`), createElement("span", {
                                    className: "file-name",
                                    children: s_6,
                                }))));
                            }));
                        })))), createElement("label", {
                            className: "file-label",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))]));
                    })))))))), createElement("div", createObj(Helpers_combineClasses("file", props_27))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
                    }))), delay(() => {
                        let elms_10, elms_9, props_36, elems_13;
                        return singleton_1((elms_10 = ofArray([(elms_9 = toList(delay(() => (detectQR ? append(singleton_1("Detect QR Code"), delay(() => append(singleton_1(createElement("br", {})), delay(() => singleton_1(createElement("small", {
                            children: ["(this will attempt to sign off the activity and reference the document against it, files with errors will not be uploaded)"],
                        })))))) : singleton_1("Do Not Detect QR")))), createElement("label", {
                            className: "label",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                        })), (props_36 = ofArray([["className", join(" ", ["description-toggle", "pretty", "p-switch", "p-fill"])], (elems_13 = [createElement("input", {
                            type: "checkbox",
                            defaultChecked: detectQR,
                            onClick: (_arg4_1) => {
                                patternInput_11[1](!detectQR);
                            },
                            disabled: processingQR,
                        }), createElement("div", {
                            className: "state",
                            children: createElement("label", {}),
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]), createElement("div", createObj(Helpers_combineClasses("control", props_36))))]), createElement("div", {
                            className: "field",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
                        })));
                    })) : singleton_1((elms_12 = ofArray([createElement("label", {
                        className: "label",
                        children: "Url",
                    }), (elms_11 = singleton(createElement("input", createObj(cons(["type", "url"], Helpers_combineClasses("input", ofArray([["onChange", (ev_3) => {
                        patternInput_4[1](ev_3.target.value);
                    }], ["defaultValue", externalUrl]])))))), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
                    }))]), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
                    }))), delay(() => {
                        let props_71, elems_20, props_69, elems_19, children_15, children_13, children_23;
                        return isEmpty(qrFiles) ? singleton_1(null) : singleton_1((props_71 = ofArray([["className", "is-info"], ["className", "is-light"], (elems_20 = [createElement("h1", {
                            className: "title",
                            children: "QR Files",
                        }), createElement("h2", {
                            className: "subtitle",
                            children: "The following files will be referenced and signed off and named based on the activity",
                        }), (props_69 = ofArray([["className", "is-bordered"], ["className", "is-fullwidth"], ["className", "is-striped"], ["className", "is-narrow"], (elems_19 = [(children_15 = singleton((children_13 = ofArray([createElement("th", {
                            children: ["File Name"],
                        }), createElement("th", {
                            children: ["Action"],
                        }), createElement("th", {
                            children: ["Target"],
                        }), createElement("th", {
                            children: ["Error"],
                        }), createElement("th", {
                            children: ["Preview"],
                        })]), createElement("tr", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_13)),
                        }))), createElement("thead", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_15)),
                        })), (children_23 = toList(delay(() => map((tupledArg_2) => {
                            const file_6 = tupledArg_2[0];
                            const result_5 = tupledArg_2[1];
                            return createElement("tr", createObj(toList(delay(() => append((result_5.tag === 1) ? singleton_1(op_PlusPlus(["className", "has-background-danger-light"], ["className", "has-text-black"])) : ((empty_2())), delay(() => {
                                let elems_18;
                                return singleton_1((elems_18 = toList(delay(() => {
                                    let children_19, children_17, value_180;
                                    return append((file_6.tag === 1) ? singleton_1((children_19 = singleton((children_17 = map((arg00_1) => createElement("li", {
                                        children: [arg00_1],
                                    }), file_6.fields[1]), createElement("ul", {
                                        children: Interop_reactApi.Children.toArray(Array.from(children_17)),
                                    }))), createElement("td", {
                                        children: Interop_reactApi.Children.toArray(Array.from(children_19)),
                                    }))) : singleton_1((value_180 = file_6.fields[0].name, createElement("td", {
                                        children: [value_180],
                                    }))), delay(() => {
                                        let e_2, value_183;
                                        return append((result_5.tag === 1) ? ((e_2 = result_5.fields[0], append(singleton_1(createElement("td", {
                                            children: [null],
                                        })), delay(() => append(singleton_1(createElement("td", {
                                            children: [null],
                                        })), delay(() => {
                                            let value_191;
                                            return (e_2.tag === 0) ? singleton_1(createElement("td", {
                                                children: ["Unknown QR Format"],
                                            })) : ((e_2.tag === 2) ? singleton_1((value_191 = (`Missing: ${EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(e_2.fields[0])}`), createElement("td", {
                                                children: [value_191],
                                            }))) : singleton_1(createElement("td", {
                                                children: ["Wrong Project"],
                                            })));
                                        })))))) : append(singleton_1((value_183 = defaultArg(defaultArg(map_1((action_1) => action_1.Name, result_5.fields[0][1]), result_5.fields[0][0].Name), ""), createElement("td", {
                                            children: [value_183],
                                        }))), delay(() => {
                                            let value_185;
                                            return append(singleton_1((value_185 = defaultArg(map_1(DomainEntity__get_Name, result_5.fields[0][2]), "None"), createElement("td", {
                                                children: [value_185],
                                            }))), delay(() => singleton_1(createElement("td", {
                                                children: [null],
                                            }))));
                                        })), delay(() => {
                                            let children_21;
                                            return singleton_1((children_21 = singleton(createElement("a", createObj(toList(delay(() => append(singleton_1(["className", "has-text-link"]), delay(() => append((file_6.tag === 1) ? singleton_1(["href", URL.createObjectURL(file_6.fields[0])]) : singleton_1(["href", URL.createObjectURL(file_6.fields[0])]), delay(() => append(singleton_1(["target", "_blank"]), delay(() => append(singleton_1(["rel", "noopener noreferrer"]), delay(() => {
                                                let elems_17;
                                                return singleton_1((elems_17 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-external-link-alt")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))]));
                                            }))))))))))))), createElement("td", {
                                                children: Interop_reactApi.Children.toArray(Array.from(children_21)),
                                            })));
                                        }));
                                    }));
                                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))]));
                            }))))));
                        }, qrFiles))), createElement("tbody", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_23)),
                        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]), createElement("table", createObj(Helpers_combineClasses("table", props_69))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]), createElement("div", createObj(Helpers_combineClasses("notification", props_71)))));
                    }));
                }));
            }));
        })), react.createElement(react.Fragment, {}, ...xs_51)),
        submit: create_4,
        submitText: "Create",
        tasks: patternInput[0],
        title: "Create",
    });
}

export function Update(updateInputProps) {
    let xs_10, elms_1, elms, elms_3, elms_2;
    const close = updateInputProps.close;
    const document$ = updateInputProps.document;
    const projectId = updateInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(document$.Name);
    const name = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(defaultArg(document$.Detail, ""));
    const detail = patternInput_2[0];
    const updates = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (isNullOrWhiteSpace(name)) {
                return Promise.resolve();
            }
            else {
                const taskId = newGuid();
                taskDispatch(new TaskMsg(0, taskId, `Update ${name}`));
                const update = new Documents_UpdateDocument(name, detail);
                taskDispatch(new TaskMsg(2, taskId));
                return update_1(projectId, document$.Id, update).then((_arg1) => {
                    const result = _arg1;
                    if (result.tag === 1) {
                        taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, taskId, void 0));
                        return Promise.resolve();
                    }
                });
            }
        }));
        void pr;
    }, [name, detail]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_10 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Name",
        }), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            patternInput_1[1](ev.target.value);
        }], ["defaultValue", name]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_3 = ofArray([createElement("label", {
            className: "label",
            children: "Detail",
        }), (elms_2 = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev_1) => {
            patternInput_2[1](ev_1.target.value);
        }], ["defaultValue", detail]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))], react.createElement(react.Fragment, {}, ...xs_10)),
        submit: updates,
        submitText: "Update",
        tasks: patternInput[0],
        title: "Update",
    });
}

class ReferencesState extends Record {
    constructor(References) {
        super();
        this.References = References;
    }
}

function ReferencesState$reflection() {
    return record_type("EPM.App.Modals.Document.ReferencesState", [], ReferencesState, () => [["References", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [DomainEntities$reflection(), class_type("Microsoft.FSharp.Collections.FSharpSet`1", [class_type("System.Guid")])])]]);
}

class ReferenceMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeReferences"];
    }
}

function ReferenceMsg$reflection() {
    return union_type("EPM.App.Modals.Document.ReferenceMsg", [], ReferenceMsg, () => [[["Item1", DomainEntities$reflection()], ["Item2", list_type(class_type("System.Guid"))]]]);
}

function initReferences(existingReferences) {
    return [new ReferencesState(existingReferences), Cmd_none()];
}

function updateReferences(msg, state) {
    const entities = msg.fields[1];
    return [new ReferencesState(change_1(msg.fields[0], (_arg1) => {
        if (isEmpty(entities)) {
            return void 0;
        }
        else {
            return ofList_1(entities, {
                Compare: comparePrimitives,
            });
        }
    }, state.References)), Cmd_none()];
}

const tabs = ofArray([new DomainEntities(13), new DomainEntities(1), new DomainEntities(2), new DomainEntities(3), new DomainEntities(6), new DomainEntities(7), new DomainEntities(8), new DomainEntities(0), new DomainEntities(9), new DomainEntities(11), new DomainEntities(5)]);

export function ChangeReferences(changeReferencesInputProps) {
    let xs_5, elms, children, elms_1;
    const close = changeReferencesInputProps.close;
    const document$ = changeReferencesInputProps.document;
    const projectId = changeReferencesInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new DomainEntities(13));
    const currentTab = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useElmish_Static_78C5B8C8(initReferences(document$.References), updateReferences, []);
    const state_1 = patternInput_2[0];
    const initialEntities = useReact_useMemo_CF4EA67(() => defaultArg(map_1(toList_1, tryFind(currentTab, state_1.References)), empty()), [currentTab]);
    const setEntities = useReact_useCallback_93353E((entities) => {
        patternInput_2[1](new ReferenceMsg(0, currentTab, map(DomainEntity__get_Id, entities)));
    }, [currentTab]);
    const change = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const taskId = newGuid();
            taskDispatch(new TaskMsg(0, taskId, `Change References ${document$.Name}`));
            const changeReferences = new Documents_ChangeReferences(state_1.References);
            taskDispatch(new TaskMsg(2, taskId));
            return changeReferences_1(projectId, document$.Id, changeReferences).then((_arg1) => {
                const result = _arg1;
                if (result.tag === 1) {
                    taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                    return Promise.resolve();
                }
                else {
                    taskDispatch(new TaskMsg(3, taskId, void 0));
                    return Promise.resolve();
                }
            });
        }));
        void pr;
    }, [state_1.References]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_5 = [(elms = singleton((children = toList(delay(() => map((tab) => {
            const props_1 = toList(delay(() => append(equals(tab, currentTab) ? singleton_1(["className", "is-active"]) : empty_2(), delay(() => singleton_1(["children", createElement("a", {
                children: `${EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(tab)} (${defaultArg(map_1(FSharpSet__get_Count, FSharpMap__TryFind(state_1.References, tab)), 0)})`,
                onClick: (e_1) => {
                    e_1.preventDefault();
                    patternInput_1[1](tab);
                },
            })])))));
            return createElement("li", createObj(Helpers_combineClasses("", props_1)));
        }, tabs))), createElement("ul", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))), createElement("div", {
            className: "tabs",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = singleton(createElement(DomainEntitiesSelector, {
            projectId: projectId,
            entityType: currentTab,
            initialEntities: initialEntities,
            onSelectedEntitiesChange: setEntities,
            filters: empty(),
            isSmall: false,
        })), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))], react.createElement(react.Fragment, {}, ...xs_5)),
        submit: change,
        submitText: "Change",
        tasks: patternInput[0],
        title: "Change References",
    });
}

const exportReferencesHeaders = ofArray([{
    key: "documentId",
    name: "DocumentId",
}, {
    key: "documentName",
    name: "DocumentName",
}, {
    key: "documentDetail",
    name: "DocumentDetail",
}, {
    key: "entityId",
    name: "EntityId",
}, {
    key: "entityType",
    name: "EntityType",
}]);

function exportReferences(document$, entityType, entityId) {
    let list2;
    return ofList((list2 = choose((x) => x, singleton(map_1((e) => ["documentDetail", e], document$.Detail))), append_1(ofArray([["documentId", document$.Id], ["documentName", document$.Name], ["entityId", entityId], ["entityType", toString_2(entityType)]]), list2)));
}

function downloadReferencesCSV(setProcessing, setTotal, setFetched, projectId, filters) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        setProcessing(true);
        return count(projectId, filters).then((_arg1) => {
            let initialCount, chunks;
            const initialCountResult = _arg1;
            let results = empty();
            let fetchedCount = 0;
            return ((initialCountResult.tag === 0) ? ((initialCount = initialCountResult.fields[0], (setTotal(initialCount), (chunks = (((~(~(initialCount / 100))) + (((initialCount % 100) > 0) ? 1 : 0)) | 0), PromiseBuilder__For_1565554B(promise, rangeDouble(0, 1, chunks - 1), (_arg2) => {
                const apiParams = ofArray([["fetch", int32ToString(100)], ["offset", int32ToString(_arg2 * 100)]]);
                return many(projectId, apiParams, filters).then((_arg3) => {
                    const dataResult = _arg3;
                    if (dataResult.tag === 0) {
                        const documents = dataResult.fields[0];
                        const processedData = map((tupledArg) => exportReferences(tupledArg[0], tupledArg[1], tupledArg[2]), collect((document$) => ofSeq(collect_1((reference) => ofSeq(map_2((v) => [document$, reference[0], v], reference[1])), document$.References)), documents));
                        results = append_1(processedData, results);
                        fetchedCount = ((fetchedCount + length(documents)) | 0);
                        setFetched(fetchedCount);
                        return Promise.resolve();
                    }
                    else {
                        console.error(some(dataResult.fields[0]));
                        return Promise.resolve();
                    }
                });
            }).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const processedData_1 = reverse(results);
                const headers = ofSeq(exportReferencesHeaders);
                const csv = StringBuilder_$ctor();
                StringBuilder__AppendLine_Z721C83C5(csv, join(",", map((header) => Csv_encode(header.name), headers)));
                iterate((row) => {
                    StringBuilder__AppendLine_Z721C83C5(csv, row);
                }, map((data) => join(",", map((header_1) => defaultArg(map_1(Csv_encode, FSharpMap__TryFind(data, header_1.key)), ""), headers)), processedData_1));
                const content = toString_2(csv);
                let now;
                let copyOfStruct = utcNow_1();
                now = toString_1(copyOfStruct, "yyyy-MM-dd hh:mm:ss");
                file_saver(new Blob([content], { 'type': "text/csv" }), `document-references-export-${now}.csv`);
                return Promise.resolve();
            })))))) : ((console.error(some(initialCountResult.fields[0])), Promise.resolve()))).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                setProcessing(false);
                return Promise.resolve();
            }));
        });
    }));
    void pr;
}

export function ExportReferences(exportReferencesInputProps) {
    let props, props_2;
    const close = exportReferencesInputProps.close;
    const filters = exportReferencesInputProps.filters;
    const projectId = exportReferencesInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const total = patternInput[0] | 0;
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const fetched = patternInput_1[0] | 0;
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const processing = patternInput_2[0];
    return createElement(Modal_ModalWithFooter, {
        close: close,
        content: (props = ofArray([["className", "is-primary"], ["max", total], ["value", fetched], ["title", toText(interpolate("%.2f%P()%%", [(total === 0) ? 0 : ((fetched / total) * 100)]))]]), createElement("progress", createObj(Helpers_combineClasses("progress", props)))),
        disabled: processing,
        footer: singleton((props_2 = toList(delay(() => append(processing ? singleton_1(["className", "is-loading"]) : empty_2(), delay(() => append(singleton_1(["disabled", processing]), delay(() => append(singleton_1(["children", "Download"]), delay(() => singleton_1(["onClick", (_arg1) => {
            downloadReferencesCSV(patternInput_2[1], patternInput[1], patternInput_1[1], projectId, filters);
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_2))))),
        title: "Export References as CSV",
    });
}

