import { Union } from "../fable_modules/fable-library.3.7.3/Types.js";
import { union_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { op_PlusPlus } from "../fable_modules/Feliz.Bulma.2.18.0/Operators.fs.js";
import { Fa_IconOption, Fa_ISize, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Fetched$1_toOption_50D43789, useFeliz_React__React_useFetch_Static_Z4BDF3CFF } from "../hooks/UseFetch.fs.js";
import { config as config_1 } from "../Api/Projects.fs.js";
import { ProjectPage__get_Href, ProjectPage__get_ProjectId } from "../ClientTypes.fs.js";
import { getUser, getTokenProfile } from "../Api/Auth.fs.js";
import { React_contextProvider_34D9BBBD, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { useFeliz_React__React_useInfoPanel_Static, useFeliz_React__React_useSearchType_Static } from "../hooks/UseRouter.fs.js";
import { defaultArg, some } from "../fable_modules/fable-library.3.7.3/Option.js";
import { Dashboard } from "../Dashboard/Dashboard.fs.js";
import { Progress } from "../Progress/Progress.fs.js";
import { ProgressSub } from "../Progress/ProgressSub.fs.js";
import { ProgressDisc } from "../Progress/ProgressDisc.fs.js";
import { ModelViewer } from "../ModelViewer/ModelViewer.fs.js";
import { Home } from "./Home.fs.js";
import { Definition } from "../Definition/Definition.fs.js";
import { Admin } from "../Admin/Admin.fs.js";
import { ProjectContext, Context_ProjectContext } from "../hooks/UseProjectContext.fs.js";
import { Header } from "./Header.fs.js";
import { join } from "../fable_modules/fable-library.3.7.3/String.js";
import { Search } from "../Search/Search.fs.js";
import { InfoPanelType, InfoPanel } from "../InfoPanels/InfoPanel.fs.js";
import "./Project.scss";


export class MobileFocus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Filter", "Content", "Results", "InfoPanel"];
    }
}

export function MobileFocus$reflection() {
    return union_type("EPM.App.Project.ProjectPage.MobileFocus", [], MobileFocus, () => [[], [], [], []]);
}

function navBarButton(isActive, buttonIcon, onClick) {
    const props_2 = toList(delay(() => append(singleton(["className", "is-tab"]), delay(() => append(isActive ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let elems_1, props, elems;
        return singleton((elems_1 = [(props = ofArray([op_PlusPlus(["className", "is-medium"], isActive ? ["className", "has-text-white"] : ["className", "has-text-primary-dark"]), ["onClick", onClick], (elems = [Fa_i(ofArray([buttonIcon, new Fa_IconOption(0, new Fa_ISize(3))]), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]));
    }))))));
    return createElement("div", createObj(Helpers_combineClasses("navbar-item", props_2)));
}

export function Project(props) {
    let elems_1, elms, children_5, children_1, elems_2, props_16, children_3, xs_20, elems_5, props_29, elems_6;
    const configFetch = useFeliz_React__React_useFetch_Static_Z4BDF3CFF(config_1, ProjectPage__get_ProjectId(props.projectPage), [ProjectPage__get_ProjectId(props.projectPage)]);
    const profileOpt = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF(getTokenProfile, void 0, []));
    const userOpt = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF(getUser, void 0, []));
    const patternInput = useFeliz_React__React_useState_Static_1505(new MobileFocus(1));
    const setMobileTab = patternInput[1];
    const currentMobileTab = patternInput[0];
    const searchResultsOpt = useFeliz_React__React_useSearchType_Static();
    const infoPanelOpt = useFeliz_React__React_useInfoPanel_Static();
    const matchValue = [configFetch, profileOpt, userOpt];
    let pattern_matching_result, failed, config, profile, user;
    if (matchValue[0].tag === 3) {
        pattern_matching_result = 1;
        failed = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 1) {
        pattern_matching_result = 2;
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[1] != null) {
            if (matchValue[2] != null) {
                pattern_matching_result = 3;
                config = matchValue[0].fields[0];
                profile = matchValue[1];
                user = matchValue[2];
            }
            else {
                pattern_matching_result = 4;
            }
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-info"], ["children", "Prepairing to get project config"]]))));
        }
        case 1: {
            const props_12 = ofArray([["className", "is-danger"], (elems_1 = [createElement("div", createObj(Helpers_combineClasses("message-header", singleton_1(["children", "Something has gone wrong"])))), (elms = toList(delay(() => {
                let value_10, value_13, value_14;
                switch (failed.tag) {
                    case 0: {
                        const pd = failed.fields[1];
                        console.error(some(pd));
                        return singleton((value_10 = defaultArg(pd.Detail, pd.Title), createElement("p", {
                            children: [value_10],
                        })));
                    }
                    case 1: {
                        const e = failed.fields[0];
                        switch (e.tag) {
                            case 2: {
                                console.error(some(e.fields[0]));
                                return singleton(createElement("p", {
                                    children: ["Unable get project config"],
                                }));
                            }
                            case 3: {
                                const n = e.fields[0];
                                console.error(some(n));
                                return singleton((value_13 = (`Network error: ${n.message}`), createElement("p", {
                                    children: [value_13],
                                })));
                            }
                            case 0: {
                                const n_1 = e.fields[0];
                                console.error(some(n_1));
                                return singleton((value_14 = (`Could not start to get the project config: ${n_1.message}`), createElement("p", {
                                    children: [value_14],
                                })));
                            }
                            default: {
                                console.error(some(e.fields[0]));
                                return singleton(createElement("p", {
                                    children: ["Unable to understand project config"],
                                }));
                            }
                        }
                    }
                    default: {
                        console.error(some(failed.fields[0]));
                        return singleton(createElement("p", {
                            children: ["Something major has gone wrong"],
                        }));
                    }
                }
            })), createElement("div", {
                className: "message-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]);
            return createElement("article", createObj(Helpers_combineClasses("message", props_12)));
        }
        case 2: {
            return createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-info"], ["children", "Fetching project config"]]))));
        }
        case 3: {
            let page;
            const matchValue_1 = props.projectPage;
            switch (matchValue_1.tag) {
                case 2: {
                    page = createElement(Dashboard, {
                        projectId: matchValue_1.fields[0],
                    });
                    break;
                }
                case 3: {
                    page = createElement(Progress, {
                        projectId: matchValue_1.fields[0],
                    });
                    break;
                }
                case 4: {
                    page = createElement(ProgressSub, {
                        projectId: matchValue_1.fields[0],
                    });
                    break;
                }
                case 5: {
                    page = createElement(ProgressDisc, {
                        projectId: matchValue_1.fields[0],
                    });
                    break;
                }
                case 6: {
                    page = createElement(ModelViewer, {
                        projectId: matchValue_1.fields[0],
                        initialModelId: matchValue_1.fields[1],
                    });
                    break;
                }
                case 0: {
                    page = createElement(Home, {
                        projectId: matchValue_1.fields[0],
                    });
                    break;
                }
                case 1: {
                    page = createElement(Definition, {
                        projectId: matchValue_1.fields[0],
                    });
                    break;
                }
                case 8: {
                    page = (`QRCode: ${matchValue_1.fields[0]} - ${matchValue_1.fields[1]} - ${matchValue_1.fields[2]}`);
                    break;
                }
                default: {
                    page = createElement(Admin, {
                        projectId: matchValue_1.fields[0],
                        adminPage: matchValue_1.fields[1],
                    });
                }
            }
            let breadcrumb;
            const elms_1 = singleton_1((children_5 = ofArray([(children_1 = singleton_1(createElement("a", createObj(ofArray([["href", ProjectPage__get_Href(props.projectPage)], (elems_2 = [(props_16 = ofArray([["className", "is-small"], ["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-home")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_16)))), createElement("span", {
                children: ["Home"],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), createElement("li", {
                children: Interop_reactApi.Children.toArray(Array.from(children_1)),
            })), (children_3 = singleton_1(createElement("a", {
                children: "A",
                href: "A",
            })), createElement("li", {
                children: Interop_reactApi.Children.toArray(Array.from(children_3)),
            }))]), createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children_5)),
            })));
            breadcrumb = createElement("nav", {
                className: "breadcrumb",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            });
            return React_contextProvider_34D9BBBD(Context_ProjectContext, new ProjectContext(ProjectPage__get_ProjectId(props.projectPage), config, profile.profile, user), (xs_20 = [createElement(Header, null), createElement("section", createObj(ofArray([["className", join(" ", ["project", "columns", "is-gapless"])], (elems_5 = toList(delay(() => {
                let entityType_1, projectId_10;
                return append((searchResultsOpt == null) ? singleton(null) : ((entityType_1 = searchResultsOpt, singleton(createElement(Search, (projectId_10 = ProjectPage__get_ProjectId(props.projectPage), {
                    entityType: entityType_1,
                    isVisibleMobileFilter: equals(currentMobileTab, new MobileFocus(0)),
                    isVisibleMobileResults: equals(currentMobileTab, new MobileFocus(2)),
                    projectId: projectId_10,
                }))))), delay(() => {
                    let props_26;
                    return append(singleton((props_26 = toList(delay(() => append(singleton(["className", "project-content"]), delay(() => append((!equals(currentMobileTab, new MobileFocus(1))) ? singleton(["className", "is-hidden-mobile"]) : empty(), delay(() => {
                        let elems_4;
                        return singleton((elems_4 = [createElement("div", {
                            className: "project-page",
                            children: page,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))]));
                    })))))), createElement("div", createObj(Helpers_combineClasses("column", props_26))))), delay(() => {
                        let $keyZ3629A10E;
                        if (infoPanelOpt == null) {
                            return empty();
                        }
                        else {
                            const infoPanel = infoPanelOpt;
                            if (infoPanel.tag === 0) {
                                return singleton(($keyZ3629A10E = "00000000-0000-0000-0000-000000000000", createElement(InfoPanel, {
                                    key: $keyZ3629A10E,
                                    $key: $keyZ3629A10E,
                                    projectId: ProjectPage__get_ProjectId(props.projectPage),
                                    isVisibleMobile: equals(currentMobileTab, new MobileFocus(3)),
                                    infoPanelType: new InfoPanelType(0),
                                })));
                            }
                            else {
                                const entityId_1 = infoPanel.fields[0];
                                return singleton(createElement(InfoPanel, {
                                    key: entityId_1,
                                    $key: entityId_1,
                                    projectId: ProjectPage__get_ProjectId(props.projectPage),
                                    isVisibleMobile: equals(currentMobileTab, new MobileFocus(3)),
                                    infoPanelType: new InfoPanelType(1, infoPanel.fields[1], entityId_1),
                                }));
                            }
                        }
                    }));
                }));
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), (props_29 = ofArray([op_PlusPlus(op_PlusPlus(op_PlusPlus(["className", "is-fixed-bottom"], ["className", "is-hidden-tablet"]), ["className", "is-radiusless"]), ["className", "mobile-panel-selection"]), (elems_6 = toList(delay(() => append(singleton(navBarButton(equals(currentMobileTab, new MobileFocus(0)), new Fa_IconOption(11, "fas fa-filter"), (_arg1) => {
                setMobileTab(new MobileFocus(0));
            })), delay(() => append(singleton(navBarButton(equals(currentMobileTab, new MobileFocus(2)), new Fa_IconOption(11, "fas fa-list"), (_arg2) => {
                setMobileTab(new MobileFocus(2));
            })), delay(() => append(singleton(navBarButton(equals(currentMobileTab, new MobileFocus(1)), new Fa_IconOption(11, "fas fa-dot-circle"), (_arg3) => {
                setMobileTab(new MobileFocus(1));
            })), delay(() => ((infoPanelOpt != null) ? singleton(navBarButton(equals(currentMobileTab, new MobileFocus(3)), new Fa_IconOption(11, "fas fa-info"), (_arg4) => {
                setMobileTab(new MobileFocus(3));
            })) : singleton(navBarButton(equals(currentMobileTab, new MobileFocus(3)), new Fa_IconOption(11, "fas fa-ban"), (value_58) => {
            }))))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]), createElement("nav", createObj(Helpers_combineClasses("navbar", props_29))))], react.createElement(react.Fragment, {}, ...xs_20)));
        }
        case 4: {
            return null;
        }
    }
}

