import { ChangeAttributes_Encoder_69034AA9, ChangeLabels_Encoder_19803C73, DeleteEntity_Encoder_Z66C2339E, Systems_SystemOwnerData_Encoder_97448AC, Systems_AssignStatus_Encoder_51AAE3AD, Systems_AssignProductionUnit_Encoder_1F556B5C, Systems_UpdateSystem_Encoder_Z5F0F86D4, Systems_CreateSystem_Encoder_3D96A59, labelDecoder, Systems_Sys$reflection, Systems_Sys_get_Decoder } from "../ServerTypes.fs.js";
import { RouterModule_encodeQueryString } from "../fable_modules/Feliz.Router.3.8.0/Router.fs.js";
import { cons, ofArray, empty, append } from "../fable_modules/fable-library.3.7.3/List.js";
import { printf, toConsole } from "../fable_modules/fable-library.3.7.3/String.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { Helpers_baseApiUrl, Helpers_withSecureHeader } from "./Api.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Thoth.Fetch.3.0.1/../Fable.Promise.3.1.3/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Thoth.Fetch.3.0.1/../Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties, FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { some, map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.4.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.3/MapUtil.js";
import { toString } from "../fable_modules/Thoth.Fetch.3.0.1/../Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { class_type, string_type, int32_type, unit_type, equals, list_type, obj_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { int, list, Auto_generateBoxedDecoderCached_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { uncurry } from "../fable_modules/fable-library.3.7.3/Util.js";
import { fromString } from "../fable_modules/Thoth.Fetch.3.0.1/../Thoth.Json.7.0.0/Decode.fs.js";

export function many(projectId, apiParams, systemParams) {
    const decoder = Systems_Sys_get_Decoder();
    const newUrl = (`projects/${projectId}/systems`) + RouterModule_encodeQueryString(append(apiParams, systemParams));
    toConsole(printf("%s"))(newUrl);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1_1 = `${Helpers_baseApiUrl}/${newUrl}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_2;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_2 = Auto_generateBoxedDecoderCached_79988AEF(list_type(Systems_Sys$reflection()), caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_2)))((path) => ((value) => list(uncurry(2, decoder), path, value))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(list_type(Systems_Sys$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function count(projectId, searchParams) {
    const newUrl = (`projects/${projectId}/systems/count`) + RouterModule_encodeQueryString(searchParams);
    toConsole(printf("%s"))(newUrl);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1_1 = `${Helpers_baseApiUrl}/${newUrl}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(int32_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(int), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(int32_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function single(projectId, systemId) {
    const decoder = Systems_Sys_get_Decoder();
    const url = `projects/${projectId}/systems/${systemId}`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(Systems_Sys$reflection(), caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(decoder), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(Systems_Sys$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function labels(projectId, systemParams) {
    const newUrl = (`projects/${projectId}/systems/labels`) + RouterModule_encodeQueryString(systemParams);
    toConsole(printf("%s"))(newUrl);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1_1 = `${Helpers_baseApiUrl}/${newUrl}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])]), caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(labelDecoder), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])]), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function create(projectId, createSystem) {
    const url = `projects/${projectId}/systems`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Systems_CreateSystem_Encoder_3D96A59(createSystem);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function update(projectId, systemId, updateSystem) {
    const url = `projects/${projectId}/systems/${systemId}/update-system`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Systems_UpdateSystem_Encoder_Z5F0F86D4(updateSystem);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function assignProductionUnit(projectId, systemId, assignProductionUnit_1) {
    const url = `projects/${projectId}/systems/${systemId}/assign-production-unit`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Systems_AssignProductionUnit_Encoder_1F556B5C(assignProductionUnit_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function assignStatus(projectId, systemId, assignStatus_1) {
    const url = `projects/${projectId}/systems/${systemId}/assign-status`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Systems_AssignStatus_Encoder_51AAE3AD(assignStatus_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function setSystemOwner(projectId, systemId, systemOwner) {
    const url = `projects/${projectId}/systems/${systemId}/set-system-owner`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Systems_SystemOwnerData_Encoder_97448AC(systemOwner);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function removeSystemOwner(projectId, systemId, systemOwner) {
    const url = `projects/${projectId}/systems/${systemId}/remove-system-owner`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Systems_SystemOwnerData_Encoder_97448AC(systemOwner);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function delete$(projectId, systemId, delete$_1) {
    const url = `projects/${projectId}/systems/${systemId}`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = DeleteEntity_Encoder_Z66C2339E(delete$_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("DELETE", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function undelete(projectId, systemsId, delete$_1) {
    const url = `projects/${projectId}/systems/${systemsId}/undelete`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = DeleteEntity_Encoder_Z66C2339E(delete$_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function changeLabels(projectId, systemsId, changeLabels_1) {
    const url = `projects/${projectId}/systems/${systemsId}/change-labels`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = ChangeLabels_Encoder_19803C73(changeLabels_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function changeAttributes(projectId, systemsId, changeAttributes_1) {
    const url = `projects/${projectId}/systems/${systemsId}/change-attributes`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = ChangeAttributes_Encoder_69034AA9(changeAttributes_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

