import { toString, Record } from "../fable_modules/fable-library.3.7.3/Types.js";
import { record_type, int32_type, string_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { isEmpty, sortBy, sortByDescending, tryPick, toArray, choose, singleton, append, filter, cons, map, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { Helpers_combineClasses, Helpers_extractClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { map as map_2, collect as collect_1, empty, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { compare, comparePrimitives, equals, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { isNullOrWhiteSpace, interpolate, toText, join } from "../fable_modules/fable-library.3.7.3/String.js";
import { useFeliz_React__React_useState_Static_1505, useReact_useMemo_CF4EA67 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { Feliz_Router_Router__Router_navigateQueryPathExclude_Static_381A6A4, DomainEntity__get_Detail, DomainEntity__get_Name, InfoPanel, ActivityDateRange, Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091, SearchParams, EPM_Domain_Events_Action_Category__Category_get_EntityType } from "../ClientTypes.fs.js";
import { some, defaultArg, map as map_1 } from "../fable_modules/fable-library.3.7.3/Option.js";
import { objectHas } from "../fable_modules/Feliz.Recharts.3.10.0/Interop.fs.js";
import { collect } from "../fable_modules/fable-library.3.7.3/Array.js";
import { addYears, utcNow } from "../fable_modules/fable-library.3.7.3/Date.js";
import { fromDate } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.Recharts.3.10.0/../Feliz.1.58.1/Interop.fs.js";
import { ResponsiveContainer, RadialBarChart, RadialBar, Tooltip, Legend, PolarAngleAxis } from "recharts";
import { EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString, System_DateTimeOffset__DateTimeOffset_ToLocaleDateString, EPM_Domain_Events_Maturity__Maturity_ToProperString, System_Int32__Int32_ToLocaleString } from "../Prelude.fs.js";
import { Maturity, DomainEntities } from "../DomainEvents.fs.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { op_PlusPlus } from "../fable_modules/Feliz.Bulma.2.18.0/Operators.fs.js";
import { useFeliz_React__React_useMaturity_Static, useFeliz_React__React_useTypedParams_Static } from "../hooks/UseRouter.fs.js";
import { projectBreakdown, projectBreakdownByArea, projectBreakdownBySystem, projectBreakdownBySubsystem, projectBreakdownByProductionUnit, projectBreakdownByModule, projectBreakdownByLocation, projectBreakdownByFacility, projectBreakdownByEquipmentType, projectBreakdownByDiscipline } from "../Api/Dashboard.fs.js";
import { SearchParams_activitySearchParams } from "../Api/SearchParams.fs.js";
import { FetchFail__get_Message, useFeliz_React__React_useFetch_Static_Z4BDF3CFF, Fetched$1_toOption_50D43789 } from "../hooks/UseFetch.fs.js";
import { EPM_Domain_Events_Maturity__Maturity_ToUrlString } from "../Api/../Prelude.fs.js";
import { single } from "../Api/Entities.fs.js";
import { FSharpMap__Change } from "../fable_modules/fable-library.3.7.3/Map.js";
import { FSharpSet__get_IsEmpty, empty as empty_1, remove as remove_1 } from "../fable_modules/fable-library.3.7.3/Set.js";
import { useFeliz_React__React_useDebouncer_Static } from "../hooks/UseDebouncer.fs.js";
import { CategorySelector_CategorySelector } from "../components/Components.fs.js";
import "./Progress.scss";


function ProgressBreakdown__ToProperString(this$) {
    switch (this$) {
        case "maturity": {
            return "Maturity";
        }
        case "productionUnit": {
            return "Production Unit";
        }
        case "system": {
            return "System";
        }
        case "facility": {
            return "Facility";
        }
        case "module": {
            return "Module";
        }
        case "area": {
            return "Area";
        }
        case "location": {
            return "Location";
        }
        case "equipmentType": {
            return "Equipment Type";
        }
        case "discipline": {
            return "Discipline";
        }
        default: {
            return "Subsystem";
        }
    }
}

class Point extends Record {
    constructor(name, max, value, fill) {
        super();
        this.name = name;
        this.max = (max | 0);
        this.value = (value | 0);
        this.fill = fill;
    }
}

function Point$reflection() {
    return record_type("EPM.App.ProgressSub.Point", [], Point, () => [["name", string_type], ["max", int32_type], ["value", int32_type], ["fill", string_type]]);
}

const allBreakdowns = ofArray(["subsystem", "maturity", "productionUnit", "system", "facility", "module", "area", "location", "equipmentType", "discipline"]);

function BreakdownSelector(breakdownSelectorInputProps) {
    let elems, elems_1;
    const onChange = breakdownSelectorInputProps.onChange;
    const patternInput = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["onChange", (ev) => {
        onChange(ev.target.value);
    }], ["defaultValue", breakdownSelectorInputProps.value], (elems = toList(delay(() => map((s) => createElement("option", {
        children: ProgressBreakdown__ToProperString(s),
        value: s,
    }), allBreakdowns))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]));
    return createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput[0]))], (elems_1 = [createElement("select", createObj(patternInput[1]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function ProgressTile(progressTileInputProps) {
    let value_4, properties_6, elements, elems_2, props_2;
    const categoryOpt = progressTileInputProps.categoryOpt;
    const maturityOpt = progressTileInputProps.maturityOpt;
    const progress = progressTileInputProps.progress;
    const isActive = progressTileInputProps.isActive;
    const data = useReact_useMemo_CF4EA67(() => filter((v) => (v.value !== 0), ofArray([new Point("Unplanned", progress.Total, progress.Unplanned, "#fdfd96"), new Point("Planned", progress.Total, progress.Planned, "#83a6ed"), new Point("Actual", progress.Total, progress.Completed, (progress.Completed > progress.Planned) ? "#82ca9d" : "#DD8680")])), [progress]);
    const actualPercentComplete = (progress.Completed / progress.Total) * 100;
    const exceptedPercentComplete = (progress.Planned / progress.Total) * 100;
    const isBehind = progress.Completed < progress.Planned;
    const urlParams = append(singleton(new SearchParams(0, (categoryOpt != null) ? EPM_Domain_Events_Action_Category__Category_get_EntityType(categoryOpt) : "scopeOfWork")), choose((x) => x, singleton(map_1((arg0) => (new SearchParams(9, arg0)), maturityOpt))));
    let chart_1;
    const properties_7 = ofArray([(value_4 = (100 + "%"), (equals(value_4, 100 + "%") ? true : equals(value_4, 100 + "%")) ? ["width", 99 + "%"] : ["width", value_4]), ["height", 100 + "%"], ["children", (properties_6 = ofArray([["barSize", 8], ["innerRadius", 60 + "%"], ["outerRadius", 90 + "%"], ["cx", 50 + "%"], ["cy", 55 + "%"], ["startAngle", 90], ["endAngle", -270], ["background", true], ["clockWise", true], ["margin", {
        top: defaultArg(5, 0),
        right: defaultArg(2, 0),
        left: defaultArg(2, 0),
        bottom: defaultArg(2, 0),
    }], ["data", toArray(data)], ["onClick", (eventArgs) => {
        if ((eventArgs == null) ? true : objectHas(singleton("isTooltipActive"), eventArgs)) {
        }
        else {
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(append(ofArray(collect((chartPoint) => {
                const matchValue = chartPoint.payload.name;
                switch (matchValue) {
                    case "Actual": {
                        return [new SearchParams(10, "completed")];
                    }
                    case "Planned": {
                        let start;
                        let copyOfStruct = utcNow();
                        start = addYears(copyOfStruct, -7);
                        const endDate = utcNow();
                        return [new SearchParams(12, new ActivityDateRange(3, fromDate(start), fromDate(endDate))), new SearchParams(11, false)];
                    }
                    case "Unplanned": {
                        return [new SearchParams(11, true)];
                    }
                    default: {
                        return [];
                    }
                }
            }, eventArgs.activePayload)), urlParams), true, true);
        }
    }], ["cursor", "pointer"], (elements = ofArray([Interop_reactApi_1.createElement(PolarAngleAxis, {
        type: "number",
        dataKey: (point) => point.max,
        tick: false,
    }), createElement("text", {
        children: toText(interpolate("%.2f%P()%%", [actualPercentComplete])),
        x: 50 + "%",
        y: 63 + "%",
        className: "progress-percent",
        textAnchor: "middle",
        fill: isBehind ? "#DD8680" : "#82ca9d",
    }), createElement("text", {
        children: toText(interpolate("%.2f%P()%%", [exceptedPercentComplete])),
        x: 50 + "%",
        y: 55 + "%",
        className: "progress-percent",
        textAnchor: "middle",
        fill: "#83a6ed",
    }), Interop_reactApi_1.createElement(Legend, {
        verticalAlign: "top",
        layout: "horizontal",
        iconType: "rect",
        align: "center",
    }), Interop_reactApi_1.createElement(Tooltip, {
        formatter: (value_65) => [`${System_Int32__Int32_ToLocaleString(value_65)} | ${System_Int32__Int32_ToLocaleString(progress.Total)}`],
    }), Interop_reactApi_1.createElement(RadialBar, {
        dataKey: (point_1) => point_1.value,
    })]), ["children", ["children", Interop_reactApi.Children.toArray(Array.from(elements))]])]), Interop_reactApi_1.createElement(RadialBarChart, createObj(properties_6)))]]);
    chart_1 = Interop_reactApi_1.createElement(ResponsiveContainer, createObj(properties_7));
    const props_5 = ofArray([["className", join(" ", toList(delay(() => append_1(singleton_1("m-2"), delay(() => (isActive ? singleton_1("active") : singleton_1("")))))))], (elems_2 = [(props_2 = toList(delay(() => append_1((maturityOpt != null) ? singleton_1(["children", EPM_Domain_Events_Maturity__Maturity_ToProperString(maturityOpt)]) : singleton_1(["children", "Overall"]), delay(() => append_1(singleton_1(["onClick", (e_1) => {
        e_1.preventDefault();
        Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(urlParams, true, true);
    }]), delay(() => singleton_1(["className", "is-clickable"]))))))), createElement("div", createObj(Helpers_combineClasses("message-header", props_2)))), createElement("div", {
        className: "message-body",
        children: Interop_reactApi.Children.toArray([chart_1]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]);
    return createElement("article", createObj(Helpers_combineClasses("message", props_5)));
}

function ToolTip(toolTipInputProps) {
    const x = toolTipInputProps.x;
    console.log(some(x));
    return "test";
}

function ProgressEntityTile(progressEntityTileInputProps) {
    let value_4, properties_6, elements, elems_5, props_5, elems_2, props_3, props_11, elems_4;
    const categoryOpt = progressEntityTileInputProps.categoryOpt;
    const progress = progressEntityTileInputProps.progress;
    const progressBreakdown = progressEntityTileInputProps.progressBreakdown;
    const isActive = progressEntityTileInputProps.isActive;
    const key = progressEntityTileInputProps.$key;
    const chartData = useReact_useMemo_CF4EA67(() => filter((v) => (v.value !== 0), ofArray([new Point("Unplanned", progress.Total, progress.Unplanned, "#fdfd96"), new Point("Planned", progress.Total, progress.Planned, "#83a6ed"), new Point("Actual", progress.Total, progress.Completed, (progress.Completed > progress.Planned) ? "#82ca9d" : "#DD8680")])), [progress]);
    const actualPercentComplete = (progress.Completed / progress.Total) * 100;
    const exceptedPercentComplete = (progress.Planned / progress.Total) * 100;
    const isBehind = progress.Completed < progress.Planned;
    const urlParams = append(singleton(new SearchParams(0, (categoryOpt != null) ? EPM_Domain_Events_Action_Category__Category_get_EntityType(categoryOpt) : "scopeOfWork")), choose((x) => x, toList(delay(() => ((progressBreakdown === "maturity") ? singleton_1(void 0) : ((progressBreakdown === "productionUnit") ? singleton_1(new SearchParams(2, progress.EntityId, new DomainEntities(13))) : ((progressBreakdown === "system") ? singleton_1(new SearchParams(2, progress.EntityId, new DomainEntities(1))) : ((progressBreakdown === "facility") ? singleton_1(new SearchParams(2, progress.EntityId, new DomainEntities(3))) : ((progressBreakdown === "module") ? singleton_1(new SearchParams(2, progress.EntityId, new DomainEntities(6))) : ((progressBreakdown === "area") ? singleton_1(new SearchParams(2, progress.EntityId, new DomainEntities(7))) : ((progressBreakdown === "location") ? singleton_1(new SearchParams(2, progress.EntityId, new DomainEntities(8))) : ((progressBreakdown === "equipmentType") ? singleton_1(new SearchParams(2, progress.EntityId, new DomainEntities(10))) : ((progressBreakdown === "discipline") ? singleton_1(void 0) : singleton_1(new SearchParams(2, progress.EntityId, new DomainEntities(2))))))))))))))));
    const entityUrlParams = (progressBreakdown === "maturity") ? (void 0) : ((progressBreakdown === "productionUnit") ? (new SearchParams(4, new InfoPanel(1, progress.EntityId, new DomainEntities(13)))) : ((progressBreakdown === "system") ? (new SearchParams(4, new InfoPanel(1, progress.EntityId, new DomainEntities(1)))) : ((progressBreakdown === "facility") ? (new SearchParams(4, new InfoPanel(1, progress.EntityId, new DomainEntities(3)))) : ((progressBreakdown === "module") ? (new SearchParams(4, new InfoPanel(1, progress.EntityId, new DomainEntities(6)))) : ((progressBreakdown === "area") ? (new SearchParams(4, new InfoPanel(1, progress.EntityId, new DomainEntities(7)))) : ((progressBreakdown === "location") ? (new SearchParams(4, new InfoPanel(1, progress.EntityId, new DomainEntities(8)))) : ((progressBreakdown === "equipmentType") ? (void 0) : ((progressBreakdown === "discipline") ? (void 0) : (new SearchParams(4, new InfoPanel(1, progress.EntityId, new DomainEntities(2))))))))))));
    let chart_1;
    const properties_7 = ofArray([(value_4 = (100 + "%"), (equals(value_4, 100 + "%") ? true : equals(value_4, 100 + "%")) ? ["width", 99 + "%"] : ["width", value_4]), ["height", 100 + "%"], ["children", (properties_6 = ofArray([["barSize", 8], ["innerRadius", 60 + "%"], ["outerRadius", 90 + "%"], ["cx", 50 + "%"], ["cy", 55 + "%"], ["startAngle", 90], ["endAngle", -270], ["background", true], ["clockWise", true], ["margin", {
        top: defaultArg(5, 0),
        right: defaultArg(2, 0),
        left: defaultArg(2, 0),
        bottom: defaultArg(2, 0),
    }], ["data", toArray(chartData)], ["onClick", (eventArgs) => {
        if ((eventArgs == null) ? true : objectHas(singleton("isTooltipActive"), eventArgs)) {
        }
        else {
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(append(ofArray(collect((chartPoint) => {
                const matchValue = chartPoint.payload.name;
                switch (matchValue) {
                    case "Actual": {
                        return [new SearchParams(10, "completed")];
                    }
                    case "Planned": {
                        let start;
                        let copyOfStruct = utcNow();
                        start = addYears(copyOfStruct, -7);
                        const endDate = utcNow();
                        return [new SearchParams(12, new ActivityDateRange(3, fromDate(start), fromDate(endDate))), new SearchParams(11, false)];
                    }
                    case "Unplanned": {
                        return [new SearchParams(11, true)];
                    }
                    default: {
                        return [];
                    }
                }
            }, eventArgs.activePayload)), urlParams), true, true);
        }
    }], ["cursor", "pointer"], (elements = ofArray([Interop_reactApi_1.createElement(PolarAngleAxis, {
        type: "number",
        dataKey: (point) => point.max,
        tick: false,
    }), createElement("text", {
        children: toText(interpolate("%.2f%P()%%", [actualPercentComplete])),
        x: 50 + "%",
        y: 63 + "%",
        className: "progress-percent",
        textAnchor: "middle",
        fill: isBehind ? "#DD8680" : "#82ca9d",
    }), createElement("text", {
        children: toText(interpolate("%.2f%P()%%", [exceptedPercentComplete])),
        x: 50 + "%",
        y: 55 + "%",
        className: "progress-percent",
        textAnchor: "middle",
        fill: "#83a6ed",
    }), Interop_reactApi_1.createElement(Legend, {
        verticalAlign: "top",
        layout: "horizontal",
        iconType: "rect",
        align: "center",
    }), Interop_reactApi_1.createElement(Tooltip, {
        formatter: (value_65) => [`${System_Int32__Int32_ToLocaleString(value_65)} | ${System_Int32__Int32_ToLocaleString(progress.Total)}`],
    }), Interop_reactApi_1.createElement(RadialBar, {
        dataKey: (point_1) => point_1.value,
    })]), ["children", ["children", Interop_reactApi.Children.toArray(Array.from(elements))]])]), Interop_reactApi_1.createElement(RadialBarChart, createObj(properties_6)))]]);
    chart_1 = Interop_reactApi_1.createElement(ResponsiveContainer, createObj(properties_7));
    const props_13 = ofArray([["className", join(" ", toList(delay(() => append_1(singleton_1("m-2"), delay(() => (isActive ? singleton_1("active") : singleton_1("")))))))], (elems_5 = [(props_5 = singleton((elems_2 = [createElement("div", {
        children: progress.EntityName,
        className: "is-clickable",
        onClick: (_arg1) => {
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(urlParams, true, true);
        },
    }), (props_3 = toList(delay(() => {
        let param;
        return append_1((entityUrlParams != null) ? ((param = entityUrlParams, append_1(singleton_1(["className", "is-clickable"]), delay(() => singleton_1(["onClick", (_arg2) => {
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(singleton(param), false);
        }]))))) : ((empty())), delay(() => {
            let elems_1;
            return singleton_1((elems_1 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-info")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]));
        }));
    })), createElement("span", createObj(Helpers_combineClasses("icon", props_3))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])), createElement("div", createObj(Helpers_combineClasses("message-header", props_5)))), (props_11 = ofArray([op_PlusPlus(["className", "is-flex"], ["className", "is-flex-direction-column"]), (elems_4 = toList(delay(() => {
        let matchValue_1, detail;
        return append_1((matchValue_1 = progress.EntityDetail, (matchValue_1 != null) ? ((detail = matchValue_1, singleton_1(createElement("p", {
            className: "is-size-7",
            children: detail,
        })))) : singleton_1(null)), delay(() => append_1(singleton_1(chart_1), delay(() => {
            let elems_3;
            const matchValue_2 = progress.PlannedDate;
            if (matchValue_2 != null) {
                const plannedDate = matchValue_2;
                return singleton_1(createElement("p", createObj(ofArray([["className", "is-size-7"], (elems_3 = ["Planned Date: ", createElement("time", {
                    dateTime: toString(plannedDate),
                    children: System_DateTimeOffset__DateTimeOffset_ToLocaleDateString(plannedDate),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
            }
            else {
                return singleton_1(createElement("p", {
                    children: ["No planned date"],
                }));
            }
        }))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]), createElement("div", createObj(Helpers_combineClasses("message-body", props_11))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]);
    return createElement("article", createObj(Helpers_combineClasses("message", props_13)));
}

function ProgressEntities(progressEntitiesInputProps) {
    const ordering = progressEntitiesInputProps.ordering;
    const search = progressEntitiesInputProps.search;
    const categoryOpt = progressEntitiesInputProps.categoryOpt;
    const projectId = progressEntitiesInputProps.projectId;
    const breakdown = progressEntitiesInputProps.breakdown;
    const typedParams = useFeliz_React__React_useTypedParams_Static();
    const entityIdOpt = (requiredDomainEntity) => tryPick((tp) => {
        let pattern_matching_result, domainEntity_1, entityId_1;
        if (tp.tag === 2) {
            if (equals(tp.fields[1], requiredDomainEntity)) {
                pattern_matching_result = 0;
                domainEntity_1 = tp.fields[1];
                entityId_1 = tp.fields[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return entityId_1;
            }
            case 1: {
                return void 0;
            }
        }
    }, typedParams);
    const patternInput = (breakdown === "discipline") ? [(projectId_2) => ((activityParams_1) => projectBreakdownByDiscipline(projectId_2, activityParams_1)), new DomainEntities(4), entityIdOpt(new DomainEntities(4))] : ((breakdown === "equipmentType") ? [(projectId_3) => ((activityParams_2) => projectBreakdownByEquipmentType(projectId_3, activityParams_2)), new DomainEntities(10), entityIdOpt(new DomainEntities(10))] : ((breakdown === "facility") ? [(projectId_4) => ((activityParams_3) => projectBreakdownByFacility(projectId_4, activityParams_3)), new DomainEntities(3), entityIdOpt(new DomainEntities(3))] : ((breakdown === "location") ? [(projectId_5) => ((activityParams_4) => projectBreakdownByLocation(projectId_5, activityParams_4)), new DomainEntities(8), entityIdOpt(new DomainEntities(8))] : ((breakdown === "module") ? [(projectId_6) => ((activityParams_5) => projectBreakdownByModule(projectId_6, activityParams_5)), new DomainEntities(6), entityIdOpt(new DomainEntities(6))] : ((breakdown === "productionUnit") ? [(projectId_7) => ((activityParams_6) => projectBreakdownByProductionUnit(projectId_7, activityParams_6)), new DomainEntities(13), entityIdOpt(new DomainEntities(13))] : ((breakdown === "subsystem") ? [(projectId_8) => ((activityParams_7) => projectBreakdownBySubsystem(projectId_8, activityParams_7)), new DomainEntities(2), entityIdOpt(new DomainEntities(2))] : ((breakdown === "system") ? [(projectId_9) => ((activityParams_8) => projectBreakdownBySystem(projectId_9, activityParams_8)), new DomainEntities(1), entityIdOpt(new DomainEntities(1))] : ((breakdown === "maturity") ? [(projectId_10) => ((activityParams_9) => projectBreakdownBySystem(projectId_10, activityParams_9)), new DomainEntities(1), entityIdOpt(new DomainEntities(1))] : [(projectId_1) => ((activityParams) => projectBreakdownByArea(projectId_1, activityParams)), new DomainEntities(7), entityIdOpt(new DomainEntities(7))]))))))));
    const activityParams_10 = useReact_useMemo_CF4EA67(() => SearchParams_activitySearchParams(categoryOpt, filter((param) => {
        switch (param.tag) {
            case 11: {
                return false;
            }
            case 10: {
                return false;
            }
            case 2: {
                return !equals(patternInput[1], param.fields[1]);
            }
            default: {
                return true;
            }
        }
    }, typedParams)), [typedParams]);
    const progressFetch = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF(patternInput[0](projectId), activityParams_10, [projectId, breakdown, activityParams_10, categoryOpt]));
    if (progressFetch != null) {
        const tiles = filter((progress_2) => (!((progress_2.Total === 0) ? true : ((progress_2.Planned === 0) && (progress_2.Completed === 0)))), filter((progress_1) => {
            if (isNullOrWhiteSpace(search)) {
                return true;
            }
            else if (progress_1.EntityName.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0) {
                return true;
            }
            else {
                return defaultArg(map_1((details) => (details.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0), progress_1.EntityDetail), false);
            }
        }, progressFetch));
        const arg00_1 = map((p) => {
            const $keyZ70ECD219 = p.EntityId;
            return createElement(ProgressEntityTile, {
                key: $keyZ70ECD219,
                $key: $keyZ70ECD219,
                isActive: equals(p.EntityId, patternInput[2]),
                progressBreakdown: breakdown,
                progress: p,
                categoryOpt: categoryOpt,
            });
        }, (ordering === "nameDesc") ? sortByDescending((progress_4) => progress_4.EntityName, tiles, {
            Compare: comparePrimitives,
        }) : ((ordering === "dateAsc") ? sortBy((progress_5) => progress_5.PlannedDate, tiles, {
            Compare: compare,
        }) : ((ordering === "dateDesc") ? sortByDescending((progress_6) => progress_6.PlannedDate, tiles, {
            Compare: compare,
        }) : sortBy((progress_3) => progress_3.EntityName, tiles, {
            Compare: comparePrimitives,
        }))));
        return react.createElement(react.Fragment, {}, ...arg00_1);
    }
    else {
        return null;
    }
}

function useuseMaturityData(projectId, maturityOpt, categoryOpt) {
    const typedParams = useFeliz_React__React_useTypedParams_Static();
    const patternInput = useFeliz_React__React_useState_Static_1505(typedParams);
    const paramsWithoutSearchType = patternInput[0];
    useReact_useMemo_CF4EA67(() => {
        const withOut = filter((tp) => {
            if (tp.tag === 0) {
                return false;
            }
            else {
                return true;
            }
        }, typedParams);
        if (!equals(withOut, paramsWithoutSearchType)) {
            patternInput[1](withOut);
        }
    }, [typedParams]);
    const filters = useReact_useMemo_CF4EA67(() => SearchParams_activitySearchParams(categoryOpt, filter((param) => {
        switch (param.tag) {
            case 11: {
                return false;
            }
            case 10: {
                return false;
            }
            case 9: {
                return false;
            }
            default: {
                return true;
            }
        }
    }, typedParams)), [paramsWithoutSearchType]);
    return Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_1) => projectBreakdown(projectId, activityParams_1), append(filters, choose((x) => x, singleton(map_1((arg00) => ["actionMaturity", EPM_Domain_Events_Maturity__Maturity_ToUrlString(arg00)], maturityOpt)))), [projectId, categoryOpt, filters]));
}

function ProgressMaturity(progressMaturityInputProps) {
    const categoryOpt = progressMaturityInputProps.categoryOpt;
    const projectId = progressMaturityInputProps.projectId;
    const maturityOpt = useFeliz_React__React_useMaturity_Static();
    const commission = useuseMaturityData(projectId, new Maturity(5), categoryOpt);
    const construction = useuseMaturityData(projectId, new Maturity(3), categoryOpt);
    const functionTest = useuseMaturityData(projectId, new Maturity(4), categoryOpt);
    const handover = useuseMaturityData(projectId, new Maturity(6), categoryOpt);
    const mechanicalCompletion = useuseMaturityData(projectId, new Maturity(2), categoryOpt);
    const preCommissioning = useuseMaturityData(projectId, new Maturity(1), categoryOpt);
    const arg00 = map((tupledArg_1) => {
        const m = tupledArg_1[1];
        return createElement(ProgressTile, {
            isActive: equals(m, maturityOpt),
            progress: tupledArg_1[0],
            maturityOpt: m,
            categoryOpt: categoryOpt,
        });
    }, filter((tupledArg) => {
        const progress = tupledArg[0];
        return !((progress.Total === 0) ? true : ((progress.Planned === 0) && (progress.Completed === 0)));
    }, choose((x) => x, ofArray([map_1((d) => [d, new Maturity(3)], construction), map_1((d_1) => [d_1, new Maturity(2)], mechanicalCompletion), map_1((d_2) => [d_2, new Maturity(1)], preCommissioning), map_1((d_3) => [d_3, new Maturity(5)], commission), map_1((d_4) => [d_4, new Maturity(4)], functionTest), map_1((d_5) => [d_5, new Maturity(6)], handover)]))));
    return react.createElement(react.Fragment, {}, ...arg00);
}

function ProgressChart(progressChartInputProps) {
    const ordering = progressChartInputProps.ordering;
    const search = progressChartInputProps.search;
    const categoryOpt = progressChartInputProps.categoryOpt;
    const projectId = progressChartInputProps.projectId;
    const breakdown = progressChartInputProps.breakdown;
    if (breakdown === "maturity") {
        return createElement(ProgressMaturity, {
            projectId: projectId,
            categoryOpt: categoryOpt,
        });
    }
    else {
        return createElement(ProgressEntities, {
            breakdown: breakdown,
            projectId: projectId,
            categoryOpt: categoryOpt,
            search: search,
            ordering: ordering,
        });
    }
}

function WhereAmIEntityTag(whereAmIEntityTagInputProps) {
    let props_6, elems, props, props_2;
    const remove = whereAmIEntityTagInputProps.remove;
    const entityId = whereAmIEntityTagInputProps.entityId;
    const domainEntity = whereAmIEntityTagInputProps.domainEntity;
    const projectId = whereAmIEntityTagInputProps.projectId;
    const fetched = useFeliz_React__React_useFetch_Static_Z4BDF3CFF((entityId_1) => single(domainEntity, projectId, entityId_1), entityId, [entityId]);
    const elms = singleton((props_6 = ofArray([["className", "has-addons"], (elems = [(props = ofArray([["className", "is-dark"], ["children", EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(domainEntity)]]), createElement("span", createObj(Helpers_combineClasses("tag", props)))), (props_2 = toList(delay(() => {
        switch (fetched.tag) {
            case 0: {
                return singleton_1(["className", "is-primary"]);
            }
            case 1: {
                return append_1(singleton_1(["className", "is-primary"]), delay(() => append_1(singleton_1(["children", "..."]), delay(() => singleton_1(["title", "loading"])))));
            }
            case 3: {
                return append_1(singleton_1(["className", "is-danger"]), delay(() => append_1(singleton_1(["children", "⁉️"]), delay(() => singleton_1(["title", FetchFail__get_Message(fetched.fields[0])])))));
            }
            default: {
                const entityOpt = fetched.fields[0];
                return append_1(singleton_1(["className", "is-primary"]), delay(() => {
                    if (entityOpt == null) {
                        return append_1(singleton_1(["children", "?"]), delay(() => singleton_1(["title", "Unknown"])));
                    }
                    else {
                        const entity = entityOpt;
                        return append_1(singleton_1(["children", DomainEntity__get_Name(entity)]), delay(() => singleton_1(["title", defaultArg(DomainEntity__get_Detail(entity), "")])));
                    }
                }));
            }
        }
    })), createElement("span", createObj(Helpers_combineClasses("tag", props_2)))), createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-delete"], ["className", "is-clickable"], ["onClick", (_arg1) => {
        remove();
    }]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("div", createObj(Helpers_combineClasses("tags", props_6)))));
    return createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

function WhereAmITag(whereAmITagInputProps) {
    let props_6, elems;
    const remove = whereAmITagInputProps.remove;
    const value = whereAmITagInputProps.value;
    const header = whereAmITagInputProps.header;
    const elms = singleton((props_6 = ofArray([["className", "has-addons"], (elems = [createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-dark"], ["children", header]])))), createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-primary"], ["children", value]])))), createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-delete"], ["className", "is-clickable"], ["onClick", (_arg1) => {
        remove();
    }]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("div", createObj(Helpers_combineClasses("tags", props_6)))));
    return createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

function WhereAmILabelsTag(whereAmILabelsTagInputProps) {
    const labels = whereAmILabelsTagInputProps.labels;
    const domainEntity = whereAmILabelsTagInputProps.domainEntity;
    const arg00 = collect_1((label) => map_2((value_2) => {
        let props_6, elems, props;
        const elms = singleton((props_6 = ofArray([["className", "has-addons"], (elems = [(props = ofArray([["className", "is-dark"], ["children", label[0]], ["title", `${EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(domainEntity)} label`]]), createElement("span", createObj(Helpers_combineClasses("tag", props)))), createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-primary"], ["children", value_2]])))), createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-delete"], ["className", "is-clickable"], ["onClick", (_arg1) => {
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(singleton(new SearchParams(5, domainEntity, FSharpMap__Change(labels, label[0], (v) => {
                const newSet = remove_1(value_2, defaultArg(v, empty_1({
                    Compare: comparePrimitives,
                })));
                return FSharpSet__get_IsEmpty(newSet) ? (void 0) : newSet;
            }))), false);
        }]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("div", createObj(Helpers_combineClasses("tags", props_6)))));
        return createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        });
    }, label[1]), labels);
    return react.createElement(react.Fragment, {}, ...arg00);
}

function WhereAmI(whereAmIInputProps) {
    let elems_1, props_1;
    const projectId = whereAmIInputProps.projectId;
    const tags = choose((tp) => {
        const remove = () => {
            Feliz_Router_Router__Router_navigateQueryPathExclude_Static_381A6A4(singleton(tp));
        };
        switch (tp.tag) {
            case 2: {
                return createElement(WhereAmIEntityTag, {
                    projectId: projectId,
                    domainEntity: tp.fields[1],
                    entityId: tp.fields[0],
                    remove: remove,
                });
            }
            case 7: {
                return createElement(WhereAmITag, {
                    header: "Condition",
                    value: toString(tp.fields[0]),
                    remove: remove,
                });
            }
            case 8: {
                return createElement(WhereAmITag, {
                    header: "Criticality",
                    value: toString(tp.fields[0]),
                    remove: remove,
                });
            }
            case 6: {
                return createElement(WhereAmITag, {
                    header: "Status",
                    value: toString(tp.fields[0]),
                    remove: remove,
                });
            }
            case 1: {
                return createElement(WhereAmITag, {
                    header: "Search",
                    value: tp.fields[0],
                    remove: remove,
                });
            }
            case 5: {
                return createElement(WhereAmILabelsTag, {
                    domainEntity: tp.fields[0],
                    labels: tp.fields[1],
                });
            }
            default: {
                return void 0;
            }
        }
    }, useFeliz_React__React_useTypedParams_Static());
    if (isEmpty(tags)) {
        return null;
    }
    else {
        const props_3 = ofArray([["className", "is-black"], (elems_1 = [createElement("h2", {
            className: "subtitle",
            children: "Progress View",
        }), (props_1 = ofArray([["className", "is-grouped"], ["className", "is-grouped-multiline"], ["children", Interop_reactApi.Children.toArray(Array.from(tags))]]), createElement("div", createObj(Helpers_combineClasses("field", props_1))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]);
        return createElement("div", createObj(Helpers_combineClasses("notification", props_3)));
    }
}

export function ProgressSub(progressSubInputProps) {
    let elems_21, props_37, elems_20, elems_23, elems_22;
    const projectId = progressSubInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const categoryOpt = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("subsystem");
    const breakdown = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("nameAsc");
    const setOrdering = patternInput_3[1];
    const ordering = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useDebouncer_Static("", patternInput_2[1], 400);
    const overAllOpt = useuseMaturityData(projectId, void 0, categoryOpt);
    let nameSortingButton;
    const props = toList(delay(() => append_1(singleton_1(["className", "is-large"]), delay(() => append_1(singleton_1(op_PlusPlus(["className", "is-clickable"], ["className", "mr-2"])), delay(() => ((ordering === "nameAsc") ? append_1(singleton_1(["className", "has-text-grey"]), delay(() => append_1(singleton_1(["onClick", (_arg1) => {
        setOrdering("nameDesc");
    }]), delay(() => append_1(singleton_1(["title", "Sort By Name Ascending"]), delay(() => {
        let elems;
        return singleton_1((elems = [Fa_i(ofArray([new Fa_IconOption(11, "fas fa-sort-alpha-up"), new Fa_IconOption(0, new Fa_ISize(3))]), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]));
    })))))) : ((ordering === "nameDesc") ? append_1(singleton_1(["className", "has-text-grey"]), delay(() => append_1(singleton_1(["onClick", (_arg2) => {
        setOrdering("nameAsc");
    }]), delay(() => append_1(singleton_1(["title", "Sort By Name Ascending"]), delay(() => {
        let elems_1;
        return singleton_1((elems_1 = [Fa_i(ofArray([new Fa_IconOption(11, "fas fa-sort-alpha-down-alt"), new Fa_IconOption(0, new Fa_ISize(3))]), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]));
    })))))) : append_1(singleton_1(["className", "has-text-grey-light"]), delay(() => append_1(singleton_1(["onClick", (_arg3) => {
        setOrdering("nameAsc");
    }]), delay(() => append_1(singleton_1(["title", "Sort By Name Ascending"]), delay(() => {
        let elems_2;
        return singleton_1((elems_2 = [Fa_i(ofArray([new Fa_IconOption(11, "fas fa-sort-alpha-up"), new Fa_IconOption(0, new Fa_ISize(3))]), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]));
    }))))))))))))));
    nameSortingButton = createElement("span", createObj(Helpers_combineClasses("icon", props)));
    let dateSortingButton;
    const props_2 = toList(delay(() => append_1(singleton_1(["className", "is-large"]), delay(() => append_1(singleton_1(op_PlusPlus(["className", "is-clickable"], ["className", "mr-2"])), delay(() => ((ordering === "dateAsc") ? append_1(singleton_1(["className", "has-text-grey"]), delay(() => append_1(singleton_1(["onClick", (_arg4) => {
        setOrdering("dateDesc");
    }]), delay(() => append_1(singleton_1(["title", "Sort By Date Ascending"]), delay(() => {
        let elems_3;
        return singleton_1((elems_3 = [Fa_i(ofArray([new Fa_IconOption(11, "fas fa-sort-amount-up"), new Fa_IconOption(0, new Fa_ISize(3))]), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]));
    })))))) : ((ordering === "dateDesc") ? append_1(singleton_1(["className", "has-text-grey"]), delay(() => append_1(singleton_1(["onClick", (_arg5) => {
        setOrdering("dateAsc");
    }]), delay(() => append_1(singleton_1(["title", "Sort By Date Ascending"]), delay(() => {
        let elems_4;
        return singleton_1((elems_4 = [Fa_i(ofArray([new Fa_IconOption(11, "fas fa-sort-amount-down-alt"), new Fa_IconOption(0, new Fa_ISize(3))]), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))]));
    })))))) : append_1(singleton_1(["className", "has-text-grey-light"]), delay(() => append_1(singleton_1(["onClick", (_arg6) => {
        setOrdering("dateAsc");
    }]), delay(() => append_1(singleton_1(["title", "Sort By Date Ascending"]), delay(() => {
        let elems_5;
        return singleton_1((elems_5 = [Fa_i(ofArray([new Fa_IconOption(11, "fas fa-sort-amount-up"), new Fa_IconOption(0, new Fa_ISize(3))]), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]));
    }))))))))))))));
    dateSortingButton = createElement("span", createObj(Helpers_combineClasses("icon", props_2)));
    let options;
    const props_39 = ofArray([op_PlusPlus(["className", "is-primary"], ["className", "m-2"]), (elems_21 = [createElement("div", createObj(Helpers_combineClasses("message-header", singleton(["children", "Options"])))), (props_37 = ofArray([["className", "progress-chart"], (elems_20 = toList(delay(() => {
        let props_11, elems_8, children_1, elms, props_7;
        return append_1(singleton_1((props_11 = ofArray([["className", "is-horizontal"], (elems_8 = [createElement("div", {
            className: "field-label",
            children: "Progress By",
        }), (children_1 = singleton((elms = singleton((props_7 = ofArray([["className", "is-expanded"], ["children", createElement(CategorySelector_CategorySelector, {
            value: categoryOpt,
            required: false,
            onChange: patternInput[1],
        })]]), createElement("div", createObj(Helpers_combineClasses("control", props_7))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(children_1)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]), createElement("div", createObj(Helpers_combineClasses("field", props_11))))), delay(() => {
            let props_18, elems_11, children_4, elms_1, props_14;
            return append_1(singleton_1((props_18 = ofArray([["className", "is-horizontal"], (elems_11 = [createElement("div", {
                className: "field-label",
                children: "Breakdown By",
            }), (children_4 = singleton((elms_1 = singleton((props_14 = ofArray([["className", "is-expanded"], ["children", createElement(BreakdownSelector, {
                value: breakdown,
                onChange: patternInput_1[1],
            })]]), createElement("div", createObj(Helpers_combineClasses("control", props_14))))), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]), createElement("div", createObj(Helpers_combineClasses("field", props_18))))), delay(() => {
                let props_27, elems_14, children_7, elms_2, props_23;
                return append_1((breakdown === "maturity") ? singleton_1(null) : singleton_1((props_27 = ofArray([["className", "is-horizontal"], (elems_14 = [createElement("div", {
                    className: "field-label",
                    children: "Search",
                }), (children_7 = singleton((elms_2 = singleton((props_23 = ofArray([["className", "is-expanded"], ["children", createElement("input", createObj(cons(["type", "search"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
                    patternInput_4[1](ev.target.value);
                }], ["value", patternInput_4[0]]])))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_23))))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(children_7)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]), createElement("div", createObj(Helpers_combineClasses("field", props_27))))), delay(() => {
                    let props_35, elems_19, children_11, props_32, elems_17;
                    return singleton_1((props_35 = ofArray([["className", "is-horizontal"], (elems_19 = [createElement("div", {
                        className: "field-label",
                        children: "Order By",
                    }), (children_11 = singleton((props_32 = ofArray([["className", "is-grouped"], (elems_17 = [createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray([nameSortingButton]),
                    }), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray([dateSortingButton]),
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]), createElement("div", createObj(Helpers_combineClasses("field", props_32))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(children_11)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]), createElement("div", createObj(Helpers_combineClasses("field", props_35)))));
                }));
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]), createElement("div", createObj(Helpers_combineClasses("message-body", props_37))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])]);
    options = createElement("article", createObj(Helpers_combineClasses("message", props_39)));
    return createElement("div", createObj(ofArray([["className", "progress-dashboard"], (elems_23 = [createElement(WhereAmI, {
        projectId: projectId,
    }), createElement("div", createObj(ofArray([op_PlusPlus(["className", join(" ", ["progress-charts", "is-flex-wrap-wrap", "is-justify-content-space-evenly", "is-align-content-flex-start", "p-1"])], ["className", "is-flex"]), (elems_22 = toList(delay(() => append_1(singleton_1(options), delay(() => {
        let overAll;
        return append_1((overAllOpt != null) ? ((overAll = overAllOpt, singleton_1(createElement(ProgressTile, {
            isActive: false,
            progress: overAll,
            maturityOpt: void 0,
            categoryOpt: categoryOpt,
        })))) : singleton_1(null), delay(() => singleton_1(createElement(ProgressChart, {
            breakdown: breakdown,
            projectId: projectId,
            categoryOpt: categoryOpt,
            search: patternInput_2[0],
            ordering: ordering,
        }))));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])));
}

