import { toString, Record, Union } from "../fable_modules/fable-library.3.7.3/Types.js";
import { int32_type, bool_type, unit_type, record_type, option_type, list_type, class_type, lambda_type, union_type, tuple_type, string_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { DomainEntities__ToPluralString, DomainEntities$reflection } from "../DomainEvents.fs.js";
import { DomainEntity$reflection } from "../ClientTypes.fs.js";
import { isEmpty, mapIndexed, singleton as singleton_1, tryHead, filter as filter_3, ofArray, tryItem, indexed, map as map_1, partition, empty as empty_1, cons, append as append_1, ofSeq, length } from "../fable_modules/fable-library.3.7.3/List.js";
import { FetchError$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { iterate, choose, map, empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { equals, max, min, uncurry, createObj, comparePrimitives, int32ToString } from "../fable_modules/fable-library.3.7.3/Util.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { Cmd_OfPromise_result, Cmd_none, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { FSharpMap__TryFind, empty as empty_3, add, filter as filter_2, ofList } from "../fable_modules/fable-library.3.7.3/Map.js";
import { ofSeq as ofSeq_1, FSharpSet__get_Count, union, filter as filter_1, ofList as ofList_1, FSharpSet__Contains, empty as empty_2 } from "../fable_modules/fable-library.3.7.3/Set.js";
import { System_Int32__Int32_tryParse_Static_Z721C83C5, System_Int32__Int32_ToLocaleString, Thoth_Fetch_FetchError__FetchError_get_Message } from "../Prelude.fs.js";
import { toArray, map as map_2, defaultArg, some } from "../fable_modules/fable-library.3.7.3/Option.js";
import { join, split as split_1, isNullOrWhiteSpace, printf, toConsole } from "../fable_modules/fable-library.3.7.3/String.js";
import { useFeliz_React__React_useDebouncer_Static } from "../hooks/UseDebouncer.fs.js";
import { createElement } from "react";
import { Helpers_extractClasses, Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useFetch_Static_Z4BDF3CFF, Fetched$1_toOption_50D43789 } from "../hooks/UseFetch.fs.js";
import { labels as labels_1 } from "../Api/Tags.fs.js";
import { labels as labels_2 } from "../Api/Actions.fs.js";
import { labels as labels_3 } from "../Api/Documents.fs.js";
import { labels as labels_4 } from "../Api/Activities.fs.js";
import { labels as labels_5 } from "../Api/Facilities.fs.js";
import { labels as labels_6 } from "../Api/Modules.fs.js";
import { labels as labels_7 } from "../Api/Areas.fs.js";
import { labels as labels_8 } from "../Api/Locations.fs.js";
import { labels as labels_9 } from "../Api/ProductionUnits.fs.js";
import { labels as labels_10 } from "../Api/Systems.fs.js";
import { labels as labels_11 } from "../Api/Subsystems.fs.js";
import { Interop_defaultProps, SelectGroup, SelectOption, SelectItem, selectSearch_options_113CE11B, selectSearch_filterOptions_Z7A2530AD } from "../fable_modules/Feliz.SelectSearch.1.6.0/SelectSearch.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.SelectSearch.1.6.0/../Feliz.1.58.1/Interop.fs.js";
import react_select_search from "react-select-search";
import { useFeliz_React__React_useState_Static_1505, useReact_useCallback_93353E } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { DomainEntitySelector } from "../components/EntitySelector.fs.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { useReact_useState_FCFD9EF } from "../hooks/../fable_modules/Feliz.1.58.1/React.fs.js";
import { fromString } from "../hooks/../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { toString as toString_1 } from "../hooks/../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { useFeliz_React__React_useElmish_Static_78C5B8C8 } from "../fable_modules/Feliz.UseElmish.1.6.0/UseElmish.fs.js";
import { rangeDouble } from "../fable_modules/fable-library.3.7.3/Range.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { op_PlusPlus } from "../fable_modules/Feliz.Bulma.2.18.0/Operators.fs.js";

export class FilterType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["String", "Entity", "Label"];
    }
}

export function FilterType$reflection() {
    return union_type("EPM.App.Admin.Table.FilterType", [], FilterType, () => [[["Item", lambda_type(string_type, union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(string_type, string_type), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(string_type, string_type)]], [["ErrorValue", string_type]]]))]], [["Item1", class_type("System.Guid")], ["Item2", DomainEntities$reflection()], ["Item3", list_type(tuple_type(string_type, string_type))], ["Item4", lambda_type(option_type(DomainEntity$reflection()), union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(string_type, string_type), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(string_type, string_type)]], [["ErrorValue", string_type]]]))]], [["Item1", class_type("System.Guid")], ["Item2", DomainEntities$reflection()], ["Item3", list_type(tuple_type(string_type, string_type))], ["Item4", lambda_type(tuple_type(string_type, string_type), union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(string_type, string_type), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(string_type, string_type)]], [["ErrorValue", string_type]]]))]]]);
}

export class Cell extends Record {
    constructor(Id, Render) {
        super();
        this.Id = Id;
        this.Render = Render;
    }
}

export function Cell$reflection() {
    return record_type("EPM.App.Admin.Table.Cell", [], Cell, () => [["Id", string_type], ["Render", class_type("Fable.React.ReactElement")]]);
}

export class Column extends Record {
    constructor(Id, Name, Filter) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Filter = Filter;
    }
}

export function Column$reflection() {
    return record_type("EPM.App.Admin.Table.Column", [], Column, () => [["Id", string_type], ["Name", string_type], ["Filter", option_type(FilterType$reflection())]]);
}

export class Row extends Record {
    constructor(Id, Cells) {
        super();
        this.Id = Id;
        this.Cells = Cells;
    }
}

export function Row$reflection() {
    return record_type("EPM.App.Admin.Table.Row", [], Row, () => [["Id", class_type("System.Guid")], ["Cells", list_type(Cell$reflection())]]);
}

export class RowSelection$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["None", "Single", "Multiple"];
    }
}

export function RowSelection$1$reflection(gen0) {
    return union_type("EPM.App.Admin.Table.RowSelection`1", [gen0], RowSelection$1, () => [[], [["Item", lambda_type(option_type(gen0), unit_type)]], [["Item", lambda_type(list_type(gen0), unit_type)]]]);
}

class State$1 extends Record {
    constructor(IsLoading, IsTotalLoading, Total, Filters, Offset, Fetch, Data, SelectedIndex, SortColumnId, SortAscending) {
        super();
        this.IsLoading = IsLoading;
        this.IsTotalLoading = IsTotalLoading;
        this.Total = (Total | 0);
        this.Filters = Filters;
        this.Offset = (Offset | 0);
        this.Fetch = (Fetch | 0);
        this.Data = Data;
        this.SelectedIndex = SelectedIndex;
        this.SortColumnId = SortColumnId;
        this.SortAscending = SortAscending;
    }
}

function State$1$reflection(gen0) {
    return record_type("EPM.App.Admin.Table.State`1", [gen0], State$1, () => [["IsLoading", bool_type], ["IsTotalLoading", bool_type], ["Total", int32_type], ["Filters", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["Offset", int32_type], ["Fetch", int32_type], ["Data", list_type(gen0)], ["SelectedIndex", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [int32_type])], ["SortColumnId", option_type(string_type)], ["SortAscending", bool_type]]);
}

function State$1__get_CanFirst(this$) {
    return this$.Offset > 0;
}

function State$1__get_CanPrevious(this$) {
    return this$.Offset > 0;
}

function State$1__get_CanNext(this$) {
    return (this$.Offset + length(this$.Data)) < this$.Total;
}

function State$1__get_CanLast(this$) {
    return (this$.Offset + length(this$.Data)) < this$.Total;
}

class Msg$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeFilter", "LoadNewPage", "LoadFirstPage", "LoadNextPage", "LoadPreviousPage", "LoadLastPage", "FirstPageLoaded", "PageLoaded", "LoadError", "SelectSingle", "SelectMultiple", "SelectAll", "NotifyExternalSelection", "Sort"];
    }
}

function Msg$1$reflection(gen0) {
    return union_type("EPM.App.Admin.Table.Msg`1", [gen0], Msg$1, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(string_type, string_type), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(string_type, string_type)]], [["ErrorValue", string_type]]])]], [], [], [], [], [], [["Item1", int32_type], ["Item2", list_type(gen0)]], [["Item", list_type(gen0)]], [["Item", FetchError$reflection()]], [["Item", int32_type]], [["Item", list_type(int32_type)]], [], [], [["Item", option_type(string_type)]]]);
}

function loadPage(loadData, fetch$, offset, sortColumn, sortAscending, filtersMap) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const extraFilters = toList(delay(() => append(singleton(["fetch", int32ToString(fetch$)]), delay(() => append(singleton(["offset", int32ToString(offset)]), delay(() => {
            if (sortColumn == null) {
                return empty();
            }
            else {
                return append(singleton(["sortBy", sortColumn]), delay(() => singleton(["sortAscending", toString(sortAscending)])));
            }
        }))))));
        const filters = ofSeq(map((kv) => [kv[0], kv[1]], filtersMap));
        return loadData(append_1(filters, extraFilters)).then((_arg1) => {
            const response = _arg1;
            return (response.tag === 1) ? (Promise.resolve(new Msg$1(8, response.fields[0]))) : (Promise.resolve(new Msg$1(7, response.fields[0])));
        });
    }));
}

function loadPageAndCount(loadData, loadCount, fetch$, filtersMap) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const extraFilters = ["fetch", int32ToString(fetch$)];
        const filters = ofSeq(map((kv) => [kv[0], kv[1]], filtersMap));
        return (Promise.all([loadCount(filters), loadData(cons(extraFilters, filters))])).then((_arg1) => {
            let matchValue, copyOfStruct, copyOfStruct_1;
            return Promise.resolve((matchValue = [_arg1[0], _arg1[1]], (copyOfStruct = matchValue[0], (copyOfStruct.tag === 1) ? (new Msg$1(8, copyOfStruct.fields[0])) : ((copyOfStruct_1 = matchValue[1], (copyOfStruct_1.tag === 1) ? (new Msg$1(8, copyOfStruct_1.fields[0])) : (new Msg$1(6, copyOfStruct.fields[0], copyOfStruct_1.fields[0])))))));
        });
    }));
}

function init(filters, fetch$) {
    const msgs = Cmd_OfFunc_result(new Msg$1(1));
    return [new State$1(false, false, 0, ofList(filters), 0, fetch$, empty_1(), empty_2({
        Compare: comparePrimitives,
    }), void 0, true), msgs];
}

function update(fetchData, fetchCount, rowSelection, onFiltersChange, msg, state) {
    let set2;
    switch (msg.tag) {
        case 10: {
            const patternInput = partition((e) => FSharpSet__Contains(state.SelectedIndex, e), msg.fields[0]);
            const removeIds = ofList_1(patternInput[0], {
                Compare: comparePrimitives,
            });
            return [new State$1(state.IsLoading, state.IsTotalLoading, state.Total, state.Filters, state.Offset, state.Fetch, state.Data, (set2 = filter_1((arg) => (!FSharpSet__Contains(removeIds, arg)), state.SelectedIndex), union(ofList_1(patternInput[1], {
                Compare: comparePrimitives,
            }), set2)), state.SortColumnId, state.SortAscending), Cmd_OfFunc_result(new Msg$1(12))];
        }
        case 11: {
            return [new State$1(state.IsLoading, state.IsTotalLoading, state.Total, state.Filters, state.Offset, state.Fetch, state.Data, ofList_1(map_1((tuple) => tuple[0], indexed(state.Data)), {
                Compare: comparePrimitives,
            }), state.SortColumnId, state.SortAscending), Cmd_OfFunc_result(new Msg$1(12))];
        }
        case 12: {
            rowSelection(ofSeq(choose((i) => tryItem(i, state.Data), state.SelectedIndex)));
            return [state, Cmd_none()];
        }
        case 0: {
            const result = msg.fields[0];
            const filters = (result.tag === 1) ? filter_2((k, _arg1) => (k !== result.fields[0]), state.Filters) : add(result.fields[0][0], result.fields[0][1], state.Filters);
            onFiltersChange(ofSeq(map((kv) => [kv[0], kv[1]], filters)));
            return [new State$1(state.IsLoading, state.IsTotalLoading, state.Total, filters, state.Offset, state.Fetch, state.Data, state.SelectedIndex, state.SortColumnId, state.SortAscending), Cmd_OfFunc_result(new Msg$1(1))];
        }
        case 1: {
            return [new State$1(true, true, 0, state.Filters, 0, state.Fetch, empty_1(), state.SelectedIndex, state.SortColumnId, state.SortAscending), Cmd_OfPromise_result(loadPageAndCount(fetchData, fetchCount, state.Fetch, state.Filters))];
        }
        case 3: {
            return [new State$1(true, state.IsTotalLoading, state.Total, state.Filters, state.Offset + state.Fetch, state.Fetch, state.Data, state.SelectedIndex, state.SortColumnId, state.SortAscending), Cmd_OfPromise_result(loadPage(fetchData, state.Fetch, state.Offset + state.Fetch, state.SortColumnId, state.SortAscending, state.Filters))];
        }
        case 4: {
            return [new State$1(true, state.IsTotalLoading, state.Total, state.Filters, state.Offset - state.Fetch, state.Fetch, state.Data, state.SelectedIndex, state.SortColumnId, state.SortAscending), Cmd_OfPromise_result(loadPage(fetchData, state.Fetch, state.Offset - state.Fetch, state.SortColumnId, state.SortAscending, state.Filters))];
        }
        case 2: {
            return [new State$1(true, state.IsTotalLoading, state.Total, state.Filters, 0, state.Fetch, state.Data, state.SelectedIndex, state.SortColumnId, state.SortAscending), Cmd_OfPromise_result(loadPage(fetchData, state.Fetch, 0, state.SortColumnId, state.SortAscending, state.Filters))];
        }
        case 5: {
            const offset = ((state.Total - state.Fetch) + (((state.Total % state.Fetch) > 0) ? state.Fetch : 0)) | 0;
            return [new State$1(true, state.IsTotalLoading, state.Total, state.Filters, offset, state.Fetch, state.Data, state.SelectedIndex, state.SortColumnId, state.SortAscending), Cmd_OfPromise_result(loadPage(fetchData, state.Fetch, offset, state.SortColumnId, state.SortAscending, state.Filters))];
        }
        case 6: {
            return [new State$1(false, false, msg.fields[0], state.Filters, state.Offset, state.Fetch, msg.fields[1], state.SelectedIndex, state.SortColumnId, state.SortAscending), Cmd_none()];
        }
        case 7: {
            return [new State$1(false, state.IsTotalLoading, state.Total, state.Filters, state.Offset, state.Fetch, msg.fields[0], state.SelectedIndex, state.SortColumnId, state.SortAscending), Cmd_none()];
        }
        case 8: {
            const error = msg.fields[0];
            console.error(some(Thoth_Fetch_FetchError__FetchError_get_Message(error)), error);
            return [new State$1(false, state.IsTotalLoading, state.Total, state.Filters, state.Offset, state.Fetch, state.Data, state.SelectedIndex, state.SortColumnId, state.SortAscending), Cmd_none()];
        }
        case 13: {
            const columnId = msg.fields[0];
            if (columnId != null) {
                const clickedColumn = columnId;
                toConsole(printf("%A column clicked"))(clickedColumn);
                const matchValue = state.SortColumnId;
                if (matchValue != null) {
                    const currentSortColumn = matchValue;
                    if ((currentSortColumn === clickedColumn) && (state.SortAscending === true)) {
                        toConsole(printf("Sorting descending by %A"))(clickedColumn);
                        return [new State$1(state.IsLoading, state.IsTotalLoading, state.Total, state.Filters, state.Offset, state.Fetch, state.Data, state.SelectedIndex, clickedColumn, false), Cmd_OfPromise_result(loadPage(fetchData, state.Fetch, state.Offset, clickedColumn, false, state.Filters))];
                    }
                    else if ((currentSortColumn === clickedColumn) && (state.SortAscending === false)) {
                        toConsole(printf("Clearing sort"));
                        return [new State$1(state.IsLoading, state.IsTotalLoading, state.Total, state.Filters, state.Offset, state.Fetch, state.Data, state.SelectedIndex, void 0, true), Cmd_OfPromise_result(loadPage(fetchData, state.Fetch, state.Offset, void 0, true, state.Filters))];
                    }
                    else {
                        toConsole(printf("Sorting ascending by %A"))(clickedColumn);
                        return [new State$1(state.IsLoading, state.IsTotalLoading, state.Total, state.Filters, state.Offset, state.Fetch, state.Data, state.SelectedIndex, clickedColumn, true), Cmd_OfPromise_result(loadPage(fetchData, state.Fetch, state.Offset, clickedColumn, true, state.Filters))];
                    }
                }
                else {
                    toConsole(printf("Sorting ascending by %A"))(clickedColumn);
                    return [new State$1(state.IsLoading, state.IsTotalLoading, state.Total, state.Filters, state.Offset, state.Fetch, state.Data, state.SelectedIndex, clickedColumn, true), Cmd_OfPromise_result(loadPage(fetchData, state.Fetch, state.Offset, clickedColumn, true, state.Filters))];
                }
            }
            else {
                toConsole(printf("Invalid case, should not be able to click on a None column"));
                return [state, Cmd_none()];
            }
        }
        default: {
            const index = msg.fields[0] | 0;
            return [new State$1(state.IsLoading, state.IsTotalLoading, state.Total, state.Filters, state.Offset, state.Fetch, state.Data, (FSharpSet__get_Count(state.SelectedIndex) > 1) ? ofSeq_1([index], {
                Compare: comparePrimitives,
            }) : (FSharpSet__Contains(state.SelectedIndex, index) ? empty_2({
                Compare: comparePrimitives,
            }) : ofSeq_1([index], {
                Compare: comparePrimitives,
            })), state.SortColumnId, state.SortAscending), Cmd_OfFunc_result(new Msg$1(12))];
        }
    }
}

function StringFilter(stringFilterInputProps) {
    const patternInput = useFeliz_React__React_useDebouncer_Static("", stringFilterInputProps.onChange, 300);
    return createElement("input", createObj(cons(["type", "search"], Helpers_combineClasses("input", ofArray([["className", "is-small"], ["onChange", (ev) => {
        patternInput[1](ev.target.value);
    }], ["value", patternInput[0]]])))));
}

function LabelFilter(labelFilterInputProps) {
    const onChange = labelFilterInputProps.onChange;
    const filters = labelFilterInputProps.filters;
    const entity = labelFilterInputProps.entity;
    const projectId = labelFilterInputProps.projectId;
    const labelsOpt = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF(((entity.tag === 0) ? ((projectId_1) => ((tagParams) => labels_1(projectId_1, tagParams))) : ((entity.tag === 11) ? ((projectId_2) => ((actionParams) => labels_2(projectId_2, actionParams))) : ((entity.tag === 9) ? ((projectId_3) => ((documentParams) => labels_3(projectId_3, documentParams))) : ((entity.tag === 5) ? ((projectId_4) => ((activityParams) => labels_4(projectId_4, activityParams))) : ((entity.tag === 3) ? ((projectId_5) => ((facilityParams) => labels_5(projectId_5, facilityParams))) : ((entity.tag === 6) ? ((projectId_6) => ((moduleParams) => labels_6(projectId_6, moduleParams))) : ((entity.tag === 7) ? ((projectId_7) => ((areaParams) => labels_7(projectId_7, areaParams))) : ((entity.tag === 8) ? ((projectId_8) => ((locationParams) => labels_8(projectId_8, locationParams))) : ((entity.tag === 13) ? ((projectId_9) => ((productionUnitTypeParams) => labels_9(projectId_9, productionUnitTypeParams))) : ((entity.tag === 1) ? ((projectId_10) => ((systemParams) => labels_10(projectId_10, systemParams))) : ((entity.tag === 2) ? ((projectId_11) => ((subsystemParams) => labels_11(projectId_11, subsystemParams))) : ((console.warn(some(`${DomainEntities__ToPluralString(entity)} does not have a labels api`)), (_arg2) => ((_arg1) => {
        const a = new FSharpResult$2(0, empty_3());
        return Promise.resolve(a);
    }))))))))))))))(projectId), filters, []));
    if (labelsOpt != null) {
        const labels = labelsOpt;
        const inputProperties = createObj(ofArray([["placeholder", "Select Label"], ["search", true], selectSearch_filterOptions_Z7A2530AD((item, searchQuery) => (item.value.toLocaleLowerCase().indexOf(searchQuery.trim().toLocaleLowerCase()) >= 0)), ["onChange", (value) => {
            if (isNullOrWhiteSpace(value)) {
                onChange(["", ""]);
            }
            else {
                const split = split_1(value, ["⚓"], null, 0);
                if (split.length === 2) {
                    onChange([split[0], split[1]]);
                }
                else {
                    onChange(["", ""]);
                }
            }
        }], selectSearch_options_113CE11B(toList(delay(() => append(singleton(new SelectOption(0, new SelectItem("", "-- None Selected --", false))), delay(() => map((label) => (new SelectOption(1, new SelectGroup(label[0], ofSeq(map((v) => (new SelectItem(`${label[0]}⚓${v}`, v, false)), label[1]))))), labels))))))]));
        return Interop_reactApi.createElement(react_select_search, Object.assign({}, Interop_defaultProps, inputProperties));
    }
    else {
        return null;
    }
}

function DomainEntityFilter(domainEntityFilterInputProps) {
    const dispatch = domainEntityFilterInputProps.dispatch;
    const onFilterChange = domainEntityFilterInputProps.onFilterChange;
    const filters = domainEntityFilterInputProps.filters;
    const entity = domainEntityFilterInputProps.entity;
    const projectId = domainEntityFilterInputProps.projectId;
    const onChange = useReact_useCallback_93353E((arg_1) => {
        dispatch(new Msg$1(0, onFilterChange(arg_1)));
    }, [dispatch, onFilterChange]);
    return createElement(DomainEntitySelector, {
        projectId: projectId,
        entityType: entity,
        initialEntity: void 0,
        onSelectedEntityChange: onChange,
        filters: filters,
        isSmall: true,
    });
}

function RenderFilter(renderFilterInputProps) {
    const dispatch = renderFilterInputProps.dispatch;
    const filter = renderFilterInputProps.filter;
    const key = renderFilterInputProps.$key;
    const children = toList(delay(() => ((filter.tag === 1) ? singleton(createElement(DomainEntityFilter, {
        projectId: filter.fields[0],
        entity: filter.fields[1],
        filters: filter.fields[2],
        onFilterChange: filter.fields[3],
        dispatch: dispatch,
    })) : ((filter.tag === 2) ? singleton(createElement(LabelFilter, {
        projectId: filter.fields[0],
        entity: filter.fields[1],
        filters: filter.fields[2],
        onChange: (arg_3) => {
            dispatch(new Msg$1(0, filter.fields[3](arg_3)));
        },
    })) : singleton(createElement(StringFilter, {
        onChange: (arg_1) => {
            dispatch(new Msg$1(0, filter.fields[0](arg_1)));
        },
    }))))));
    return createElement("div", {
        children: Interop_reactApi_1.Children.toArray(Array.from(children)),
    });
}

const fetchOptions = ofArray([10, 25, 50, 100, 250, 500]);

export function Table(tableInputProps) {
    let key, initialValue, patternInput, patternInput_2, elems_10, children_2, children, children_6, children_9, elems_9, elems_8, elms, props_12, props_14, props_16, props_18, patternInput_6, elems_5, elems_6;
    const rowSelection = tableInputProps.rowSelection;
    const refresh = tableInputProps.refresh;
    const rowRender = tableInputProps.rowRender;
    const fetchCount = tableInputProps.fetchCount;
    const fetchData = tableInputProps.fetchData;
    const onFiltersChange = tableInputProps.onFiltersChange;
    const initialFilters = tableInputProps.initialFilters;
    const columns = tableInputProps.columns;
    const name = tableInputProps.name;
    const columnVisbility = ((key = (`table.${name}`), (initialValue = empty_2({
        Compare: comparePrimitives,
    }), (patternInput = useReact_useState_FCFD9EF(() => {
        const existingValue = window.localStorage.getItem(key);
        if (isNullOrWhiteSpace(existingValue)) {
            return initialValue;
        }
        else {
            const result = fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type]), void 0, void 0)), existingValue);
            if (result.tag === 1) {
                console.error(some(`Unable to get ${key} from local storage`), result.fields[0]);
                return initialValue;
            }
            else {
                return result.fields[0];
            }
        }
    }), [patternInput[0], (value_1) => {
        const json = toString_1(4, Auto_generateBoxedEncoder_Z20B7B430(class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type]), void 0, void 0, void 0)(value_1));
        window.localStorage.setItem(key, json);
        patternInput[1](value_1);
    }]))))[0];
    let patternInput_3;
    const key_1 = `table.${name}.fetchSize`;
    const initialValue_1 = 25;
    patternInput_3 = ((patternInput_2 = useReact_useState_FCFD9EF(() => {
        const existingValue_1 = window.localStorage.getItem(key_1);
        if (isNullOrWhiteSpace(existingValue_1)) {
            return initialValue_1;
        }
        else {
            const result_1 = fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(int32_type, void 0, void 0)), existingValue_1);
            if (result_1.tag === 1) {
                console.error(some(`Unable to get ${key_1} from local storage`), result_1.fields[0]);
                return initialValue_1;
            }
            else {
                return result_1.fields[0];
            }
        }
    }), [patternInput_2[0], (value_1_1) => {
        const json_1 = toString_1(4, Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(value_1_1));
        window.localStorage.setItem(key_1, json_1);
        patternInput_2[1](value_1_1);
    }]));
    const fetchSize = patternInput_3[0] | 0;
    const initialFiltersString = join("", map_1((tupledArg) => (tupledArg[0] + tupledArg[1]), initialFilters));
    const visbileColumns = filter_3((arg) => (!FSharpSet__Contains(columnVisbility, arg)), map_1((col) => col.Id, columns));
    const canClick = !(rowSelection.tag === 0);
    const canMultiClick = rowSelection.tag === 2;
    const patternInput_4 = useFeliz_React__React_useElmish_Static_78C5B8C8(init(initialFilters, fetchSize), (msg, state) => update(fetchData, fetchCount, (data) => {
        switch (rowSelection.tag) {
            case 1: {
                rowSelection.fields[0](tryHead(data));
                break;
            }
            case 2: {
                rowSelection.fields[0](data);
                break;
            }
            default: {
            }
        }
    }, onFiltersChange, msg, state), [refresh, fetchSize, initialFiltersString]);
    const state_1 = patternInput_4[0];
    const dispatch = patternInput_4[1];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(0);
    const lastIndex = patternInput_5[0] | 0;
    const onRowClick = useReact_useCallback_93353E((event) => ((i) => {
        if (canMultiClick) {
            if (event.shiftKey) {
                const start = min(comparePrimitives, i, lastIndex) | 0;
                const endIndex = max(comparePrimitives, i, lastIndex) | 0;
                const indexToIgnore = ((i === start) ? endIndex : start) | 0;
                dispatch(new Msg$1(10, filter_3((i_2) => (i_2 !== indexToIgnore), toList(delay(() => map((i_1) => i_1, rangeDouble(start, 1, endIndex)))))));
            }
            else if (event.ctrlKey) {
                dispatch(new Msg$1(10, singleton_1(i)));
            }
            else {
                dispatch(new Msg$1(9, i));
            }
        }
        else {
            dispatch(new Msg$1(9, i));
        }
        patternInput_5[1](i);
    }), [canClick, canMultiClick, dispatch, lastIndex]);
    const onRowDoubleClick = useReact_useCallback_93353E((_arg1) => {
        dispatch(new Msg$1(11));
    }, [dispatch]);
    const props_28 = ofArray([["className", "is-bordered"], ["className", "is-striped"], ["className", "is-narrow"], ["className", "is-hoverable"], (elems_10 = [(children_2 = singleton_1((children = mapIndexed((i_3, col_2) => {
        let elems_3;
        return createElement("th", createObj(ofArray([["onClick", (_arg2) => {
            dispatch(new Msg$1(13, col_2.Id));
        }], (elems_3 = toList(delay(() => {
            let arg10, elems_2, arg00_1, elems_1, elems, props;
            return append((i_3 === 0) ? singleton((arg10 = singleton_1((elems_2 = toList(delay(() => append(singleton(col_2.Name), delay(() => (equals(state_1.SortColumnId, col_2.Id) ? (state_1.SortAscending ? singleton(Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-angle-up")), [])) : singleton(Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-angle-down")), []))) : singleton(null)))))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])), (arg00_1 = ofArray([(elems_1 = [Fa_i(ofArray([new Fa_IconOption(11, "fas fa-columns"), new Fa_IconOption(15, "column-option")]), [])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))]), ["className", "is-clickable"], ["title", "Column Options"]]), createElement("span", createObj(ofArray([["className", "icon-text"], (elems = [(props = toList(delay(() => arg00_1)), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("span", createObj(toList(delay(() => arg10))))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])))))) : append(singleton(col_2.Name), delay(() => (equals(state_1.SortColumnId, col_2.Id) ? (state_1.SortAscending ? singleton(Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-angle-up")), [])) : singleton(Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-angle-down")), []))) : singleton(null)))), delay(() => {
                let $key4C0D4278;
                const matchValue = col_2.Filter;
                if (matchValue != null) {
                    const filter = matchValue;
                    return singleton(($key4C0D4278 = (`${name}:${col_2.Id}:filter`), createElement(RenderFilter, {
                        key: $key4C0D4278,
                        $key: $key4C0D4278,
                        filter: filter,
                        dispatch: dispatch,
                    })));
                }
                else {
                    return singleton(null);
                }
            }));
        })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))])])));
    }, filter_3((col_1) => (!FSharpSet__Contains(columnVisbility, col_1.Id)), columns)), createElement("tr", {
        children: Interop_reactApi_1.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: Interop_reactApi_1.Children.toArray(Array.from(children_2)),
    })), (children_6 = mapIndexed((i_4, row) => {
        const renderedRow = rowRender(row);
        const cellMap = ofList(map_1((cell) => [cell.Id, cell], renderedRow.Cells));
        return createElement("tr", createObj(toList(delay(() => append(singleton(["key", renderedRow.Id]), delay(() => append(canClick ? append(singleton(["onClick", (e) => {
            onRowClick(e)(i_4);
        }]), delay(() => append(singleton(["onDoubleClick", onRowDoubleClick]), delay(() => (FSharpSet__Contains(state_1.SelectedIndex, i_4) ? singleton(op_PlusPlus(["className", "is-selected"], ["className", "is-clickable"])) : singleton(["className", "is-clickable"])))))) : empty(), delay(() => {
            let elems_4;
            return singleton((elems_4 = map((cell_1) => {
                const children_4 = singleton_1(defaultArg(map_2((c) => c.Render, FSharpMap__TryFind(cellMap, cell_1)), null));
                return createElement("td", {
                    children: Interop_reactApi_1.Children.toArray(Array.from(children_4)),
                });
            }, visbileColumns), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_4))]));
        }))))))));
    }, state_1.Data), createElement("tbody", {
        children: Interop_reactApi_1.Children.toArray(Array.from(children_6)),
    })), (children_9 = ofArray([createElement("tr", {
        className: "totals",
        children: createElement("td", createObj(toList(delay(() => append(singleton(["colSpan", length(visbileColumns)]), delay(() => (state_1.IsTotalLoading ? singleton(["children", "Loading..."]) : (isEmpty(state_1.Data) ? singleton(["children", "No data available"]) : singleton(["children", `Showing ${System_Int32__Int32_ToLocaleString(state_1.Offset + 1)} - ${System_Int32__Int32_ToLocaleString(state_1.Offset + length(state_1.Data))} of ${System_Int32__Int32_ToLocaleString(state_1.Total)} results`]))))))))),
    }), createElement("tr", createObj(ofArray([["colSpan", length(visbileColumns)], (elems_9 = [createElement("td", createObj(ofArray([["colSpan", length(visbileColumns)], (elems_8 = [(elms = ofArray([(props_12 = ofArray([["children", "\u003c\u003c"], ["onClick", (_arg3) => {
        dispatch(new Msg$1(2));
    }], ["disabled", (!State$1__get_CanFirst(state_1)) ? true : state_1.IsLoading]]), createElement("button", createObj(Helpers_combineClasses("button", props_12)))), (props_14 = ofArray([["children", "\u003c"], ["onClick", (_arg4) => {
        dispatch(new Msg$1(4));
    }], ["disabled", (!State$1__get_CanPrevious(state_1)) ? true : state_1.IsLoading]]), createElement("button", createObj(Helpers_combineClasses("button", props_14)))), (props_16 = ofArray([["children", "\u003e"], ["onClick", (_arg5) => {
        dispatch(new Msg$1(3));
    }], ["disabled", (!State$1__get_CanNext(state_1)) ? true : state_1.IsLoading]]), createElement("button", createObj(Helpers_combineClasses("button", props_16)))), (props_18 = ofArray([["children", "\u003e\u003e"], ["onClick", (_arg6) => {
        dispatch(new Msg$1(5));
    }], ["disabled", (!State$1__get_CanLast(state_1)) ? true : state_1.IsLoading]]), createElement("button", createObj(Helpers_combineClasses("button", props_18)))), (patternInput_6 = Helpers_extractClasses(ofArray([["defaultValue", fetchSize], ["onChange", (ev) => {
        iterate(patternInput_3[1], toArray(System_Int32__Int32_tryParse_Static_Z721C83C5(ev.target.value)));
    }], (elems_5 = map_1((size) => createElement("option", {
        children: `Show ${size}`,
        value: size,
    }), fetchOptions), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_5))])])), createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput_6[0]))], (elems_6 = [createElement("select", createObj(patternInput_6[1]))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_6))])]))))]), createElement("div", {
        className: "buttons",
        children: Interop_reactApi_1.Children.toArray(Array.from(elms)),
    }))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_9))])])))]), createElement("tfoot", {
        children: Interop_reactApi_1.Children.toArray(Array.from(children_9)),
    }))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_10))])]);
    return createElement("table", createObj(Helpers_combineClasses("table", props_28)));
}

