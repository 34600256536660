import { PromiseBuilder__For_1565554B, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { cons, empty, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { toArray, some } from "../fable_modules/fable-library.3.7.3/Option.js";
import { dataUrlToCanvas, readAsDataUrlPromise, readAsArrayBufferPromise, System_String__String_ToOption_Static_Z721C83C5 } from "../Prelude.fs.js";
import * as webpack from "pdfjs-dist/webpack";
import { rangeDouble } from "../fable_modules/fable-library.3.7.3/Range.js";
import { pdfToCanvas } from "../Bindings/PDF.fs.js";
import { iterate } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { getScanner } from "../Bindings/QRScanner.fs.js";

function region(x, y, width, height) {
    return new (class {
        get x() {
            return x;
        }
        set x(_arg1) {
        }
        get y() {
            return y;
        }
        set y(_arg2) {
        }
        get width() {
            return width;
        }
        set width(_arg3) {
        }
        get height() {
            return height;
        }
        set height(_arg4) {
        }
        get downScaledWidth() {
            return (width > 4000) ? (width / 4) : ((width > 2000) ? (width / 3) : ((width > 1000) ? (width / 2) : width));
        }
        set downScaledWidth(_arg5) {
        }
        get downScaledHeight() {
            return (width > 4000) ? (height / 4) : ((width > 2000) ? (height / 3) : ((width > 1000) ? (height / 2) : height));
        }
        set downScaledHeight(_arg6) {
        }
    }
    )();
}

function scanRegions(scanner, qrScannerEngine, width, height, scanData) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const regionHeight = height / 4;
        const regionWidth = width / 4;
        const regions = ofArray([region(0, 0, regionWidth, regionHeight), region(regionWidth, 0, regionWidth, regionHeight), region(regionWidth * 2, 0, regionWidth, regionHeight), region(regionWidth * 3, 0, regionWidth, regionHeight)]);
        let result = void 0;
        return PromiseBuilder__For_1565554B(promise, regions, (_arg1) => {
            const region_1 = _arg1;
            return (result == null) ? (PromiseBuilder__Delay_62FBFDE1(promise, () => (scanner.scanImage(scanData, region_1, some(qrScannerEngine)).then((_arg2) => {
                result = System_String__String_ToOption_Static_Z721C83C5(_arg2);
                return Promise.resolve();
            }))).catch((_arg3) => {
                console.warn(some(`Failed to find QR in Region: x:${region_1.x}, y:${region_1.y}, width:${region_1.width}, height:${region_1.height}`), _arg3);
                return Promise.resolve();
            })) : (Promise.resolve());
        }).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => (Promise.resolve(result))));
    }));
}

function scanWhole(scanner, qrScannerEngine, width, height, scanData) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const wholeRegion = region(0, 0, width, height);
        return scanner.scanImage(scanData, wholeRegion, some(qrScannerEngine)).then((_arg1) => (Promise.resolve(System_String__String_ToOption_Static_Z721C83C5(_arg1))));
    }).catch((_arg2) => {
        console.warn(some("Failed to find QR in whole File"), _arg2);
        return Promise.resolve(void 0);
    }))));
}

function scanForQR(scanner, qrScannerEngine, width, height, scanData) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (scanWhole(scanner, qrScannerEngine, width, height, scanData).then((_arg1) => {
        const resultOpt = _arg1;
        if (resultOpt == null) {
            return scanWhole(scanner, qrScannerEngine, width, height, scanData);
        }
        else {
            const result = resultOpt;
            return Promise.resolve(result);
        }
    }))));
}

function detectQRonPDF(scanner, qrScannerEngine, pdf) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (readAsArrayBufferPromise(pdf).then((_arg1) => (webpack.getDocument(_arg1).promise.then((_arg2) => {
        const pdfDoc = _arg2;
        let qrDetected = false;
        let result = void 0;
        return PromiseBuilder__For_1565554B(promise, rangeDouble(1, 1, pdfDoc.numPages), (_arg3) => ((!qrDetected) ? (pdfToCanvas(pdfDoc, _arg3).then((_arg4) => {
            const canvasOpt = _arg4;
            if (canvasOpt != null) {
                const canvas = canvasOpt;
                return scanForQR(scanner, qrScannerEngine, canvas.width, canvas.height, canvas).then((_arg5) => {
                    iterate((qr) => {
                        qrDetected = true;
                        result = qr;
                    }, toArray(_arg5));
                    return Promise.resolve();
                });
            }
            else {
                return Promise.resolve();
            }
        })) : (Promise.resolve()))).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => (Promise.resolve(result))));
    }))))));
}

function detectQROnImage(scanner, qrScannerEngine, file) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (readAsDataUrlPromise(file).then((_arg1) => (dataUrlToCanvas(_arg1).then((_arg2) => {
        const canvas = _arg2;
        return scanForQR(scanner, qrScannerEngine, canvas.width, canvas.height, canvas);
    }))))));
}

export function detectQROnFiles(files) {
    const scanner = getScanner();
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let results = empty();
        return scanner.createQrEngine().then((_arg1) => {
            const qrScannerEngine = _arg1;
            return PromiseBuilder__For_1565554B(promise, files, (_arg2) => {
                let matchValue;
                const file = _arg2;
                return ((matchValue = file.type, (matchValue === "application/pdf") ? detectQRonPDF(scanner, qrScannerEngine, file) : ((matchValue === "image/jpg") ? detectQROnImage(scanner, qrScannerEngine, file) : ((matchValue === "image/jpeg") ? detectQROnImage(scanner, qrScannerEngine, file) : ((matchValue === "image/png") ? detectQROnImage(scanner, qrScannerEngine, file) : (Promise.resolve(void 0))))))).then((_arg3) => {
                    results = cons([file, _arg3], results);
                    return Promise.resolve();
                });
            }).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => (Promise.resolve(results))));
        });
    }));
}

