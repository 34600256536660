import { Feliz_Router_Router__Router_navigatePath_Static_Z499477B1, Feliz_Router_Router__Router_formatPath_Static_Z499477B1, Feliz_Router_Router__Router_getCurrentParsedUrl_Static } from "../Prelude.fs.js";
import { Page__OnClick_Z11029FF1, Page__get_Href, Page, ProjectPage, EntityType__ToProperString, EntityType__ToProperPluralString, SearchParams, SearchParams__get_toKeyValue } from "../ClientTypes.fs.js";
import { ofList, FSharpMap__TryFind, FSharpMap__Add } from "../fable_modules/fable-library.3.7.3/Map.js";
import { empty as empty_1, cons, singleton as singleton_1, choose, tryPick, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { useReact_useCallback_93353E, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { useFeliz_React__React_useSearchType_Static, useFeliz_React__React_useTypedParams_Static } from "../hooks/UseRouter.fs.js";
import { value as value_113, map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { Impl_createRemoveOptions, Impl_adjustPassive, Impl_defaultPassive } from "../fable_modules/Feliz.UseListener.0.6.3/Listener.fs.js";
import { React_createDisposable_3A5B6456, useReact_useCallbackRef_7C4B0DD6, useReact_useEffect_Z5ECA432F, useReact_useMemo_CF4EA67 } from "../fable_modules/Feliz.UseListener.0.6.3/../Feliz.1.58.1/React.fs.js";
import { op_PlusPlus } from "../fable_modules/Feliz.Bulma.2.18.0/Operators.fs.js";
import { map as map_1, empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { PropHelpers_createOnKey } from "../fable_modules/Feliz.1.58.1/Properties.fs.js";
import { key_enter } from "../fable_modules/Feliz.1.58.1/Key.fs.js";
import { useFeliz_React__React_useFetch_Static_Z4BDF3CFF, Fetched$1_toOption_50D43789 } from "../hooks/UseFetch.fs.js";
import { many } from "../Api/Projects.fs.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.8.0/Router.fs.js";
import { join } from "../fable_modules/fable-library.3.7.3/String.js";
import { useFeliz_React__React_useProjectContext_Static } from "../hooks/UseProjectContext.fs.js";
import { logOut } from "../Api/Auth.fs.js";
import "./Header.scss";


function downdownButton(close, entityType) {
    const patternInput = Feliz_Router_Router__Router_getCurrentParsedUrl_Static();
    const currentUrl = patternInput[0];
    let newQuery;
    const tupledArg = SearchParams__get_toKeyValue(new SearchParams(0, entityType));
    newQuery = FSharpMap__Add(patternInput[1], tupledArg[0], tupledArg[1]);
    const path = Feliz_Router_Router__Router_formatPath_Static_Z499477B1(currentUrl, newQuery);
    const props = ofArray([["children", EntityType__ToProperPluralString(entityType)], ["href", path], ["onClick", (e) => {
        e.preventDefault();
        close();
        Feliz_Router_Router__Router_navigatePath_Static_Z499477B1(currentUrl, newQuery);
    }]]);
    return createElement("a", createObj(Helpers_combineClasses("dropdown-item", props)));
}

export const dropDown2Values = ofArray([["Name", "Order By ..."], ["Name", "Order By Name"], ["ID", "Order By ID"], ["LastModified", "Order By Last Modified"]]);

function Search() {
    let elems_11, elms_3, props_8, props_13, elems_7, props_11, value_44, elms_5, props_16, elems_9, elms_4;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setMenuOpen = patternInput[1];
    const menuOpen = patternInput[0];
    const ref = useReact_useRef_1505(void 0);
    const openMenu = useReact_useCallback_93353E((_arg1) => {
        setMenuOpen(true);
    });
    const closeMenuButton = useReact_useCallback_93353E(() => {
        setMenuOpen(false);
    });
    const closeMenu = useReact_useCallback_93353E((_arg2) => {
        setMenuOpen(false);
    });
    const toggle = useReact_useCallback_93353E((_arg3) => {
        setMenuOpen(!menuOpen);
    });
    const searchParams = useFeliz_React__React_useTypedParams_Static();
    const entityTypeOpt = useFeliz_React__React_useSearchType_Static();
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(defaultArg(tryPick((p) => {
        if (p.tag === 1) {
            return p.fields[0];
        }
        else {
            return void 0;
        }
    }, searchParams), ""));
    const searchText = patternInput_1[0];
    const ordersql_1 = defaultArg(tryPick((o) => {
        if (o.tag === 1) {
            return o.fields[0];
        }
        else {
            return void 0;
        }
    }, searchParams), "Name");
    const goSearch = useReact_useCallback_93353E(() => {
        const patternInput_2 = Feliz_Router_Router__Router_getCurrentParsedUrl_Static();
        const searchType = new SearchParams(0, defaultArg(entityTypeOpt, "activity"));
        const infoPanel = map((ip) => ["ip", ip], FSharpMap__TryFind(patternInput_2[1], "ip"));
        Feliz_Router_Router__Router_navigatePath_Static_Z499477B1(patternInput_2[0], ofList(choose((x) => x, ofArray([SearchParams__get_toKeyValue(new SearchParams(1, searchText)), SearchParams__get_toKeyValue(searchType), infoPanel]))));
    }, [searchText]);
    const goSearchExact = useReact_useCallback_93353E((_arg5) => {
        const patternInput_3 = Feliz_Router_Router__Router_getCurrentParsedUrl_Static();
        const searchType_1 = new SearchParams(0, defaultArg(entityTypeOpt, "activity"));
        const infoPanel_1 = map((ip_1) => ["ip", ip_1], FSharpMap__TryFind(patternInput_3[1], "ip"));
        Feliz_Router_Router__Router_navigatePath_Static_Z499477B1(patternInput_3[0], ofList(choose((x_1) => x_1, ofArray([SearchParams__get_toKeyValue(new SearchParams(1, searchText)), SearchParams__get_toKeyValue(searchType_1), infoPanel_1]))));
    }, [searchText]);
    const elemRef = ref;
    const callback = closeMenu;
    const options_1 = defaultArg(void 0, Impl_defaultPassive);
    const eventType = "mousedown";
    const action_1 = (ev) => {
        let copyOfStruct;
        const matchValue = elemRef.current;
        let pattern_matching_result;
        if (matchValue != null) {
            if (!((copyOfStruct = value_113(matchValue), copyOfStruct.contains(ev.target)))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                callback(ev);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_3 = options_1;
    const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_3), [options_3]);
    const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_3), [options_3]);
    const fn = useReact_useMemo_CF4EA67(() => ((arg) => {
        action_1(arg);
    }), [action_1]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions == null) {
            document.addEventListener(eventType, fn);
        }
        else {
            const options_1_1 = addOptions;
            document.addEventListener(eventType, fn, options_1_1);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions == null) {
                document.removeEventListener(eventType, fn);
            }
            else {
                const options_2_1 = removeOptions;
                document.removeEventListener(eventType, fn, options_2_1);
            }
        });
    }));
    const eventType_1 = "touchstart";
    const action_3 = (ev_1) => {
        let copyOfStruct_1;
        const matchValue_1 = elemRef.current;
        let pattern_matching_result_1;
        if (matchValue_1 != null) {
            if (!((copyOfStruct_1 = value_113(matchValue_1), copyOfStruct_1.contains(ev_1.target)))) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                callback(ev_1);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_5 = options_1;
    const addOptions_1 = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_5), [options_5]);
    const removeOptions_1 = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_5), [options_5]);
    const fn_1 = useReact_useMemo_CF4EA67(() => ((arg_1) => {
        action_3(arg_1);
    }), [action_3]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions_1 == null) {
            document.addEventListener(eventType_1, fn_1);
        }
        else {
            const options_1_2 = addOptions_1;
            document.addEventListener(eventType_1, fn_1, options_1_2);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions_1 == null) {
                document.removeEventListener(eventType_1, fn_1);
            }
            else {
                const options_2_2 = removeOptions_1;
                document.removeEventListener(eventType_1, fn_1, options_2_2);
            }
        });
    }));
    const props_19 = ofArray([op_PlusPlus(["className", "search-bar"], ["className", "has-addons"]), (elems_11 = [(elms_3 = singleton_1((props_8 = toList(delay(() => append(singleton(["ref", ref]), delay(() => append(menuOpen ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let elems_5, elms, props_3, elems_1, props_1, elems, elms_2, elms_1;
        return singleton((elems_5 = [(elms = singleton_1((props_3 = ofArray([["onClick", toggle], (elems_1 = [createElement("span", createObj(toList(delay(() => append(singleton(["className", "is-size-7-mobile"]), delay(() => ((entityTypeOpt == null) ? singleton(["children", "Choose"]) : singleton(["children", EntityType__ToProperString(entityTypeOpt)])))))))), (props_1 = ofArray([["className", "is-small"], (elems = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-angle-down")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_1))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), createElement("button", createObj(Helpers_combineClasses("button", props_3))))), createElement("div", {
            className: "dropdown-trigger",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_2 = singleton_1((elms_1 = ofArray([downdownButton(closeMenuButton, "activity"), downdownButton(closeMenuButton, "area"), downdownButton(closeMenuButton, "certification"), downdownButton(closeMenuButton, "document"), downdownButton(closeMenuButton, "documentTracking"), downdownButton(closeMenuButton, "facility"), downdownButton(closeMenuButton, "location"), downdownButton(closeMenuButton, "module"), downdownButton(closeMenuButton, "nonConformanceReport"), downdownButton(closeMenuButton, "preservation"), downdownButton(closeMenuButton, "productionUnit"), downdownButton(closeMenuButton, "punchList"), downdownButton(closeMenuButton, "scopeOfWork"), downdownButton(closeMenuButton, "subsystem"), downdownButton(closeMenuButton, "system"), downdownButton(closeMenuButton, "tag"), downdownButton(closeMenuButton, "technicalQuery")]), createElement("div", {
            className: "dropdown-content",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), createElement("div", {
            className: "dropdown-menu",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]));
    })))))), createElement("div", createObj(Helpers_combineClasses("dropdown", props_8))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (props_13 = ofArray([["className", "is-expanded"], (elems_7 = [(props_11 = ofArray([["className", "search-bar-input"], ["onChange", (ev_2) => {
        patternInput_1[1](ev_2.target.value);
    }], ["onKeyDown", (ev_3) => {
        PropHelpers_createOnKey(key_enter, (_arg6) => {
            goSearch();
        }, ev_3);
    }], (value_44 = searchText, ["ref", (e) => {
        if ((!(e == null)) && (!equals(e.value, value_44))) {
            e.value = value_44;
        }
    }]), ["placeholder", "Search..."]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_11)))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]), createElement("div", createObj(Helpers_combineClasses("control", props_13)))), (elms_5 = singleton_1((props_16 = ofArray([["className", "search-button"], ["onClick", (_arg7) => {
        goSearch();
    }], (elems_9 = [(elms_4 = singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-search")), [])), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]), createElement("button", createObj(Helpers_combineClasses("button", props_16))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]);
    return createElement("div", createObj(Helpers_combineClasses("field", props_19)));
}

function ProjectSwitcher() {
    let elems, elems_1;
    const projectOptions = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF(many, void 0, []));
    if (projectOptions == null) {
        return createElement("div", {
            children: "Loading projects...",
        });
    }
    else {
        const options = projectOptions;
        const patternInput = Helpers_extractClasses(ofArray([["onChange", (ev) => {
            RouterModule_nav(singleton_1("/projects/" + ev.target.value), 1, 2);
        }], (elems = toList(delay(() => append(singleton(createElement("option", {
            value: "",
            children: "Select a project",
        })), delay(() => map_1((project) => createElement("option", {
            value: project.ProjectId,
            children: project.Name,
        }), options))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]));
        return createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput[0]))], (elems_1 = [createElement("select", createObj(patternInput[1]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
    }
}

export function Header() {
    let elems_11, elms, props_5, elms_1, props_38, elems_10, props_36, elems_9, props_34;
    const projectContext = useFeliz_React__React_useProjectContext_Static();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const showOptions = patternInput[0];
    const setShowOptions = patternInput[1];
    const ref = useReact_useRef_1505(void 0);
    const elemRef = ref;
    const callback = useReact_useCallback_93353E((_arg1) => {
        setShowOptions(false);
    });
    const options_1 = defaultArg(void 0, Impl_defaultPassive);
    const eventType = "mousedown";
    const action_1 = (ev) => {
        let copyOfStruct;
        const matchValue = elemRef.current;
        let pattern_matching_result;
        if (matchValue != null) {
            if (!((copyOfStruct = value_113(matchValue), copyOfStruct.contains(ev.target)))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                callback(ev);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_3 = options_1;
    const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_3), [options_3]);
    const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_3), [options_3]);
    const fn = useReact_useMemo_CF4EA67(() => ((arg) => {
        action_1(arg);
    }), [action_1]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions == null) {
            document.addEventListener(eventType, fn);
        }
        else {
            const options_1_1 = addOptions;
            document.addEventListener(eventType, fn, options_1_1);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions == null) {
                document.removeEventListener(eventType, fn);
            }
            else {
                const options_2_1 = removeOptions;
                document.removeEventListener(eventType, fn, options_2_1);
            }
        });
    }));
    const eventType_1 = "touchstart";
    const action_3 = (ev_1) => {
        let copyOfStruct_1;
        const matchValue_1 = elemRef.current;
        let pattern_matching_result_1;
        if (matchValue_1 != null) {
            if (!((copyOfStruct_1 = value_113(matchValue_1), copyOfStruct_1.contains(ev_1.target)))) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                callback(ev_1);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_5 = options_1;
    const addOptions_1 = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_5), [options_5]);
    const removeOptions_1 = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_5), [options_5]);
    const fn_1 = useReact_useMemo_CF4EA67(() => ((arg_1) => {
        action_3(arg_1);
    }), [action_3]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions_1 == null) {
            document.addEventListener(eventType_1, fn_1);
        }
        else {
            const options_1_2 = addOptions_1;
            document.addEventListener(eventType_1, fn_1, options_1_2);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions_1 == null) {
                document.removeEventListener(eventType_1, fn_1);
            }
            else {
                const options_2_2 = removeOptions_1;
                document.removeEventListener(eventType_1, fn_1, options_2_2);
            }
        });
    }));
    const epmLogo = createElement("img", {
        src: "/logoepm4.svg",
        className: "epm-logo",
        alt: "EPM",
    });
    let defaultProjectIcon;
    const props_1 = ofArray([["className", "epm-logo"], ["children", Fa_i(ofArray([new Fa_IconOption(11, "fas fa-project-diagram"), new Fa_IconOption(0, new Fa_ISize(2))]), [])]]);
    defaultProjectIcon = createElement("span", createObj(Helpers_combineClasses("icon", props_1)));
    const projectPage = new Page(1, new ProjectPage(0, projectContext.ProjectId));
    const props_40 = ofArray([["className", "header-container"], ["className", "px-0"], ["className", "is-radiusless"], ["className", "is-fixed-top"], (elems_11 = [(elms = singleton_1(createElement("a", {
        href: Page__get_Href(new Page(0)),
        onClick: (arg00_2) => {
            Page__OnClick_Z11029FF1(new Page(0), arg00_2);
        },
        className: "navbar-item",
        title: "Project Selection",
        children: epmLogo,
    })), createElement("div", {
        className: "navbar-brand",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_5 = ofArray([["className", "is-expanded"], ["children", createElement(Search, null)]]), createElement("div", createObj(Helpers_combineClasses("navbar-item", props_5)))), (elms_1 = singleton_1(ProjectSwitcher()), createElement("div", {
        className: "navbar-item",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (props_38 = ofArray([["className", "project-info"], (elems_10 = [(props_36 = ofArray([["className", "is-clickable"], ["onClick", (_arg2) => {
        setShowOptions(!showOptions);
    }], (elems_9 = [(props_34 = toList(delay(() => append(singleton(["className", "is-right"]), delay(() => append(singleton(["ref", ref]), delay(() => append(showOptions ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let elems_8;
        return singleton((elems_8 = toList(delay(() => append(singleton(createElement("div", createObj(ofArray([op_PlusPlus(["className", "is-hidden-mobile"], ["className", "project-name"]), ["children", projectContext.Config.Name]])))), delay(() => {
            let matchValue_2, imageUrl;
            return append((matchValue_2 = projectContext.Config.ImageUrl, (matchValue_2 == null) ? singleton(defaultProjectIcon) : ((imageUrl = matchValue_2, singleton(createElement("img", {
                className: "project-thumbnail",
                src: imageUrl,
                alt: projectContext.Config.Name,
            }))))), delay(() => {
                let props_32, elems_7, elms_3, props_14, arg00_4, elems_2, props_10, props_20, arg00_5, elems_3, props_16, elms_2, props_28, arg00_6, elems_4, props_24;
                return singleton((props_32 = singleton_1((elems_7 = [(elms_3 = ofArray([(props_14 = ofArray([["href", Page__get_Href(projectPage)], ["onClick", (arg00_3) => {
                    Page__OnClick_Z11029FF1(projectPage, arg00_3);
                }], ["children", (arg00_4 = singleton_1(["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-home")), [])]), createElement("span", createObj(ofArray([["className", "icon-text"], (elems_2 = [(props_10 = toList(delay(() => arg00_4)), createElement("span", createObj(Helpers_combineClasses("icon", props_10)))), createElement("span", createObj(toList(delay(() => [["children", "Project Home"]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))))]]), createElement("a", createObj(Helpers_combineClasses("dropdown-item", props_14)))), (props_20 = ofArray([["href", "https://help.unasys.com"], ["rel", "noopener noreferrer"], ["target", "_blank"], ["children", (arg00_5 = singleton_1(["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-question-circle")), [])]), createElement("span", createObj(ofArray([["className", "icon-text"], (elems_3 = [(props_16 = toList(delay(() => arg00_5)), createElement("span", createObj(Helpers_combineClasses("icon", props_16)))), createElement("span", createObj(toList(delay(() => [["children", "Help \u0026 Support"]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))))]]), createElement("a", createObj(Helpers_combineClasses("dropdown-item", props_20)))), createElement("hr", createObj(Helpers_combineClasses("dropdown-divider", empty_1()))), (elms_2 = singleton_1((props_28 = ofArray([["className", "is-clickable"], ["children", (arg00_6 = singleton_1(["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-sign-out-alt")), [])]), createElement("span", createObj(ofArray([["className", "icon-text"], (elems_4 = [(props_24 = toList(delay(() => arg00_6)), createElement("span", createObj(Helpers_combineClasses("icon", props_24)))), createElement("span", createObj(toList(delay(() => [["children", "Sign out"]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))))], ["onClick", (_arg3) => {
                    logOut();
                }]]), createElement("div", createObj(Helpers_combineClasses("dropdown-item", props_28))))), createElement("div", {
                    className: "dropdown-content",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))]), createElement("div", {
                    className: "dropdown-content",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])), createElement("div", createObj(Helpers_combineClasses("dropdown-menu", props_32)))));
            }));
        })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))]));
    })))))))), createElement("div", createObj(Helpers_combineClasses("dropdown", props_34))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]), createElement("div", createObj(Helpers_combineClasses("navbar-item", props_36))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]), createElement("div", createObj(Helpers_combineClasses("navbar-end", props_38))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]);
    return createElement("nav", createObj(Helpers_combineClasses("navbar", props_40)));
}

