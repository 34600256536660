import { DeleteEntity_Encoder_Z66C2339E, ChangeAttributes_Encoder_69034AA9, ChangeLabels_Encoder_19803C73, Tags_AssignCriticality_Encoder_676A2961, Tags_AssignCondition_Encoder_390FCD5D, Tags_AssignStatus_Encoder_4F7D8E9A, Tags_AssignEquipmentType_Encoder_Z20DA3D64, Tags_MoveToPhysicalPosition_Encoder_57EECAC9, Tags_MoveToLogicalLocation_Encoder_54F1845B, Tags_UpdateTagDetails_Encoder_Z6EE3A1D2, Tags_CreateTag_Encoder_Z4E2CBE07, labelDecoder, Tags_Tag$reflection, Tags_Tag_get_Decoder } from "../ServerTypes.fs.js";
import { RouterModule_encodeQueryString } from "../fable_modules/Feliz.Router.3.8.0/Router.fs.js";
import { cons, ofArray, empty, append } from "../fable_modules/fable-library.3.7.3/List.js";
import { printf, toConsole } from "../fable_modules/fable-library.3.7.3/String.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { Helpers_baseApiUrl, Helpers_withSecureHeader } from "./Api.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Thoth.Fetch.3.0.1/../Fable.Promise.3.1.3/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Thoth.Fetch.3.0.1/../Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties, FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { some, map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.4.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.3/MapUtil.js";
import { toString } from "../fable_modules/Thoth.Fetch.3.0.1/../Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { class_type, string_type, int32_type, unit_type, equals, list_type, obj_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { string, int, list, Auto_generateBoxedDecoderCached_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { uncurry } from "../fable_modules/fable-library.3.7.3/Util.js";
import { fromString } from "../fable_modules/Thoth.Fetch.3.0.1/../Thoth.Json.7.0.0/Decode.fs.js";

export function many(projectId, apiParams, tagParams) {
    const decoder = Tags_Tag_get_Decoder();
    const newUrl = (`projects/${projectId}/tags`) + RouterModule_encodeQueryString(append(apiParams, tagParams));
    toConsole(printf("%s"))(newUrl);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1_1 = `${Helpers_baseApiUrl}/${newUrl}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_2;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_2 = Auto_generateBoxedDecoderCached_79988AEF(list_type(Tags_Tag$reflection()), caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_2)))((path) => ((value) => list(uncurry(2, decoder), path, value))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(list_type(Tags_Tag$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function count(projectId, tagParams) {
    const newUrl = (`projects/${projectId}/tags/count`) + RouterModule_encodeQueryString(tagParams);
    toConsole(printf("%s"))(newUrl);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1_1 = `${Helpers_baseApiUrl}/${newUrl}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(int32_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(int), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(int32_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function single(projectId, tagId) {
    const decoder = Tags_Tag_get_Decoder();
    const url = `projects/${projectId}/tags/${tagId}`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(Tags_Tag$reflection(), caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(decoder), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(Tags_Tag$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function labels(projectId, tagParams) {
    const newUrl = (`projects/${projectId}/tags/labels`) + RouterModule_encodeQueryString(tagParams);
    toConsole(printf("%s"))(newUrl);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1_1 = `${Helpers_baseApiUrl}/${newUrl}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])]), caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(labelDecoder), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])]), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function attributes(projectId, tagParams) {
    const newUrl = (`projects/${projectId}/tags/attributes`) + RouterModule_encodeQueryString(tagParams);
    toConsole(printf("%s"))(newUrl);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1_1 = `${Helpers_baseApiUrl}/${newUrl}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_3;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_3 = Auto_generateBoxedDecoderCached_79988AEF(list_type(string_type), caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_3)))((path_1) => ((value_1) => list(string, path_1, value_1))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(list_type(string_type), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function create(projectId, createTag) {
    const url = `projects/${projectId}/tags`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Tags_CreateTag_Encoder_Z4E2CBE07(createTag);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function update(projectId, tagId, updateTag) {
    const url = `projects/${projectId}/tags/${tagId}/update-tag`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Tags_UpdateTagDetails_Encoder_Z6EE3A1D2(updateTag);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function moveLogically(projectId, tagId, moveLogically_1) {
    const url = `projects/${projectId}/tags/${tagId}/move-logically`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Tags_MoveToLogicalLocation_Encoder_54F1845B(moveLogically_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function movePhysically(projectId, tagId, movePhysically_1) {
    const url = `projects/${projectId}/tags/${tagId}/move-physically`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Tags_MoveToPhysicalPosition_Encoder_57EECAC9(movePhysically_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function assignEquipmentType(projectId, tagId, assignEquipmentType_1) {
    const url = `projects/${projectId}/tags/${tagId}/assign-to-equipment-type`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Tags_AssignEquipmentType_Encoder_Z20DA3D64(assignEquipmentType_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function assignStatus(projectId, tagId, assignStatus_1) {
    const url = `projects/${projectId}/tags/${tagId}/assign-status`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Tags_AssignStatus_Encoder_4F7D8E9A(assignStatus_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function assignCondition(projectId, tagId, assignCondition_1) {
    const url = `projects/${projectId}/tags/${tagId}/assign-condition`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Tags_AssignCondition_Encoder_390FCD5D(assignCondition_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function assignCriticality(projectId, tagId, assignCriticality_1) {
    const url = `projects/${projectId}/tags/${tagId}/assign-criticality`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Tags_AssignCriticality_Encoder_676A2961(assignCriticality_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function changeLabels(projectId, tagId, changeLabels_1) {
    const url = `projects/${projectId}/tags/${tagId}/change-labels`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = ChangeLabels_Encoder_19803C73(changeLabels_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function changeAttributes(projectId, tagId, changeAttributes_1) {
    const url = `projects/${projectId}/tags/${tagId}/change-attributes`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = ChangeAttributes_Encoder_69034AA9(changeAttributes_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function delete$(projectId, tagId, delete$_1) {
    const url = `projects/${projectId}/tags/${tagId}`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = DeleteEntity_Encoder_Z66C2339E(delete$_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("DELETE", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function undelete(projectId, tagId, delete$_1) {
    const url = `projects/${projectId}/tags/${tagId}/undelete`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = DeleteEntity_Encoder_Z66C2339E(delete$_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

