import { map, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { ProjectPage__OnClick_Z11029FF1, ProjectPage__get_Href, AdminPage__get_Name, ProjectPage } from "../ClientTypes.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { Modules } from "./Module.fs.js";
import { Areas } from "./Area.fs.js";
import { Locations } from "./Location.fs.js";
import { ProductionUnitTypes } from "./ProductionUnitType.fs.js";
import { ProductionUnits } from "./ProductionUnit.fs.js";
import { Systems } from "./System.fs.js";
import { Subsystems } from "./Subsystem.fs.js";
import { Disciplines } from "./Discipline.fs.js";
import { EquipmentTypes } from "./EquipmentType.fs.js";
import { Tags } from "./Tag.fs.js";
import { ActionTypes } from "./ActionType.fs.js";
import { Actions } from "./Action.fs.js";
import { Jobs } from "./Job.fs.js";
import { Activities } from "./Activity.fs.js";
import { Action_Category } from "../DomainEvents.fs.js";
import { Documents } from "./Document.fs.js";
import { Users } from "./User.fs.js";
import { Facilities } from "./Facility.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import "./Admin.scss";


const definitionMenu = ofArray(["productionUnitType", "productionUnit", "system", "subsystem", "facility", "module", "area", "location", "discipline", "equipmentType", "tag"]);

const executionMenu = ofArray(["actionType", "action", "activity", "certificate", "documentTracking", "preservation", "punchList", "technicalQuery"]);

const otherMenu = ofArray(["document", "job", "user"]);

function menuList(projectId, currentTab, menu) {
    return map((tab) => {
        const projectPage = new ProjectPage(7, projectId, tab);
        return createElement("li", {
            children: createElement("a", createObj(toList(delay(() => append(singleton(["children", AdminPage__get_Name(tab)]), delay(() => append(singleton(["href", ProjectPage__get_Href(projectPage)]), delay(() => append(singleton(["onClick", (arg00) => {
                ProjectPage__OnClick_Z11029FF1(projectPage, arg00);
            }]), delay(() => ((tab === currentTab) ? singleton(["className", "is-active"]) : empty()))))))))))),
        });
    }, menu);
}

export function Admin(adminInputProps) {
    let elems_5, elems_4, elms_3, elms, elms_1, elms_2;
    const adminPage = adminInputProps.adminPage;
    const projectId = adminInputProps.projectId;
    const pageContent = (adminPage === "module") ? createElement(Modules, {
        projectId: projectId,
    }) : ((adminPage === "area") ? createElement(Areas, {
        projectId: projectId,
    }) : ((adminPage === "location") ? createElement(Locations, {
        projectId: projectId,
    }) : ((adminPage === "productionUnitType") ? createElement(ProductionUnitTypes, {
        projectId: projectId,
    }) : ((adminPage === "productionUnit") ? createElement(ProductionUnits, {
        projectId: projectId,
    }) : ((adminPage === "system") ? createElement(Systems, {
        projectId: projectId,
    }) : ((adminPage === "subsystem") ? createElement(Subsystems, {
        projectId: projectId,
    }) : ((adminPage === "discipline") ? createElement(Disciplines, {
        projectId: projectId,
    }) : ((adminPage === "equipmentType") ? createElement(EquipmentTypes, {
        projectId: projectId,
    }) : ((adminPage === "tag") ? createElement(Tags, {
        projectId: projectId,
    }) : ((adminPage === "actionType") ? createElement(ActionTypes, {
        projectId: projectId,
    }) : ((adminPage === "action") ? createElement(Actions, {
        projectId: projectId,
    }) : ((adminPage === "job") ? createElement(Jobs, {
        projectId: projectId,
    }) : ((adminPage === "activity") ? createElement(Activities, {
        projectId: projectId,
        category: new Action_Category(0),
    }) : ((adminPage === "documentTracking") ? createElement(Activities, {
        projectId: projectId,
        category: new Action_Category(3),
    }) : ((adminPage === "certificate") ? createElement(Activities, {
        projectId: projectId,
        category: new Action_Category(1),
    }) : ((adminPage === "preservation") ? createElement(Activities, {
        projectId: projectId,
        category: new Action_Category(4),
    }) : ((adminPage === "punchList") ? createElement(Activities, {
        projectId: projectId,
        category: new Action_Category(2),
    }) : ((adminPage === "technicalQuery") ? createElement(Activities, {
        projectId: projectId,
        category: new Action_Category(5),
    }) : ((adminPage === "document") ? createElement(Documents, {
        projectId: projectId,
    }) : ((adminPage === "user") ? createElement(Users, {
        projectId: projectId,
    }) : createElement(Facilities, {
        projectId: projectId,
    })))))))))))))))))))));
    return createElement("div", createObj(ofArray([["className", "admin-container"], (elems_5 = [createElement("div", createObj(ofArray([["className", "menu"], (elems_4 = [(elms_3 = ofArray([createElement("p", {
        className: "menu-label",
        children: "Definition",
    }), (elms = menuList(projectId, adminPage, definitionMenu), createElement("ul", {
        className: "menu-list",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("p", {
        className: "menu-label",
        children: "Execution",
    }), (elms_1 = menuList(projectId, adminPage, executionMenu), createElement("ul", {
        className: "menu-list",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), createElement("p", {
        className: "menu-label",
        children: "Misc",
    }), (elms_2 = menuList(projectId, adminPage, otherMenu), createElement("ul", {
        className: "menu-list",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]), createElement("aside", {
        className: "menu",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", {
        className: "admin-content",
        children: pageContent,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
}

