import { replace, interpolate, toText, remove, join, isNullOrWhiteSpace } from "./fable_modules/fable-library.3.7.3/String.js";
import { tryParse } from "./fable_modules/fable-library.3.7.3/Guid.js";
import { FSharpRef } from "./fable_modules/fable-library.3.7.3/Types.js";
import { tryParse as tryParse_1 } from "./fable_modules/fable-library.3.7.3/Int32.js";
import { tryParse as tryParse_2 } from "./fable_modules/fable-library.3.7.3/Boolean.js";
import { addSeconds, month, year, create, tryParse as tryParse_3, minValue } from "./fable_modules/fable-library.3.7.3/DateOffset.js";
import { toString } from "./fable_modules/fable-library.3.7.3/Date.js";
import { tryParse as tryParse_4 } from "./fable_modules/fable-library.3.7.3/Double.js";
import { totalSeconds, totalMinutes, totalHours, totalDays, seconds as seconds_1, minutes as minutes_1, hours as hours_1, days as days_1 } from "./fable_modules/fable-library.3.7.3/TimeSpan.js";
import { toList, ofSeq, tryFind, add, fold } from "./fable_modules/fable-library.3.7.3/Map.js";
import { some, map as map_1, defaultArgWith, value as value_3 } from "./fable_modules/fable-library.3.7.3/Option.js";
import { map } from "./fable_modules/fable-library.3.7.3/Seq.js";
import { RouterModule_nav, RouterModule_trySeparateLast, RouterModule_encodeQueryString, RouterModule_encodeParts, RouterModule_urlSegments } from "./fable_modules/Feliz.Router.3.8.0/Router.fs.js";
import { map as map_2, singleton, append } from "./fable_modules/fable-library.3.7.3/List.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { uncurry } from "./fable_modules/fable-library.3.7.3/Util.js";
import { Scope, ProblemDetails_get_Decoder } from "./ServerTypes.fs.js";
import { Action_Fulfillment, Maturity, Criticality, Condition, Status, Action_Category } from "./DomainEvents.fs.js";
import { FSharpSet__get_IsEmpty } from "./fable_modules/fable-library.3.7.3/Set.js";
import { FSharpChoice$7 } from "./fable_modules/fable-library.3.7.3/Choice.js";
import { toString as toString_1 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";

export function System_String__String_ToOption_Static_Z721C83C5(x) {
    if (isNullOrWhiteSpace(x)) {
        return void 0;
    }
    else {
        return x;
    }
}

export function System_Guid__Guid_tryParse_Static_Z721C83C5(x) {
    let matchValue;
    let outArg = "00000000-0000-0000-0000-000000000000";
    matchValue = [tryParse(x, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function System_Int32__Int32_ToLocaleString(this$) {
    return this$.toLocaleString();
}

export function System_Int32__Int32_tryParse_Static_Z721C83C5(x) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse_1(x, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function System_Boolean__Boolean_tryParse_Static_Z721C83C5(x) {
    let matchValue;
    let outArg = false;
    matchValue = [tryParse_2(x, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function System_DateTimeOffset__DateTimeOffset_tryParse_Static_Z721C83C5(x) {
    let matchValue;
    let outArg = minValue();
    matchValue = [tryParse_3(x, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function System_DateTimeOffset__DateTimeOffset_StartOfMonth(this$) {
    return create(year(this$), month(this$), 1, 0, 0, 0, 0);
}

export function System_DateTimeOffset__DateTimeOffset_EndOfMonth(this$) {
    let copyOfStruct = create(year(this$), month(this$) + 1, 1, 0, 0, 0, 0);
    return addSeconds(copyOfStruct, -1);
}

export function System_DateTimeOffset__DateTimeOffset_ToLocaleString(this$) {
    return this$.toLocaleString();
}

export function System_DateTimeOffset__DateTimeOffset_ToLocaleDateString(this$) {
    return this$.toLocaleDateString();
}

export function System_DateTimeOffset__DateTimeOffset_ToLocaleTimeString(this$) {
    return this$.toLocaleTimeString();
}

export function System_DateTimeOffset__DateTimeOffset_ToShortMonthYear(this$) {
    const matchValue = month(this$) | 0;
    switch (matchValue) {
        case 1: {
            return "Jan " + toString(this$, "yy");
        }
        case 2: {
            return "Feb " + toString(this$, "yy");
        }
        case 3: {
            return "Mar " + toString(this$, "yy");
        }
        case 4: {
            return "Apr " + toString(this$, "yy");
        }
        case 5: {
            return "May " + toString(this$, "yy");
        }
        case 6: {
            return "Jun " + toString(this$, "yy");
        }
        case 7: {
            return "Jul " + toString(this$, "yy");
        }
        case 8: {
            return "Aug " + toString(this$, "yy");
        }
        case 9: {
            return "Sep " + toString(this$, "yy");
        }
        case 10: {
            return "Oct " + toString(this$, "yy");
        }
        case 11: {
            return "Nov " + toString(this$, "yy");
        }
        case 12: {
            return "Dec " + toString(this$, "yy");
        }
        default: {
            throw (new Error("Can\u0027t have more that 12 months in a year"));
        }
    }
}

export function System_DateTimeOffset__DateTimeOffset_ToShortDayMonthYear(this$) {
    const matchValue = month(this$) | 0;
    switch (matchValue) {
        case 1: {
            return (toString(this$, "dd") + " Jan ") + toString(this$, "yy");
        }
        case 2: {
            return (toString(this$, "dd") + " Feb ") + toString(this$, "yy");
        }
        case 3: {
            return (toString(this$, "dd") + " Mar ") + toString(this$, "yy");
        }
        case 4: {
            return (toString(this$, "dd") + " Apr ") + toString(this$, "yy");
        }
        case 5: {
            return (toString(this$, "dd") + " May ") + toString(this$, "yy");
        }
        case 6: {
            return (toString(this$, "dd") + " Jun ") + toString(this$, "yy");
        }
        case 7: {
            return (toString(this$, "dd") + " Jul ") + toString(this$, "yy");
        }
        case 8: {
            return (toString(this$, "dd") + " Aug ") + toString(this$, "yy");
        }
        case 9: {
            return (toString(this$, "dd") + " Sep ") + toString(this$, "yy");
        }
        case 10: {
            return (toString(this$, "dd") + " Oct ") + toString(this$, "yy");
        }
        case 11: {
            return (toString(this$, "dd") + " Nov ") + toString(this$, "yy");
        }
        case 12: {
            return (toString(this$, "dd") + " Dec ") + toString(this$, "yy");
        }
        default: {
            throw (new Error("Can\u0027t have more that 12 months in a year"));
        }
    }
}

export function System_Double__Double_ToLocaleString(this$) {
    return this$.toLocaleString();
}

export function System_Double__Double_tryParse_Static_Z721C83C5(x) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse_4(x, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function System_TimeSpan__TimeSpan_IsoString(this$) {
    return `P${days_1(this$)}DT${hours_1(this$)}H${minutes_1(this$)}M${seconds_1(this$)}S`;
}

export function Map_merge(m1, m2) {
    return fold((s, k, v) => add(k, v, s), m1, m2);
}

export function Map_unionWith(combiner, source1, source2) {
    return fold((m, k, v$0027) => {
        let matchValue;
        return add(k, (matchValue = tryFind(k, m), (matchValue == null) ? v$0027 : combiner(value_3(matchValue), v$0027)), m);
    }, source1, source2);
}

export function Map_union(source, altSource) {
    return Map_unionWith((k, _arg1) => k, source, altSource);
}

export function Feliz_Router_Router__Router_getQuery_Static_Z721C83C5(queryString) {
    return ofSeq(map((arr) => [arr[0], arr[1]], (new URLSearchParams(queryString)).entries()));
}

export function Feliz_Router_Router__Router_getCurrentQuery_Static() {
    return Feliz_Router_Router__Router_getQuery_Static_Z721C83C5(window.location.search);
}

export function Feliz_Router_Router__Router_getCurrentUrl_Static() {
    return [window.location.pathname, window.location.search];
}

export function Feliz_Router_Router__Router_getParsedUrl_Static_Z384F8060(pathName, queryString) {
    return [RouterModule_urlSegments(pathName, 2), ofSeq(map((arr) => [arr[0], arr[1]], (new URLSearchParams(queryString)).entries()))];
}

export function Feliz_Router_Router__Router_getCurrentParsedUrl_Static() {
    const tupledArg = Feliz_Router_Router__Router_getCurrentUrl_Static();
    return Feliz_Router_Router__Router_getParsedUrl_Static_Z384F8060(tupledArg[0], tupledArg[1]);
}

export function Feliz_Router_Router__Router_formatPath_Static_Z499477B1(xs, queryString) {
    const queryString_1 = toList(queryString);
    return defaultArgWith(map_1((tupledArg) => RouterModule_encodeParts(append(tupledArg[0], singleton(tupledArg[1] + RouterModule_encodeQueryString(queryString_1))), 2), RouterModule_trySeparateLast(xs)), () => RouterModule_encodeParts(singleton(RouterModule_encodeQueryString(queryString_1)), 2));
}

export function Feliz_Router_Router__Router_navigatePath_Static_Z499477B1(xs, queryString) {
    const queryString_1 = toList(queryString);
    defaultArgWith(map_1((tupledArg) => {
        RouterModule_nav(append(tupledArg[0], singleton(tupledArg[1] + RouterModule_encodeQueryString(queryString_1))), 1, 2);
    }, RouterModule_trySeparateLast(xs)), () => {
        RouterModule_nav(singleton(RouterModule_encodeQueryString(queryString_1)), 1, 2);
    });
}

export function Thoth_Fetch_FetchError__FetchError_get_Message(this$) {
    switch (this$.tag) {
        case 2: {
            return this$.fields[0].statusText;
        }
        case 3: {
            return this$.fields[0].message;
        }
        case 0: {
            return this$.fields[0].message;
        }
        default: {
            return this$.fields[0];
        }
    }
}

export function Thoth_Fetch_FetchError__FetchError_get_IsFetchFailed(this$) {
    if (this$.tag === 2) {
        return true;
    }
    else {
        return false;
    }
}

export function Fetch_Types_Response__Response_ProblemDetails(this$) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (this$.text().then((_arg1) => {
        const body = _arg1;
        if (isNullOrWhiteSpace(body)) {
            return Promise.resolve(void 0);
        }
        else {
            const problemDetailsResult = fromString(uncurry(2, ProblemDetails_get_Decoder()), body);
            if (problemDetailsResult.tag === 1) {
                console.error(some("Unable to parse problem details from api"), problemDetailsResult.fields[0]);
                return Promise.resolve(void 0);
            }
            else {
                return Promise.resolve(problemDetailsResult.fields[0]);
            }
        }
    }))));
}

export function EPM_Domain_Events_DomainEntities__DomainEntities_ToProperPluralString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Systems";
        }
        case 2: {
            return "Subsystems";
        }
        case 3: {
            return "Facility";
        }
        case 4: {
            return "Disciplines";
        }
        case 5: {
            return "Activities";
        }
        case 6: {
            return "Modules";
        }
        case 7: {
            return "Areas";
        }
        case 8: {
            return "Locations";
        }
        case 9: {
            return "Documents";
        }
        case 10: {
            return "Equipment Types";
        }
        case 11: {
            return "Actions";
        }
        case 12: {
            return "Action Types";
        }
        case 13: {
            return "Production Units";
        }
        case 14: {
            return "Production Unit Types";
        }
        default: {
            return "Tags";
        }
    }
}

export function EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(this$) {
    switch (this$.tag) {
        case 1: {
            return "System";
        }
        case 2: {
            return "Subsystem";
        }
        case 3: {
            return "Facility";
        }
        case 4: {
            return "Discipline";
        }
        case 5: {
            return "Activity";
        }
        case 6: {
            return "Module";
        }
        case 7: {
            return "Area";
        }
        case 8: {
            return "Location";
        }
        case 9: {
            return "Document";
        }
        case 10: {
            return "Equipment Type";
        }
        case 11: {
            return "Action";
        }
        case 12: {
            return "Action Type";
        }
        case 13: {
            return "Production Unit";
        }
        case 14: {
            return "Production Unit Type";
        }
        default: {
            return "Tag";
        }
    }
}

export function EPM_Domain_Events_Action_Category__Category_ToProperPluralString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Certificates";
        }
        case 2: {
            return "Punch Lists";
        }
        case 3: {
            return "Document Trackings";
        }
        case 4: {
            return "Preservation";
        }
        case 5: {
            return "Technical Queries";
        }
        case 6: {
            return "Non-Conformance Reports";
        }
        default: {
            return "Activities";
        }
    }
}

export function EPM_Domain_Events_Action_Category__Category_ToProperString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Certificate";
        }
        case 2: {
            return "Punch List";
        }
        case 3: {
            return "Document Tracking";
        }
        case 4: {
            return "Preservation";
        }
        case 5: {
            return "Technical Query";
        }
        case 6: {
            return "Non-Conformance Report";
        }
        default: {
            return "Activity";
        }
    }
}

export function EPM_Domain_Events_Action_Category__Category_ToUrlString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Certification";
        }
        case 2: {
            return "PunchList";
        }
        case 3: {
            return "Document";
        }
        case 4: {
            return "Preservation";
        }
        case 5: {
            return "TechnicalQuery";
        }
        case 6: {
            return "NonConformanceReport";
        }
        default: {
            return "Activity";
        }
    }
}

export function EPM_Domain_Events_Action_Category__Category_tryParse_Static_Z721C83C5(x) {
    switch (x) {
        case "Activity": {
            return new Action_Category(0);
        }
        case "Certification": {
            return new Action_Category(1);
        }
        case "PunchList": {
            return new Action_Category(2);
        }
        case "Document": {
            return new Action_Category(3);
        }
        case "Preservation": {
            return new Action_Category(4);
        }
        case "TechnicalQuery": {
            return new Action_Category(5);
        }
        case "NonConformanceReport": {
            return new Action_Category(6);
        }
        default: {
            return void 0;
        }
    }
}

export function EPM_Domain_Events_Status__Status_ToUrlString(this$) {
    switch (this$.tag) {
        case 0: {
            return "Operational";
        }
        case 3: {
            return "Passive";
        }
        case 2: {
            return "Redundant";
        }
        case 1: {
            return "Removed";
        }
        case 4: {
            return "Shutdown";
        }
        default: {
            return "Decommissioned";
        }
    }
}

export function EPM_Domain_Events_Status__Status_tryParse_Static_Z721C83C5(x) {
    switch (x) {
        case "Decommissioned": {
            return new Status(5);
        }
        case "Operational": {
            return new Status(0);
        }
        case "Passive": {
            return new Status(3);
        }
        case "Redundant": {
            return new Status(2);
        }
        case "Removed": {
            return new Status(1);
        }
        case "Shutdown": {
            return new Status(4);
        }
        default: {
            return void 0;
        }
    }
}

export function EPM_Domain_Events_Condition__Condition_ToUrlString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Good";
        }
        case 2: {
            return "Moderate";
        }
        case 3: {
            return "Poor";
        }
        case 4: {
            return "VeryPoor";
        }
        default: {
            return "VeryGood";
        }
    }
}

export function EPM_Domain_Events_Condition__Condition_tryParse_Static_Z721C83C5(x) {
    switch (x) {
        case "VeryGood": {
            return new Condition(0);
        }
        case "Good": {
            return new Condition(1);
        }
        case "Moderate": {
            return new Condition(2);
        }
        case "Poor": {
            return new Condition(3);
        }
        case "VeryPoor": {
            return new Condition(4);
        }
        default: {
            return void 0;
        }
    }
}

export function EPM_Domain_Events_Criticality__Criticality_ToUrlString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Safety";
        }
        case 2: {
            return "Production";
        }
        case 3: {
            return "Structural";
        }
        case 4: {
            return "None";
        }
        default: {
            return "Class";
        }
    }
}

export function EPM_Domain_Events_Criticality__Criticality_tryParse_Static_Z721C83C5(x) {
    switch (x) {
        case "Class": {
            return new Criticality(0);
        }
        case "Safety": {
            return new Criticality(1);
        }
        case "Production": {
            return new Criticality(2);
        }
        case "Structural": {
            return new Criticality(3);
        }
        case "None": {
            return new Criticality(4);
        }
        default: {
            return void 0;
        }
    }
}

export function EPM_Domain_Events_Maturity__Maturity_ToUrlString(this$) {
    switch (this$.tag) {
        case 1: {
            return "PreCommissioning";
        }
        case 2: {
            return "MechanicalCompletion";
        }
        case 3: {
            return "Construction";
        }
        case 4: {
            return "FunctionTest";
        }
        case 5: {
            return "Commission";
        }
        case 6: {
            return "Handover";
        }
        default: {
            return "Unknown";
        }
    }
}

export function EPM_Domain_Events_Maturity__Maturity_ToProperString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Pre-Commissioning";
        }
        case 2: {
            return "Mechanical Completion";
        }
        case 3: {
            return "Construction";
        }
        case 4: {
            return "Function Test";
        }
        case 5: {
            return "Commissioning";
        }
        case 6: {
            return "Handover";
        }
        default: {
            return "Unknown";
        }
    }
}

export function EPM_Domain_Events_Maturity__Maturity_tryParse_Static_Z721C83C5(x) {
    switch (x) {
        case "Unknown": {
            return new Maturity(0);
        }
        case "PreCommissioning": {
            return new Maturity(1);
        }
        case "MechanicalCompletion": {
            return new Maturity(2);
        }
        case "Construction": {
            return new Maturity(3);
        }
        case "FunctionTest": {
            return new Maturity(4);
        }
        case "Commission": {
            return new Maturity(5);
        }
        case "Handover": {
            return new Maturity(6);
        }
        default: {
            return void 0;
        }
    }
}

export function EPM_Domain_Events_Action_Fulfillment__Fulfillment_ToUrlString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Documents";
        }
        case 4: {
            return "Images";
        }
        case 3: {
            return "Number";
        }
        case 0: {
            return "SignOff";
        }
        case 5: {
            return "Videos";
        }
        default: {
            return "Comment";
        }
    }
}

export function EPM_Domain_Events_Action_Fulfillment__Fulfillment_ToProperString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Documents";
        }
        case 4: {
            return "Images";
        }
        case 3: {
            return "Number";
        }
        case 0: {
            return "Sign Off";
        }
        case 5: {
            return "Videos";
        }
        default: {
            return "Comment";
        }
    }
}

export function EPM_Domain_Events_Action_Fulfillment__Fulfillment_tryParse_Static_Z721C83C5(x) {
    switch (x) {
        case "Comment": {
            return new Action_Fulfillment(2);
        }
        case "Documents": {
            return new Action_Fulfillment(1);
        }
        case "Images": {
            return new Action_Fulfillment(4);
        }
        case "Number": {
            return new Action_Fulfillment(3);
        }
        case "SignOff": {
            return new Action_Fulfillment(0);
        }
        case "Videos": {
            return new Action_Fulfillment(5);
        }
        default: {
            return void 0;
        }
    }
}

export function Types_Dashboard_ProjectBreakdown_GlobalStats_ProjectBreakdown__ProjectBreakdown_get_ToDo(this$) {
    return this$.Total - this$.Completed;
}

export function Types_Dashboard_ProjectBreakdown_GlobalStats_ProjectBreakdown__ProjectBreakdown_get_ToDoHours(this$) {
    return this$.EstimatedCompletionTime - this$.ActualCompletionTime;
}

export function EPM_Domain_Events_Attribute__Attribute_ValueString(this$) {
    if (this$.tag === 0) {
        return this$.fields[0];
    }
    else {
        return this$.fields[0].toString();
    }
}

export function Types_Scope__Scope_ToUrlString(this$) {
    switch (this$.tag) {
        case 1: {
            return "AssignUserToActivity";
        }
        case 4: {
            return "CanRevertActivityStep";
        }
        case 2: {
            return "CanSignOffAnyActivity";
        }
        case 3: {
            return "CanUnSignAnyActivity";
        }
        case 6: {
            return "SetSystemOwner";
        }
        case 5: {
            return "UserManagement";
        }
        default: {
            return "Operator";
        }
    }
}

export function Types_Scope__Scope_ToProperString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Assign User To Activity";
        }
        case 4: {
            return "Can Revert Activity Step";
        }
        case 2: {
            return "Can Sign Off Any Activity";
        }
        case 3: {
            return "Can UnSign Any Activity";
        }
        case 6: {
            return "Set System Owner";
        }
        case 5: {
            return "User Management";
        }
        default: {
            return "Operator";
        }
    }
}

export function Types_Scope__Scope_get_HasAccessToAdmin(this$) {
    switch (this$.tag) {
        case 0:
        case 6:
        case 5: {
            return true;
        }
        default: {
            return false;
        }
    }
}

export function Types_Scope__Scope_tryParse_Static_Z721C83C5(x) {
    switch (x) {
        case "Operator": {
            return new Scope(0);
        }
        case "AssignUserToActivity": {
            return new Scope(1);
        }
        case "CanRevertActivityStep": {
            return new Scope(4);
        }
        case "CanSignOffAnyActivity": {
            return new Scope(2);
        }
        case "CanUnSignAnyActivity": {
            return new Scope(3);
        }
        case "SetSystemOwner": {
            return new Scope(6);
        }
        case "UserManagement": {
            return new Scope(5);
        }
        default: {
            return void 0;
        }
    }
}

export function labelsToUrl(labels) {
    const value_2 = join(";", map((kv) => {
        let value_1;
        if (FSharpSet__get_IsEmpty(kv[1])) {
            value_1 = (encodeURIComponent(kv[0]));
        }
        else {
            const values = join(",", map((value) => (encodeURIComponent(value)), kv[1]));
            value_1 = (`${(encodeURIComponent(kv[0]))}=${values}`);
        }
        return encodeURIComponent(value_1);
    }, labels));
    return encodeURIComponent(value_2);
}

export function attributesToUrl(attributes) {
    const value_1 = join(";", map_2((value) => (encodeURIComponent(value)), attributes));
    return encodeURIComponent(value_1);
}

function StringPatterns_opt(bool) {
    if (bool) {
        return some(void 0);
    }
    else {
        return void 0;
    }
}

export function StringPatterns_$007CStartsWithExtract$007C_$007C(pattern, x) {
    if (x.indexOf(pattern) === 0) {
        return remove(x, 0, pattern.length);
    }
    else {
        return void 0;
    }
}

export function StringPatterns_$007CStartsWith$007C_$007C(pattern, x) {
    return StringPatterns_opt(x.indexOf(pattern) === 0);
}

function Humanize_$007CYears$007CMonths$007CWeeks$007CDays$007CHours$007CMinutes$007CSeconds$007C(duration) {
    if (totalDays(duration) > 365) {
        return new FSharpChoice$7(0, totalDays(duration) / 365);
    }
    else if (totalDays(duration) > 28) {
        return new FSharpChoice$7(1, totalDays(duration) / 28);
    }
    else if (totalDays(duration) > 7) {
        return new FSharpChoice$7(2, totalDays(duration) / 7);
    }
    else if (totalHours(duration) > 24) {
        return new FSharpChoice$7(3, totalDays(duration));
    }
    else if (totalMinutes(duration) > 60) {
        return new FSharpChoice$7(4, totalHours(duration));
    }
    else if (totalSeconds(duration) > 60) {
        return new FSharpChoice$7(5, totalMinutes(duration));
    }
    else {
        return new FSharpChoice$7(6, totalSeconds(duration));
    }
}

export function Humanize_timeSpan(timespan) {
    const activePatternResult33575 = Humanize_$007CYears$007CMonths$007CWeeks$007CDays$007CHours$007CMinutes$007CSeconds$007C(timespan);
    if (activePatternResult33575.tag === 1) {
        if (activePatternResult33575.fields[0] > 1) {
            return toText(interpolate("%.2f%P() Months", [activePatternResult33575.fields[0]]));
        }
        else {
            return toText(interpolate("%.2f%P() Month", [activePatternResult33575.fields[0]]));
        }
    }
    else if (activePatternResult33575.tag === 2) {
        if (activePatternResult33575.fields[0] > 1) {
            return toText(interpolate("%.2f%P() Weeks", [activePatternResult33575.fields[0]]));
        }
        else {
            return toText(interpolate("%.2f%P() Week", [activePatternResult33575.fields[0]]));
        }
    }
    else if (activePatternResult33575.tag === 3) {
        if (activePatternResult33575.fields[0] > 1) {
            return toText(interpolate("%.2f%P() Days", [activePatternResult33575.fields[0]]));
        }
        else {
            return toText(interpolate("%.2f%P() Day", [activePatternResult33575.fields[0]]));
        }
    }
    else if (activePatternResult33575.tag === 4) {
        if (activePatternResult33575.fields[0] > 1) {
            return toText(interpolate("%.2f%P() Hours", [activePatternResult33575.fields[0]]));
        }
        else {
            return toText(interpolate("%.2f%P() Hour", [activePatternResult33575.fields[0]]));
        }
    }
    else if (activePatternResult33575.tag === 5) {
        if (activePatternResult33575.fields[0] > 1) {
            return toText(interpolate("%.2f%P() Minutes", [activePatternResult33575.fields[0]]));
        }
        else {
            return toText(interpolate("%.2f%P() Minute", [activePatternResult33575.fields[0]]));
        }
    }
    else if (activePatternResult33575.tag === 6) {
        if (activePatternResult33575.fields[0] > 1) {
            return toText(interpolate("%.2f%P() Seconds", [activePatternResult33575.fields[0]]));
        }
        else {
            return toText(interpolate("%.2f%P() Second", [activePatternResult33575.fields[0]]));
        }
    }
    else if (activePatternResult33575.fields[0] > 1) {
        return toText(interpolate("%.2f%P() Years", [activePatternResult33575.fields[0]]));
    }
    else {
        return toText(interpolate("%.2f%P() Year", [activePatternResult33575.fields[0]]));
    }
}

export function Browser_Types_BlobType__BlobType_createBlobFromJson_4E60E31B(this$, data) {
    const arg00 = toString_1(4, data);
    return new Blob([arg00], { 'type': "application/json" });
}

export function Csv_encode(x) {
    if ((((x.indexOf(",") >= 0) ? true : (x.indexOf("\"") >= 0)) ? true : (x.indexOf("\r") >= 0)) ? true : (x.indexOf("\n") >= 0)) {
        const newValue = replace(x, "\"", "\"\"");
        return `"${newValue}"`;
    }
    else {
        return x;
    }
}

export function readAsArrayBufferPromise(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = ((_arg1) => {
            resolve(reader.result);
        });
        reader.onerror = ((_arg2) => {
            reject("");
        });
        reader.readAsArrayBuffer(blob);
    });
}

export function readAsDataUrlPromise(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = ((_arg1) => {
            resolve(reader.result);
        });
        reader.onerror = ((_arg2) => {
            reject("");
        });
        reader.readAsDataURL(blob);
    });
}

export function dataUrlToImage(dataUrl) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = ((_arg1) => {
            resolve(image);
        });
        image.src = dataUrl;
    });
}

export function dataUrlToCanvas(dataUrl) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (dataUrlToImage(dataUrl).then((_arg1) => {
        const image = _arg1;
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0);
        return Promise.resolve(canvas);
    }))));
}

