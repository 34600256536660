import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { labelsToUrl } from "../Api/../Prelude.fs.js";
import { ofList } from "../fable_modules/fable-library.3.7.3/Map.js";
import { ofList as ofList_1 } from "../fable_modules/fable-library.3.7.3/Set.js";
import { map as map_1, head, tail, length, isEmpty, empty, ofArray, singleton } from "../fable_modules/fable-library.3.7.3/List.js";
import { createObj, comparePrimitives } from "../fable_modules/fable-library.3.7.3/Util.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { useFeliz_React__React_useOptionalFetch_Static_Z3614112E, Fetched$1_toOption_50D43789 } from "../hooks/UseFetch.fs.js";
import { count, many, contentUrl as contentUrl_1 } from "../Api/Documents.fs.js";
import { createElement } from "react";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { RowSelection$1, Table, Row, Cell, Column, FilterType } from "./Table.fs.js";
import { DomainEntities } from "../DomainEvents.fs.js";
import { adminContent, ByCell, dateTimeCell, Labels } from "./Prelude.fs.js";
import { useReact_useCallback_93353E, useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { append, empty as empty_1, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Create, ExportReferences, ChangeReferences, Update } from "../Modals/Document.fs.js";
import { Components_UnDelete, Components_Delete, Components_ExportLabels, Components_ChangeLabels } from "../Modals/Modal.fs.js";
import { DomainEntity } from "../ClientTypes.fs.js";
import { many as many_1 } from "../Api/Entities.fs.js";

function nameParam(name) {
    if (isNullOrWhiteSpace(name)) {
        return new FSharpResult$2(1, "name");
    }
    else {
        return new FSharpResult$2(0, ["name", name]);
    }
}

function detailParam(name) {
    if (isNullOrWhiteSpace(name)) {
        return new FSharpResult$2(1, "detail");
    }
    else {
        return new FSharpResult$2(0, ["detail", name]);
    }
}

function labelParam(key, value) {
    if (isNullOrWhiteSpace(key) && isNullOrWhiteSpace(value)) {
        return new FSharpResult$2(1, "labels");
    }
    else {
        return new FSharpResult$2(0, ["labels", labelsToUrl(ofList(singleton([key, ofList_1(singleton(value), {
            Compare: comparePrimitives,
        })])))]);
    }
}

function OpenExternal(openExternalInputProps) {
    let elems;
    const document$ = openExternalInputProps.document;
    const projectId = openExternalInputProps.projectId;
    const contentUrl = defaultArg(document$.CurrentRevision.ExternalSourceUrl, Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((revisionId) => contentUrl_1(projectId, document$.Id, revisionId), (document$.CurrentRevision.ExternalSourceUrl != null) ? (void 0) : document$.CurrentRevision.RevisionId, [])));
    if (contentUrl != null) {
        const url = contentUrl;
        return createElement("a", createObj(ofArray([["href", url], ["target", "_blank"], ["rel", "noopener noreferrer"], ["alt", document$.Name], (elems = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-external-link-alt")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }
    else {
        return null;
    }
}

function columns(projectId) {
    return ofArray([new Column("name", "Name", new FilterType(0, nameParam)), new Column("detail", "Detail", new FilterType(0, detailParam)), new Column("labels", "Labels", new FilterType(2, projectId, new DomainEntities(9), empty(), (tupledArg) => labelParam(tupledArg[0], tupledArg[1]))), new Column("uploadedOn", "Uploaded On", void 0), new Column("uploadedBy", "Uploaded By", void 0), new Column("createdDate", "Created Date", void 0), new Column("lastModifiedOn", "Last Modified", void 0), new Column("lastModifiedBy", "Last Modified By", void 0), new Column("openExternal", "Open External", void 0)]);
}

function rowRender(projectId, document$) {
    return new Row(document$.Id, ofArray([new Cell("name", document$.Name), new Cell("detail", defaultArg(map((arg00) => arg00, document$.Detail), null)), new Cell("labels", createElement(Labels, {
        labels: document$.Labels,
    })), new Cell("uploadedOn", dateTimeCell(document$.CurrentRevision.UploadedOn)), new Cell("createdDate", defaultArg(map(dateTimeCell, document$.CreatedDate), null)), new Cell("uploadedBy", createElement(ByCell, {
        projectId: projectId,
        by: document$.CurrentRevision.UploadedBy,
    })), new Cell("lastModifiedOn", dateTimeCell(document$.LastModified)), new Cell("openExternal", createElement(OpenExternal, {
        projectId: projectId,
        document: document$,
    }))]));
}

export function Documents(documentsInputProps) {
    let matchValue, documents, documents_1;
    const projectId = documentsInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setAction = patternInput[1];
    const action = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const selectedDocuments = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const showDeleted = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(empty());
    const filters = patternInput_4[0];
    const columns_1 = useReact_useMemo_CF4EA67(() => columns(projectId), [projectId]);
    const close = useReact_useCallback_93353E((hasChanges) => {
        let copyOfStruct;
        setAction(void 0);
        if (hasChanges) {
            patternInput_3[1]((copyOfStruct = utcNow(), toString(copyOfStruct)));
        }
    });
    const table = createElement(Table, {
        name: "Documents",
        columns: columns_1,
        initialFilters: toList(delay(() => (showDeleted ? singleton_1(["deleted", ""]) : empty_1()))),
        onFiltersChange: patternInput_4[1],
        fetchData: (documentParams) => many(projectId, empty(), documentParams),
        fetchCount: (documentParams_1) => count(projectId, documentParams_1),
        rowRender: (document$) => rowRender(projectId, document$),
        refresh: patternInput_3[0],
        rowSelection: new RowSelection$1(2, patternInput_1[1]),
    });
    const isNotEmpty = !isEmpty(selectedDocuments);
    const isMoreThan1 = length(selectedDocuments) !== 1;
    return adminContent(toList(delay(() => {
        let props;
        return append(singleton_1((props = ofArray([["className", "is-info"], ["children", showDeleted ? "Hide Deleted" : "Show Deleted"], ["onClick", (_arg1) => {
            patternInput_2[1](!showDeleted);
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props))))), delay(() => {
            let props_2;
            return append(singleton_1((props_2 = ofArray([["className", "is-success"], ["children", "Create"], ["onClick", (_arg2) => {
                setAction("create");
            }], ["disabled", isNotEmpty ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_2))))), delay(() => {
                let props_4;
                return append(singleton_1((props_4 = ofArray([["className", "is-success"], ["children", "Update"], ["onClick", (_arg3) => {
                    setAction("update");
                }], ["disabled", isMoreThan1 ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_4))))), delay(() => {
                    let props_6;
                    return append(singleton_1((props_6 = ofArray([["className", "is-success"], ["children", "Change References"], ["onClick", (_arg4) => {
                        setAction("changeReferences");
                    }], ["disabled", isMoreThan1 ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_6))))), delay(() => {
                        let props_8;
                        return append(singleton_1((props_8 = ofArray([["className", "is-success"], ["children", "Change Labels"], ["onClick", (_arg5) => {
                            setAction("changeLabels");
                        }], ["disabled", isEmpty(selectedDocuments) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_8))))), delay(() => {
                            let props_10, props_12;
                            return append(showDeleted ? singleton_1((props_10 = ofArray([["className", "is-danger"], ["children", "UnDelete"], ["onClick", (_arg6) => {
                                setAction("unDelete");
                            }], ["disabled", isEmpty(selectedDocuments)]]), createElement("button", createObj(Helpers_combineClasses("button", props_10))))) : singleton_1((props_12 = ofArray([["className", "is-danger"], ["children", "Delete"], ["onClick", (_arg7) => {
                                setAction("delete");
                            }], ["disabled", isEmpty(selectedDocuments)]]), createElement("button", createObj(Helpers_combineClasses("button", props_12))))), delay(() => append(singleton_1(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-warning"], ["children", "Export References"], ["onClick", (_arg8) => {
                                setAction("exportReferences");
                            }]]))))), delay(() => singleton_1(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-warning"], ["children", "Export Labels"], ["onClick", (_arg9) => {
                                setAction("exportLabels");
                            }]])))))))));
                        }));
                    }));
                }));
            }));
        }));
    })), table, (action != null) ? ((matchValue = [action, selectedDocuments], (matchValue[0] === "update") ? ((!isEmpty(matchValue[1])) ? (isEmpty(tail(matchValue[1])) ? createElement(Update, {
        projectId: projectId,
        document: head(matchValue[1]),
        close: close,
    }) : (void 0)) : (void 0)) : ((matchValue[0] === "changeReferences") ? ((!isEmpty(matchValue[1])) ? (isEmpty(tail(matchValue[1])) ? createElement(ChangeReferences, {
        projectId: projectId,
        document: head(matchValue[1]),
        close: close,
    }) : (void 0)) : (void 0)) : ((matchValue[0] === "changeLabels") ? createElement(Components_ChangeLabels, {
        projectId: projectId,
        entities: map_1((document$_3) => [new DomainEntity(9, document$_3), document$_3.Labels], selectedDocuments),
        close: close,
    }) : ((matchValue[0] === "exportReferences") ? createElement(ExportReferences, {
        projectId: projectId,
        filters: filters,
        close: () => {
            close(false);
        },
    }) : ((matchValue[0] === "exportLabels") ? createElement(Components_ExportLabels, {
        entityType: new DomainEntities(9),
        countApi: () => count(projectId, filters),
        fetchApi: (apiParams_1) => many_1(new DomainEntities(9), projectId, apiParams_1, filters),
        close: () => {
            close(false);
        },
    }) : ((matchValue[0] === "delete") ? ((documents = map_1((arg0_6) => (new DomainEntity(9, arg0_6)), selectedDocuments), createElement(Components_Delete, {
        projectId: projectId,
        entities: documents,
        close: close,
    }))) : ((matchValue[0] === "unDelete") ? ((documents_1 = map_1((arg0_8) => (new DomainEntity(9, arg0_8)), selectedDocuments), createElement(Components_UnDelete, {
        projectId: projectId,
        entities: documents_1,
        close: close,
    }))) : createElement(Create, {
        projectId: projectId,
        close: close,
    }))))))))) : (void 0));
}

