import { Project$reflection, Project_get_Decoder } from "../ServerTypes.fs.js";
import { RouterModule_encodeQueryString } from "../fable_modules/Feliz.Router.3.8.0/Router.fs.js";
import { cons, ofArray, empty } from "../fable_modules/fable-library.3.7.3/List.js";
import { printf, toConsole } from "../fable_modules/fable-library.3.7.3/String.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { Helpers_baseApiUrl, Helpers_withSecureHeader } from "./Api.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Thoth.Fetch.3.0.1/../Fable.Promise.3.1.3/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Thoth.Fetch.3.0.1/../Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties, FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.4.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.3/MapUtil.js";
import { toString } from "../fable_modules/Thoth.Fetch.3.0.1/../Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { unit_type, equals, list_type, obj_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { list, Auto_generateBoxedDecoderCached_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { uncurry } from "../fable_modules/fable-library.3.7.3/Util.js";
import { fromString } from "../fable_modules/Thoth.Fetch.3.0.1/../Thoth.Json.7.0.0/Decode.fs.js";
import { ProjectConfig$reflection, ProjectConfig_get_Decoder } from "../ClientTypes.fs.js";

export function many() {
    const decoder = Project_get_Decoder();
    const newUrl = "projects" + RouterModule_encodeQueryString(empty());
    toConsole(printf("%s"))(newUrl);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1_1 = `${Helpers_baseApiUrl}/${newUrl}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_2;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_2 = Auto_generateBoxedDecoderCached_79988AEF(list_type(Project$reflection()), caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_2)))((path) => ((value) => list(uncurry(2, decoder), path, value))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(list_type(Project$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function config(projectId) {
    const decoder = ProjectConfig_get_Decoder();
    const url = `projects/${projectId}/config`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(ProjectConfig$reflection(), caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(decoder), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(ProjectConfig$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

